import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react'
import { DealService, InventoryService } from '../../../services';
import localforage, * as localForage from "localforage";
import CustomButton from '../../../components/CustomButton';
import CustomSearch from '../../../components/CustomSearch';
import { ClickOutside, utils } from '../../../utils';
import DeleteModal from '../../../components/DeleteModal';
import CustomsModal from '../../../components/CustomModal';
import CustomModal from '../../../components/CustomModal';
import ViewDetail from '../../../components/ViewDetail';
import { PngIcons, SvgIcons } from '../../../icons';
import { BrandContext } from '../../../context/Brand.context';
import { AgencyContext } from '../../../context/Agency.context';
import { InventoryVld } from '../../../validation';
import { Brand } from '../../../enums';
import { LayoutContext } from '../../../context/Layout.context';
import { useHistory } from 'react-router-dom';
import { ImageModalV2 } from '../../../components/ImageModalV2/ImageModalV2';
import LogoUploader from '../../../components/LogoUploader';
import { CardActionArea, CircularProgress } from '@mui/material';
import CustomTextField from '../../../components/CustomTextField';
import CustomTextArea from '../../../components/CustomTextArea';



import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';

function DealNew() {
  const theme = useTheme();

  const layout = useContext(LayoutContext);
  const agency = useContext(AgencyContext);
  const brand = useContext(BrandContext);
  const history = useHistory();
  const [imageModal, setImageModal] = useState({
    logo: { open: false, imageEvent: "", return: "" },
    cover: { open: false, imageEvent: "", return: "" },
  });


  const [state, setstate] = useState({
    apiError: "",
    loader: false,
    deleteLoader: false,
    deleteModal: false,
    mainLoader: true,
    deleteItem: "",
    deleteIndex: "",
    result: [],
  });
  const [data, setData] = useState(state.result);
  //   const [filtered, setFiltered] = useState(state.result);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [index1, setIndex] = useState();

  const [viewMore, setViewMore] = useState([]);


  const initState = {
    _id: "",
    brandLogo: "",
    brandLogoFile: "",
    name: "",
    nameq: "",
    price: "0",
    description: "",
    edit: false,
  };

  useEffect(async () => {
    setstate({ ...state, mainLoader: true });
    let deal = await localForage.getItem("deal");
    // console.log('aaaa',deal);
    if (!deal) {
      const { response, error } = await DealService.Get({
        query: { brandId: brand._id, delete: false },
      });
      if (response) {
        localForage.setItem("deal", response.data);
        deal = response.data;
      }
    }

    setData(deal);
    setstate({ ...state, mainLoader: false, result: deal });
  }, [])
  


  const handleSubmit = async () => {
    setstate({ ...state, loader: true });

    if (formik.values.brandLogoFile == "" && formik.values.brandLogo == "") {
      utils.showToaster({
        position: "top-right",
        title: "Missing Reward Logo",
        message: "Reward Logo is required",
        severity: "error",
      });
    } else {
      const user = await localForage.getItem("user");
      const desiredPath = `${user._id}/${formik.values.productName}/${formik.values.brandLogoFile?.name}/${formik.values.brandLogoFile?.size}/${formik.values.brandLogoFile?.lastModified}`;
      console.log('formik.values.brandLogoFile',formik.values.brandLogoFile);

      console.log('desiredPath',desiredPath);
      

      let logo;
      if (formik.values.brandLogoFile && !formik.errors.brandLogoFile) {
        logo = await utils.uploadImage({
          file: formik.values.brandLogoFile,
          desiredPath,
        });
        if (logo.error)
          return setstate({
            ...state,
            loader: false,
            apiError: logo.error.message,
          });
      }


      console.log('logo',logo);
      

      let payload = {
        name: formik.values.name.toLowerCase(),
        description: formik.values.description,
        image: logo && logo.response.data,
        price: formik.values.price,
        agencyId: agency._id,
        brandId: brand._id,
      };

      let payload2 = {
        name: formik.values.name,
        description: formik.values.description,
        image: logo && logo.response.data,
        agencyId: agency._id,
        brandId: brand._id,
        discountType: Brand.DealDiscountType.AMOUNT_TOTAL_PRODUCT,
        discountValue: 0,
        discountedPrice: formik.values.price,
        totalPrice: formik.values.price,
      };

      // console.log('====================================');
      // console.log(payload);
      // console.log(payload2);
      // console.log('====================================');

      if (!agency._id) delete payload2.agencyId;

      if (!formik.values.edit) {
        payload.prefix = utils.getRandomString(3);
        const { response, error } = await InventoryService.Create({
          toaster: true,
          payload,
        });
        if (error) setstate({ ...state, loader: false, apiError: error.error });
        else {
          let inventory = await localforage.getItem("inventory");
          if (inventory == null) inventory = [];
          inventory.push(response.data);
          localforage.setItem("inventory", inventory);

          payload2.items = [response.data._id];

          const dealres = await DealService.Create({
            payload: { ...payload2 },
          });
          if (dealres.error)
            setstate({
              ...state,
              loader: false,
              apiError: dealres.error.error,
            });
          else {
            let deal = await localForage.getItem("deal");
            if (deal === undefined || deal === null) deal = [];
            deal.push(dealres.response.data);
            localForage.setItem("deal", deal);
            setData(deal)
            state.result = deal;
            setstate({ ...state });
            setModal2(false);
            setModal1(false);
            setTimeout(() => {
              formik.setValues({ ...initState });
            }, 2000);
          }
        }
      } else {
        if (formik.values?.name1 === formik.values.name) delete payload2.name;
        console.log('YesZoop');
        const { response, error } = await DealService.Update({
          toaster: true,
          payload: { ...payload2, _id: formik.values._id },
        });
        if (error) setstate({ ...state, loader: false, apiError: error.error });
        else {
          let deal = await localForage.getItem("deal");
          deal.map((element, idx) => {
            if (element._id === formik.values._id) deal[idx] = response.data;
          });
          setData(deal)
          localForage.setItem("deal", deal);
          state.result = deal;
          setstate({ ...state });
          setModal2(false);
          setModal1(false);
          setTimeout(() => {
            formik.setValues({ ...initState });
          }, 2000);
        }
      }
    }
    setstate({ ...state, loader: false });
  };

  const formik = useFormik({
    initialValues: { ...initState },
    onSubmit: handleSubmit,
    validationSchema: InventoryVld.Create,
    // isInitialValid   : inventoryData && inventoryData.edit,
    isInitialValid: false,
    enableReinitialize: true,
  });

  const handlePriceChange = (e) => {
    if (e.target.value >= 0) {
      formik.setValues({ ...formik.values, price: e.target.value });
    }
  };


  //   useEffect(() => {
  //     let temp = [];
  //     if (filtered && filtered.length > 0) {
  //       for (const value of filtered) {
  //         temp.push(false);
  //       }
  //     }
  //     setViewMore(temp);
  //   }, [filtered]);

  const ViewMoreIconClickedFunc = (e, index) => {
    e.stopPropagation();
    e.preventDefault();
    if (index1 === index) setIndex();
    else setIndex(index);
  };

  const handleDelete = async (e) => {
    setDeleteLoader(true);
    const payload = {
      _id: formik.values._id,
      delete: true,
    };

    // console.log('payload', payload);


    const { error, response } = await DealService.Update({
      toaster: true,
      payload,
    });

    // console.log('response', response, error);
    if (error) setstate({ ...state, loader: false, apiError: error.error });

    let inventory = await localForage.getItem("deal");
    let temp = [];
    inventory.map((element, idx) => {
      if (element._id !== formik.values._id) temp.push(element);
    });
    localForage.setItem("deal", temp);
    setData(temp)
    state.result = temp;
    setstate({ ...state });
    formik.setValues({ ...initState });
    setDeleteLoader(false);
    setDeleteModal(false);
    setModal2(false);
  };

  const handleSearch = (e) => {
    const results = state.result.filter((item) =>
      item.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setData(results);
  };

  useEffect(() => {
    layout.setLayout({
      borderBottom: false,
      edit: false,
      // backTitles  : ["Loyalty Suite"],
      title: "Reward Setup",
      button: <></>
      // <CustomButton
      //   varient={"primary"} btntext={"Add Reward"} onClick={() => {
      //     formik.setValues({ ...formik.values, price: "0", edit: false });
      //     setModal2(true);
      //   }} />
    })
  }, [])
  return (
    <div className="pr_24 pl_24 pt_16 pb_16">
      <>
        <div>
          {console.log('datadata', data)}
          {console.log('datadata1', formik.values)}
          {
          
            data && data.length > 0 ? 
            <div style={{ 
              display: 'grid', 
              gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))', 
              gap: '16px' 
            }}>
              {data.map((value, index) => (
                <Card key={index} sx={{ flex: '1 1 300px', height: 'fit-content' }} onClick={() => {
                  // formik.setValues({
                  //   ...formik.values,
                  //   _id: value._id,
                  //   brandLogo: value.image,
                  //   name: value.name,
                  //   name1: value.name,
                  //   price: value.discountedPrice,
                  //   description: value.description,
                  //   edit: true,
                  // });
                  history.push({
                    pathname: window.location.pathname + '/add',
                    state: {_id: value._id,
                      brandLogo: value.image,
                      name: value.name,
                      name1: value.name,
                      price: value.discountedPrice,
                      description: value.description,
                      edit: true,}
                })
                  // if (index1 == index) setIndex();
                  // setModal2(true);
                }}>
                  <CardActionArea style={{ alignItems: 'flex-start', display: 'flex' }}>
                    <CardContent style={{width: '100%'}} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <Typography gutterBottom variant="h5" component="div" sx={{fontWeight:'bold', fontFamily: 'Inter'}}>
                        {utils.capitalize(value.name)}
                      </Typography>
                      <Typography variant="h6" style={{ fontFamily: 'Inter'}} color="text.secondary" sx={{
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2, // Limits to 2 lines
                        textOverflow: 'ellipsis',
                      }}>
                        {utils.capitalize(value.description)}
                      </Typography>
                    </CardContent>
                    <CardMedia
                      component="img"
                      height="140"
                      // width="140px"
                      style={{ padding: 12, width: '140px' }}
                      image={value.image}
                      alt={value.name}
                    />
                  </CardActionArea>
                </Card>
              ))}
              <div onClick={()=>{
              // formik.setValues({ ...formik.values, price: "0", edit: false });
              // setModal2(true);
              history.push({
                pathname: window.location.pathname + '/add',state: {_id: ""}
              })
            }
              } className='w-100  trigger1' style={{ alignContent: 'center', color:'#2960EC', minHeight: '140px' }} varient='tertiary'>
              <SvgIcons.Add color={'#2960EC'}/><br/>
              Add New Product
            </div>
            </div>
          
           : (
            <div className="emptyState-sm">
              {/* CONTENT */}
              <div className="emptyBody">
                <div className="Body14R color-neutral60 mb_0">
                  No Reward Found
                </div>
              </div>
            </div>
          )}
        </div>
      </>


      <CustomsModal
        open={deleteModal}
        minWidth={"600px"}
        component={
          <DeleteModal
            icon={deleteLoader}
            title="Reward"
            onCancel={() => setDeleteModal(false)}
            onDelete={handleDelete}
          />
        }
        onClose={() => setDeleteModal(false)}
      />

      <CustomModal
        open={modal2}
        onClose={() => setModal2(false)}
        maxWidth={550}
        minWidth={500}
        title={
          <div className="d-flex space-between">
            <div className="Heading22M color-neutral100">
              {formik.values.edit ? "Edit Reward" : "Add Reward"}
            </div>
            <div
              className="cp"
              onClick={() => {
                setModal2(false);
                formik.setValues({ ...initState });
              }}
            >
              <SvgIcons.CrossIcon />
            </div>
          </div>
        }
        component={
          <Modal2Component
            initState={initState}
            setDeleteModal={setDeleteModal}
            state={state}
            handleSubmit={handleSubmit}
            formik={formik}
            setModal2={setModal2}
            setImageModal={setImageModal}
            imageModal={imageModal}
            handlePriceChange={handlePriceChange}
            data={data}
          />
        }
      />
    </div>
  )
}

const Modal2Component = ({
  setDeleteModal,
  setModal2,
  formik,
  data,
  setImageModal,
  imageModal,
  handlePriceChange,
  handleSubmit,
  state,
  initState,
}) => {
  const brand = useContext(BrandContext);
  return (
    <div>
      {/* {console.log('child 2',state.result.length)} */}
      <div id="AddInventory2">
        <div className="modalComponent">
          <div className="mt_16">
            <CustomTextField
              label="Reward Name"
              className="w-100"
              name="name"
              placeholder="Enter name"
              value={formik.values.name}
              onChange={formik.handleChange}
              inputProps={{ onFocus: formik.handleBlur }}
              error={formik.touched.name && formik.errors.name}
              helperText={
                formik.touched.name && formik.errors.name
                  ? formik.errors.name
                  : ""
              }
            />
          </div>

          <div
            className="col-12 pl_0 pr_0 z-index-20 "
            style={{ height: "165px" }}
          >
            <LogoUploader
              src={
                formik.values.brandLogoFile
                  ? URL.createObjectURL(formik.values.brandLogoFile)
                  : formik.values.brandLogo
              }
              label="Brand Cover"
              // size         = 's'
              displayLabel="Deal Image"
              hint={
                <div>
                  <div className="Link12R color-neutral60">
                    File requirements JPG, PNG, WebP upto 10 MB.ss
                  </div>
                  <div className="Link12R color-neutral60">
                    Minimum pixels required: 550 x 440.
                  </div>
                </div>
              }
              className="mt_24 mb_24 pl_0"
              error={formik.values.brandLogoFile && formik.errors.brandLogoFile}
              btntext="Upload Deal Image"
              btnName="Upload"
              htmlFor={"logoUpload"}
              onChange={(e) => {
                setImageModal({
                  ...imageModal,
                  logo: { ...imageModal.logo, open: true, imageEvent: e },
                });
              }}
            />
          </div>
          <div className="error">{formik.errors.brandLogoFile}</div>
          {
            console.log('brand.currency.symbol',brand)
          }

          {/* <div className="mt_16" style={{ position: 'relative' }}>
            <CustomTextField
              label="Reward Price (Optional)"
              // paddingLeft = "55px"

              className="w-100"
              name="price"
              type="number"
              left={10}
              icon={formik.values.price ? <div>{brand.location?.code }</div> : <></>} //style={{position:'absolute' , top:0}}
              position="start"
              // paddingLeft={30}
              placeholder="Enter price"
              value={formik.values.price}
              inputProps={{ onFocus: formik.handleBlur, style: { marginLeft: '30px' } }}
              onChange={(e) => handlePriceChange(e)}
              error={formik.touched.price && formik.errors.price}
              helperText={
                formik.touched.price && formik.errors.price
                  ? formik.errors.price
                  : ""
              }
            />
          </div> */}
          <div className="mt_16 mb_24">
            <CustomTextArea
              label="Description (Optional)"
              className="w-100"
              name="description"
              placeholder="Enter short description about the reward"
              value={formik.values.description}
              onChange={formik.handleChange}
              inputProps={{ onFocus: formik.handleBlur }}
              error={formik.touched.description && formik.errors.description}
              helperText={
                formik.touched.description && formik.errors.description
                  ? formik.errors.description
                  : ""
              }
            />
          </div>
        </div>

        <CustomModal
          open={imageModal.logo.open}
          onClose={() =>
            setImageModal({
              ...imageModal,
              logo: { ...imageModal.logo, open: false },
            })
          }
          minWidth="470px"
          minHeight="470px"
          padding="0px"
          component={
            <ImageModalV2
              imageModal={imageModal}
              setImageModal={setImageModal}
              image={"logo"}
              type={"logo"}
              formik={formik}
            />
          }
        />
      </div>
      <div className="bar mb_20 mt_32"></div>
      <div className="w-100 d-flex justify-content-right">
        {data?.length>1&&<CustomButton
          varient="dangerSecondary"
          btntext={"Delete"}
          onClick={() => {
            // formik.setValues({
            //   ...formik.values,
            //   _id: value._id,
            // });
            setDeleteModal(true);
          }}
        />}
        <CustomButton
          varient="secondary"
          btntext={"Cancel"}
          className={"ml_10"}
          onClick={() => {
            setModal2(false);
            setTimeout(() => {
              formik.setValues({ ...initState });
            }, 2000);
          }}
        />
        <CustomButton
          disabled={state.loader || !formik.isValid}
          onClick={handleSubmit}
          icon={
            state.loader && <CircularProgress size={20} color={"inherit"} />
          }
          className={"ml_10"}
          btntext={formik.values.edit ? "Update" : "Create"}
        />
      </div>
    </div>
  );
};

export default DealNew