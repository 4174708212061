import React, { useContext, useState, useEffect } from 'react'
import { PngIcons } from '../../icons'
import { utils } from '../../utils'
import CustomButton from '../../components/CustomButton'
import { ColorSchemeCode } from '../../enums/ColorScheme'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

export function MainOnboard2() {

  const history = useHistory();
  let template = [
    "Standalone Powerhouse","CloudWaitress"
    // ,"POS (Point of Sale) System"
  ]

  const [selectedT, setSelectedT] = useState(0)

  return (
   
     
    <>
    <div id='mainOnBo' className='position-relative w-100'>
    <div className='top-header-auth d-flex align-items-center'>
      <img src={(PngIcons.authLogo)} height="32px" width="auto" className='borderRadius-60 ' alt="" />
      
</div>


      {
      <div id="Auth" className="LoginCardLayout w-100">
        <div className='auth-form'>
          <div className='mainDiv' id='auth'>
            <div className='main_sub_1 w-100'>
              <div className='main_sub_1_child2 boxShadow mt_20'>
                  <h1 >Choose Your Walletly Superpower</h1>
                  <p className='specialp mb_48'>{"How do you want to roll with Walletly? Pick your path:"}</p>

                  {
                    template.map((value, index)=>(
                      <div className={`d-flex cp gap-16 mb_16 bg-color-neutral0 borderRadius-8 pt_16 pb_16 pr_24 pl_24 ${index===2&& 'disabled'}`} style={{ border: selectedT === index && "1px solid #2960EC"}} onClick={()=>index!==2&&setSelectedT(index)}>
                        <div className='H18M'>{value}</div>
                      </div>
                    ))
                  }
                  {console.log(selectedT)}
                  
                  <CustomButton
                    varient   = "primary"
                    className = "w-100 mt_48"
                    btntext   = {'Next'}
                    onClick   = {(e)=> 
                      {
                        e.stopPropagation()
                        if(selectedT===0) history.push({pathname: '/brand'}) 
                        if(selectedT===1) history.push({pathname: '/brand',search: '?cw'})
                      }
                    }
                  />
                <div className='d-flex gap-10 mt_20' style={{alignItems: 'center', alignSelf: 'center'}}>
                  
                {window.location.href.includes('brandType')?'':<div style={{width: 'fit-content'}} className='mt_8 color-neutral60 specialp d-flex align-items-center justify-content-center cp' onClick={()=>{
                //  if(search) setSearch(false)
                //  else setSearch(true)
                }}
                   >
                  {'Enter Website'}
                </div>}
                {window.location.href.includes('brandType')?'':<div style={{width: 'fit-content'}} className='mt_8 color-neutral60 specialp d-flex align-items-center justify-content-center '  >  | </div>}
                <div style={{width: 'fit-content'}} className='mt_8 color-neutral60 specialp d-flex align-items-center justify-content-center cp' onClick={()=>utils.Logout()} >
                  Log Out
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      }
    </div>
  </>
   
    
  )
}

export default MainOnboard2