// import React, { useContext, useEffect, useState } from "react";
// import { Link, useHistory } from "react-router-dom";
// import CircularProgress from "@mui/material/CircularProgress";
// import { useFormik } from "formik";
// import * as localForage from "localforage";
// import { AuthVld } from "../../validation";
// import {
//   AnalyticService,
//   AuthService,
//   BrandService,
//   UserService,
// } from "../../services";
// import { PermissionService } from "../../services";

// // import CustomTextField from "../../components/CustomTextField";
// import CustomTextField from "../../components/CustomTextField copy";
// import CustomButton from "../../components/CustomButton";
// import { BusinessType } from "../../enums/enums";
// import { UserContext } from "../../context/User.context";
// import { Brand, Campaign, ColorScheme, User } from "../../enums";
// import CoalitionService from "../../services/Coalition.service";
// import { AgencyContext } from "../../context/Agency.context";
// import { BrandContext } from "../../context/Brand.context";
// import { SvgIcons, PngIcons } from "../../icons";
// import Slide from 'react-reveal/Fade';

// function Login({ setTitle }) {
//   const history                   = useHistory();
//   const userContext               = useContext(UserContext);
//   const agency                    = useContext(AgencyContext);
//   const [showPassword, setShowPassword] = useState(false);
//   const [type, settype]           = useState("password");
  
//   const [state, setState] = React.useState({
//     apiError: "",
//     loader: false,
//   });

//   const initState = {
//     email: "",
//     password: "",
//   };

//   const formik = useFormik({
//     initialValues: { ...initState },
//     onSubmit: handleSubmit,
//     validationSchema: AuthVld.LoginVld,
//     isInitialValid: false,
//   });

//   async function handleSubmit(e) {

//     setState({ ...state, loader: true });
//     let user = await AuthService.Login({ payload: formik.values });

//     if (user.error) {
//       setState({ ...state, loader: false });
//       return setState({ ...state, apiError: user.error.error });
//     }

//     if (
//       user.response.data.roles.includes(User.Roles.MASTER_ADMIN) &&
//       !user.response.data.roles.includes(User.Roles.ADMIN)
//     ) {
//       setState({ ...state, loader: false });
//       return setState({ ...state, apiError: "please signup for walletly" });
//     }

//     await localForage.setItem("token", user.response.data.token);
//     delete user.response.data.token;

//     const query = {
//       roles: user.response.data.roles,
//       businessType: BusinessType.BRAND,
//       delete: false,
//     };
//     const permissions = await PermissionService.GetQueriedPermissions({
//       query,
//     });
//     let permissionCheck;
//     const brandData = await BrandService.Get({
//       query: { agencyId: agency._id, delete: false },
//     });

//     if (agency.whiteLabel && permissions.response) {
//       permissionCheck = [];
//       for (const permission of permissions.response.data) {
//         // const brandData = await BrandService.Get({query: {_id: permission.businessId, delete: false}})
//         let brand;
//         if (brandData.response) {
//           brandData.response.data.map((element, idx) => {
//             if (element._id === permission.businessId) {
//               brand = element;
//             }
//           });
//         }
//         if (brand && brand.agency && brand.agency.id === agency._id) {
//           permissionCheck.push(permission);
//         }
//         //

//         if (brand && brand.brandType === Brand.BrandTypes.SUB_BRAND) {
//           const coalitionData = await CoalitionService.Get({
//             query: {
//               childBrandId: permission.businessId,
//               agencyId: agency._id,
//             },
//           });
//           if (coalitionData.response && coalitionData.response.data[0])
//             permissionCheck.push(permission);
//         }
//       }
//     }

//     if (permissionCheck && permissionCheck.length > 0) {
//       user = {
//         ...user.response.data,
//         activeBrand: permissionCheck[0].businessId,
//       };
//       const payload = {
//         _id: user._id,
//         activeBrand: permissionCheck[0].businessId,
//       };
//       UserService.Update({ payload });
//     }

//     if (!permissions.error && !agency.whiteLabel) {
//       user = {
//         ...user.response.data,
//         activeBrand: permissions.response.data[0].businessId,
//       };
//       const payload = {
//         _id: user._id,
//         activeBrand: permissions.response.data[0].businessId,
//       };
//       await UserService.Update({ payload });
//     }

//     if (permissionCheck && permissionCheck.length <= 0) {
//       user = { ...user.response.data, activeBrand: undefined };
//       const payload = { _id: user._id, activeBrand: undefined };
//       UserService.Update({ payload });
//     }

//     userContext.updateUser(
//       user.response ? user.response.data : user,
//       !permissionCheck && permissions.response
//         ? permissions.response.data
//         : permissionCheck
//     );

//     setState({ ...state, loader: false });

//     if (user.activeBrand) {
//       const brandCheck = await BrandService.Get({
//         query: { _id: user.activeBrand, delete: false },
//       });
//       if (
//         brandCheck.response &&
//         brandCheck.response.data[0].brandType === Brand.BrandTypes.NORMAL &&
//         !agency.whiteLabel &&
//         !brandCheck.response.data[0].subscription
//       ) {
//         history.push("/" + user.activeBrand + "/setting/billing");
//         return window.location.reload();
//       } else if (
//         brandCheck.response &&
//         brandCheck.response.data[0].brandType === Brand.BrandTypes.NORMAL &&
//         agency.whiteLabel &&
//         !brandCheck.response.data[0].subscription
//       ) {
//         history.push("/" + user.activeBrand + "/setting/general");
//         return window.location.reload();
//       }
//     }

//     history.push("/" + user.activeBrand);
//   }


//   const eyeClick = () => {
//     var x = document.getElementById("password");
//     if (x.type === "password") {
//       x.type = "text";
//       settype("password");
//     } else {
//       x.type = "password";
//       settype("text");
//     }
//   };

//   useEffect(() => {
//     setTitle("Sign In");
//     console.log('useEffect');

//   }, []);

//   const handleChange = async (e, name) => {
//     console.log('handleChange');

//     formik.setValues({ ...formik.values, [name]: e.target.value });
//     formik.setTouched({ ...formik.touched, [name]: true }); 
//   };
  


//   return (
//     <>
//       <form onSubmit={formik.handleSubmit} autocomplete="on">
//         <div className="auth-form " >
//           <div className="d-flex justify-content-center w-100">
//             <div className="position-relative"  style={{height: '160px', width : '160px', backgroundSize : 'cover',  backgroundImage: `url(${PngIcons.logoImageBorder})`  } }>
//               <img  style={{position : 'absolute'}} className='borderRadius-60 object-fit-cover absoluteMiddle' width={'50px'} height={'50px'} src={(agency.logo || PngIcons.authLogo)} />
//             </div>
//           </div>
          

//           <div className="mt_24 Heading24M color-Heading text-center w-100">
//               Sign In with <span className="capitalize">{agency?.agencyName || "Walletly"}</span>
//           </div>

//     <div id="LoginBtn" className="w-100">
//           <div className="w-100 mt_24">
//             <CustomTextField
//               label        = "Email address"
//               type         = "email"
//               name         = "email"
//               autocomplete = 'email'
//               className    = "lh-24 "
//               top          = {10}
//               id           = "email"
//               fontSize     = "16px"
//               background   = "white"
//               onSubmit     = {()=>setShowPassword(true)}
//               padding      = "12px 16px"
//               // icon         = {<span onClick={()=>setShowPassword(true)}><SvgIcons.LoginNextIcon/></span>}
//               // position     = "end"
//               // error        = {formik.touched.email &&  formik.errors.email}
//               defaultValue = {formik.values.email}
//               onChange     = {(e) => {
//                 handleChange(e, "email");
//               }}
//               helperText={
//                 formik.touched.email && formik.errors.email
//                   ? formik.errors.email
//                   : ""
//               }
//             />
//           </div>

//           {<div className="w-100 mt_24 position-relative showContent">
//             <CustomTextField
//               label        = "Password"
//               type         = "password"
//               name         = "password"
//               id           = "password"
//               autocomplete = 'password'
//               background   = "white"
//               className    = "lh-24"
//               top          = {15}
//               fontSize     = "16px"
//               padding      = "12px 16px"
//               onSubmit     = {handleSubmit}
//               // error        = {formik.touched.password &&  formik.errors.password}
//               defaultValue = {formik.values.password}
//               helperText   = {
//                 formik.touched.password && formik.errors.password
//                   ? formik.errors.password
//                   : ""
//               }
//               onChange     = {(e) => {
//                 handleChange(e, "password");
//               }}
//               position = "end"
//             />
//             <div onClick={eyeClick} style={{ position: "absolute", top: "12px", right: "16px" }}>
//             {type !== "password" ? (
//                     <SvgIcons.EyeIconPassword />
//                   ) : (
//                     <SvgIcons.CrossEyeIcon />
//                   )}
//           </div>
//           </div>}
//           </div>
  
          

//           <div className="w-100 pt_8 gap-12 d-flex flex-column">
//             <CustomButton
//               varient   = "primary"
//               btntext   = "Continue"
//               className = "w-100 hpx-52"
//               onClick   = {(formik.values.email != "" && formik.values.password != "") ? handleSubmit : ()=>setShowPassword(true) }
//               disabled  = {state.loader || formik.errors.password || formik.errors.email}
//               icon      = {
//                 state.loader && <CircularProgress size={20} color={"inherit"} />
//               }
//             />
//              {state.apiError && <div className={'error'}>{state.apiError}</div>}
//             <div className="text-center w-100">
//             <Link
//               to="/forget"
//               className="Body14R color-primary40"
//             >
//               Forgot Password
//             </Link>
//           </div>
//           </div>
          
//           <div  className='d-flex align-items-center w-100 mt_32'style={{justifyContent:"center"}}>
//             <div className="text-center Body14R lh-20  color-primary40">
//                 <Link to={'/signup'} className="cp   color-primary40">
//                 Create <span className="capitalize">{agency?.agencyName || "Walletly"}</span> Account
//               </Link>
//               </div>
//           </div>
          
//         </div>
//       </form>
//     </>
//   );
// }

// export default Login;



import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { useFormik } from "formik";
import * as localForage from "localforage";
import { AuthVld } from "../../validation";
import {
  AnalyticService,
  AuthService,
  BrandService,
  UserService,
} from "../../services";
import { PermissionService } from "../../services";

// import CustomTextField from "../../components/CustomTextField";
import CustomTextField from "../../components/CustomTextField copy";
import CustomButton from "../../components/CustomButton";
import { BusinessType } from "../../enums/enums";
import { UserContext } from "../../context/User.context";
import { Brand, Campaign, ColorScheme, User } from "../../enums";
import CoalitionService from "../../services/Coalition.service";
import { AgencyContext } from "../../context/Agency.context";
import { SvgIcons, PngIcons } from "../../icons";
import { Input } from "antd";

function Login({ setTitle }) {
  const history                   = useHistory();
  const userContext               = useContext(UserContext);
  const agency                    = useContext(AgencyContext);
  const [showPassword, setShowPassword] = useState(false);
  const [type, settype]           = useState("password");
  
  const [state, setState] = React.useState({
    apiError: "",
    loader: false,
  });

  const initState = {
    email: "",
    password: "",
  };

  const formik = useFormik({
    initialValues: { ...initState },
    onSubmit: handleSubmit,
    validationSchema: AuthVld.LoginVld,
    isInitialValid: false,
  });

  async function handleSubmit(e) {

    setState({ ...state, loader: true });
    let user = await AuthService.Login({ payload: formik.values });

    if (user.error) {
      setState({ ...state, loader: false });
      return setState({ ...state, apiError: user.error.error });
    }
    console.log('roles',user.response.data.roles);
    if (
      user.response.data.roles.includes(User.Roles.MASTER_ADMIN) &&
      !user.response.data.roles.includes(User.Roles.ADMIN)
    ) {
      setState({ ...state, loader: false });
      return setState({ ...state, apiError: "please signup for walletly" });
    console.log('roles 2',user.response.data.roles);

    }

    await localForage.setItem("token", user.response.data.token);
    delete user.response.data.token;
    console.log('roles 3',user.response.data.roles);

    const query = {
      roles: user.response.data.roles,
      businessType: BusinessType.BRAND,
      delete: false,
    };
    console.log('roles4',user.response.data.roles);

    const permissions = await PermissionService.GetQueriedPermissions({
      query,
    });

    console.log('permissions ', permissions)
    console.log('agency ', agency)

    let permissionCheck;
    console.log('agency',agency)
    const brandData = await BrandService.Get({
      query: { agencyId: agency._id, delete: false },
    });
    console.log('brandData',[user.response.data , brandData , query]);

    if (agency.whiteLabel && permissions.response) {
      permissionCheck = [];
      for (const permission of permissions.response.data) {
        // const brandData = await BrandService.Get({query: {_id: permission.businessId, delete: false}})
        let brand;
        if (brandData.response) {
          brandData.response.data.map((element, idx) => {
            if (element._id === permission.businessId) {
              brand = element;
            }
          });
        }
        if (brand && brand.agency && brand.agency.id === agency._id) {
          permissionCheck.push(permission);
        }
        //

        if (brand && brand.brandType === Brand.BrandTypes.SUB_BRAND) {
          const coalitionData = await CoalitionService.Get({
            query: {
              childBrandId: permission.businessId,
              agencyId: agency._id,
            },
          });
          if (coalitionData.response && coalitionData.response.data[0])
            permissionCheck.push(permission);
        }
      }
    }

    if (permissionCheck && permissionCheck.length > 0) {
      user = {
        ...user.response.data,
        activeBrand: permissionCheck[0].businessId,
      };
      const payload = {
        _id: user._id,
        activeBrand: permissionCheck[0].businessId,
      };
      console.log('user payload',payload);
      UserService.Update({ payload });
    }

    if (!permissions.error && !agency.whiteLabel) {
      user = {
        ...user.response.data,
        activeBrand: permissions.response.data[0].businessId,
      };
      const payload = {
        _id: user._id,
        activeBrand: permissions.response.data[0].businessId,
      };
      console.log('user payload 2',payload);

      await UserService.Update({ payload });
    }

    if (permissionCheck && permissionCheck.length <= 0) {
      user = { ...user.response.data, activeBrand: undefined };
      const payload = { _id: user._id, activeBrand: undefined };
      UserService.Update({ payload });
    }

    userContext.updateUser(
      user.response ? user.response.data : user,
      !permissionCheck && permissions.response
        ? permissions.response.data
        : permissionCheck
    );

    setState({ ...state, loader: false });

    if (user.activeBrand) {
      const brandCheck = await BrandService.Get({
        query: { _id: user.activeBrand, delete: false },
      });
      if (
        brandCheck.response &&
        brandCheck.response.data[0].brandType === Brand.BrandTypes.NORMAL &&
        !agency.whiteLabel &&
        !brandCheck.response.data[0].subscription
      ) {
        // history.push("/" + user.activeBrand + "/setting/billing");
        history.push("/" + user.activeBrand + "/");
        // history.push("/" + user.activeBrand + "/setting/integrations");
        return window.location.reload();
      } else if (
        brandCheck.response &&
        brandCheck.response.data[0].brandType === Brand.BrandTypes.NORMAL &&
        agency.whiteLabel &&
        !brandCheck.response.data[0].subscription
      ) {
        history.push("/" + user.activeBrand + "/");
        // history.push("/" + user.activeBrand + "/setting/general");
        return window.location.reload();
      }
    }

    history.push("/" + user.activeBrand);
  }


  const eyeClick = () => {
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
      settype("password");
    } else {
      x.type = "password";
      settype("text");
    }
  };

  useEffect(() => {
    setTitle("Sign In");
    console.log('useEffect');

  }, []);

  useEffect(() => {
    formik.validateForm(); // Trigger Formik validation when values change
  }, [formik.values]);

  const handleChange = async (e, name) => {
    console.log('handleChange');

    formik.setValues({ ...formik.values, [name]: e.target.value });
    formik.setTouched({ ...formik.touched, [name]: true }); 
  };
  
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  return (
    <>
      <form onSubmit={formik.handleSubmit} autocomplete="on">
        <div className="auth-form " >
          {/* <div className="d-flex justify-content-center w-100">
            <div className="position-relative"  style={{height: '160px', width : '160px', backgroundSize : 'cover',  backgroundImage: `url(${PngIcons.logoImageBorder})`  } }>
              <img  style={{position : 'absolute'}} className='borderRadius-60 object-fit-cover absoluteMiddle' width={'50px'} height={'50px'} src={(agency.logo || PngIcons.authLogo)} />
            </div>
          </div> */}
          

          <div className="mt_24 Heading24M color-Heading text-center w-100">
          New to {agency?.agencyName || "Walletly"}? <br/>Start your journey to better business.
              {/* Sign In with <span className="capitalize">{agency?.agencyName || "Walletly"}</span> */}
          </div>
          <div className=" Heading16M color-neutral60 text-center mb_16 w-100">
          Welcome to {agency?.agencyName+'. ' || "Walletly. "}
          Boost loyalty, increase revenue.
              {/* Sign In with <span className="capitalize">{agency?.agencyName || "Walletly"}</span> */}
          </div>

          <Input size="large"
              type="email"
              name="email"
              placeholder="Work Email"
              status={formik.errors.email&&"error"}
              value={formik.values.email}
              onChange={(e) => {
                handleChange(e, "email");
              }}
              onPaste={(e) => {
                e.preventDefault(); // Prevent default paste behavior
                const pastedValue = e.clipboardData.getData('Text');
                formik.setFieldValue("email", pastedValue);
              }}
            />
          <Input.Password size="large" 
            status={formik.errors.password&&"error"}
            placeholder="Password"
            name="password"
            value={formik.values.password}
            onChange={(e) => {
              handleChange(e, "password");
            }}
            onPaste={(e) => {
              e.preventDefault(); // Prevent default paste behavior
              const pastedValue = e.clipboardData.getData('Text');
              formik.setFieldValue("password", pastedValue);
            }}
             />
    <div id="LoginBtn" className="w-100">
          {/* <div className="w-100 mt_24" style={{fontSize: '26px !important'}}>
            <CustomTextField
              label        = "Email address"
              type         = "email"
              name         = "email"
              autocomplete = 'email'
              padding="12px 16px"
              className="lh-24"
              fontSize="16px"
              // className    = "lh-24 "
              // top          = {10}
              id           = "email"
              // fontSize     = "16px"
              background   = "white"
              onSubmit     = {()=>setShowPassword(true)}
              // padding      = "12px 16px"
              // icon         = {<span onClick={()=>setShowPassword(true)}><SvgIcons.LoginNextIcon/></span>}
              // position     = "end"
              // error        = {formik.touched.email &&  formik.errors.email}
              defaultValue = {formik.values.email}
              error={formik.touched.email && formik.errors.email}
              onChange     = {(e) => {
                handleChange(e, "email");
              }}
              helperText={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : ""
              }
            />
          </div> */}

           

          {/* {<div className="w-100 mt_24 position-relative showContent">
            <CustomTextField
              label        = "Password"
              type         = "password"
              name         = "password"
              id           = "password"
              autocomplete = 'password'
              background   = "white"
              className="lh-24"
              fontSize="16px"
              padding="12px 16px"
              // className    = "lh-24"
              // top          = {15}
              // fontSize     = "16px"
              // padding      = "12px 16px"
              onSubmit     = {handleSubmit}
              // error        = {formik.touched.password &&  formik.errors.password}
              defaultValue = {formik.values.password}
              error={formik.touched.password && formik.errors.password}
              helperText   = {
                formik.touched.password && formik.errors.password
                  ? formik.errors.password
                  : ""
              }
              onChange     = {(e) => {
                handleChange(e, "password");
              }}
              position = "end"
            />
            <div onClick={eyeClick} style={{ position: "absolute", top: "12px", right: "16px" }}>
            {type !== "password" ? (
                    <SvgIcons.EyeIconPassword />
                  ) : (
                    <SvgIcons.CrossEyeIcon />
                  )}
          </div>
          </div>} */}
          <div className="text-end w-100">
            <Link
              to="/forget"
              className="Body14R color-primary40"
            >
              Forgot Password?
            </Link>
          </div>
          </div>
  {
    console.log('formik.errors.password',formik.errors.password)
  }
  {
    console.log('formik.values',formik.values)
  }
  {
    console.log('formik.errors.email',formik.errors.email)
  }
          

          <div className="w-100 pt_8 gap-12 d-flex flex-column">
            <CustomButton
              varient   = "primary"
              btntext   = "Continue"
              className = "w-100 "//hpx-52
              onClick   = {(formik.values.email != "" && formik.values.password != "") ? handleSubmit : ()=>setShowPassword(true) }
              disabled  = {state.loader || formik.errors.password || formik.errors.email}
              icon      = {
                state.loader && <CircularProgress size={20} color={"inherit"} />
              }
            />
             {state.apiError && <div className={'error'}>{state.apiError}</div>}
            
          </div>

          
          <div  className='d-flex align-items-center w-100 mt_12'style={{justifyContent:"center"}}>
            <div className="text-center Body14R lh-20  color-neutral70">
              Don’t have an account yet? 
                <Link to={Object.keys(params).length > 0?'/signup?cw':'/signup'} className="cp  ml_8 color-primary40">
                Sign Up
                {/* Create <span className="capitalize">{agency?.agencyName || "Walletly"}</span> Account */}
              </Link>
              </div>
          </div>
          {/* <div  className='d-flex align-items-center w-100 mt_12'style={{justifyContent:"center"}}>
            <div className="text-center Body14R lh-20  color-primary40">
                <a  href={'https://webapp.walletly.ai'} style={{backgroundColor : '#F2F6FF' , padding : '10px 20px' , borderRadius : '6px'}} className="cp   color-primary40">
                Login with Old Walletly <SvgIcons.RocketArrow />
              </a>
              </div>
          </div> */}
          
        </div>
      </form>
    </>
  );
}

export default Login;