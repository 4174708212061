import { Card, CardActionArea, CardContent, CardMedia, CircularProgress } from '@mui/material';
import { Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import { PngIcons } from '../../../icons';
import { FlowService, PassService, RedeemingWayService } from '../../../services';
// import localforage from 'localforage';
import { BrandContext } from '../../../context/Brand.context';
import CustomButton from '../../../components/CustomButton';
import localforage, * as localForage from "localforage";
import { enums } from '../../../enums';
import axios from 'axios';
import CustomsModal from '../../../components/CustomModal';

function BulkCW() {
    const [filteredData, setFilteredData] = useState();
    const [loader, setLoader] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selected, setSelected] = useState('');
    const brand = useContext(BrandContext)
    
    const [state, setState] = useState({
      apiError: "",
      generalLoader: false,
      apiError: '',
      brandingLoader: false,
      termConditions :false,
      promoData: {},
      loader: false,
      deleteLoader: false,
      deleteModal: false,
      mainLoader: true,
      deleteItem: "",
      deleteIndex: "",
      result: [],
    });
  

    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    function getDayName(number) {
        if (number >= 1 && number <= 7) {
            return dayNames[number % 7]; // Using modulo operator to handle numbers greater than 7
        } else {
            return "Invalid day number"; // Handling invalid numbers
        }
    }

    const onLoad = async () => {

      const query = { brandId: brand._id, delete: false}
      let redeemingWays = await localForage.getItem("redeemingWays");
      console.log('redeemingWays',redeemingWays);
      if(!redeemingWays){
        console.log('redeemingWays in',redeemingWays);
        redeemingWays = await RedeemingWayService.GetQueriedEarningWays({ query })
        console.log('redeemingWays res',redeemingWays);
        if (redeemingWays?.response){
          localForage.setItem("redeemingWays", redeemingWays.response.data);
        }
      }
      
      if (redeemingWays?.response?.data) {
        redeemingWays.response.data.sort((a, b) => {
          if (a.created > b.created) return -1;
          else return 1;
        })
        setState({ ...state, mainLoader: false, redeemingWays: redeemingWays?.response?.data });
      }
      else if (redeemingWays) {console.log('redeemingWays',redeemingWays)
        redeemingWays?.sort((a, b) => {
          if (a?.created > b?.created) return -1;
          else return 1;
        })
        setState({ ...state, mainLoader: false, redeemingWays: redeemingWays });
      }
    };
    
      useEffect(onLoad, [])

      const [htmlCode, setHtmlCode] = useState("<div>Your HTML code here...</div>");

    const handleChange = (e) => {
        setHtmlCode(e.target.value);
    };

      const [subject, setSubject] = useState("Subject");

    const handleChangeSubject = (e) => {
        setSubject(e.target.value);
    };

    const [model, setModel] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);

    const [ca, setca] = useState()
    useEffect(async() => {
        const customers = await localforage.getItem("customersAudience");
        setca(customers)
    }, [])
    

  return (
    <div>
      {console.log('state123',state)
      }
        <div className='d-flex align-items-center space-between mb_8'>
        <div className='B16R color-neutral100'>Drive Engagement with Promotions and Custom Emails</div>
            <CustomButton btntext={"Send"} icon={state.generalLoader && <CircularProgress color='inherit' size={14} />} onClick={async()=>{
                // setState({ generalLoader: true, apiError: '' })
                setModel(true)
                // setState({ generalLoader: false, apiError: '' })
            }} />
        </div>
        <div className='bar mt_10 mb_20'></div>
        <div style={{ 
                                display: 'grid', 
                                // gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', 
                                gridTemplateColumns: 'repeat(2, 1fr)', 
                                gap: '16px' 
                                }}>
                {filteredData && filteredData.length > 0 && 
                filteredData.map((element, index) => (
                  element?.flow?.length > 0 && element.flow.map((ele, ind) => (

                    
                    
                    
                    (ele.state[0].festival==='Custom Date' ||
                    ele.state[0].festival==='Week') &&
                    
                    <Card 
                    style={{
                        backgroundColor: ind === selectedIndex && '#F2F6FF',
                        border: ind === selectedIndex && '2px solid #2960EC'
                    }}
                    onClick={()=>{
                        setSelectedIndex(ind)
                        setSelected('')
                    }}
                    key={`${index}-${ind}`} sx={{ flex: '1 1 300px', height: 'fit-content' }} >
                    {console.log('1',index)}
                    {console.log('2',ind)}
                    <CardActionArea style={{ alignItems: 'flex-start', display: 'flex' }}>
                      <CardContent style={{width: '100%'}} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <div className="d-flex gap-6">
                          <Typography gutterBottom style={{fontSize: '15px', color: '#141519', fontWeight: 500, fontFamily: 'Inter'}} variant="h5" component="div" sx={{fontWeight:'bold'}}>
                          Boost Loyalty with Promotions
                        </Typography>
                          {/* {ele?.active!==false&&<img src={PngIcons.tick} width={16} height={16}/>} */}
                          {<img src={ele.active?PngIcons.tick:PngIcons.tickG} width={16} height={16}/>}
                        </div>
                        <Typography style={{fontSize: '13px', color: '#2E3039', fontWeight: 400, fontFamily: 'Inter'}} color="text.secondary" sx={{
                          overflow: 'hidden',
                          display: '-webkit-box', fontFamily: 'Inter',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2,
                          textOverflow: 'ellipsis',
                        }}>
                          Send bulk emails offering bonus points to boost engagement and loyalty.
                        </Typography>
                      </CardContent>
                      <CardMedia
                        component="img"
                        height="140"
                        style={{ padding: 12, width: '140px' }}
                        image={
                          ele.state[0].key === "referrallogin"? //receiver
                            PngIcons.NewRBlock:
                            ele.state[0].key === "referral"? //sender
                            PngIcons.NewRBlock:
                            ele.state[0].key === "customerInactivity"? 
                            PngIcons.NewIABlock:
                            (ele.state[0].key === "event" && ele.state[0].festival === "Birth Day" ) ? 
                            PngIcons.newBD:
                            (ele.state[0].key === "visit"||(ele.state[0].key === "event" && (ele.state[0].festival === "Custom Date"||ele.state[0].festival === "Week"))) ? 
                            PngIcons.newCouponBlock:
                            ele.state[0].key === "point"? 
                            PngIcons.newRewardBlock:
                          'https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg'}
                        alt={ele.block || element.name || element.key}
                      />
                    </CardActionArea>
                  </Card>
                  
                ))))
              }
              {state?.redeemingWays && state?.redeemingWays.length > 0 && 
                state?.redeemingWays.map((element, ind) => (
                  element.redeemingWayType === enums.RedeemingWays.PROMOTION&&
                  
                  <Card
                  style={{
                    backgroundColor: ind === selectedIndex && '#F2F6FF',
                    border: ind === selectedIndex && '2px solid #2960EC'
                }}
                 key={ind} sx={{ flex: '1 1 300px', height: 'fit-content' }} onClick={
                    () => {
                      setSelectedIndex(ind)
                        setSelected('')
                  }
                  }>
                    <CardActionArea style={{ alignItems: 'flex-start', display: 'flex' }}>
                      <CardContent style={{width: '100%'}} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <div className="d-flex gap-6">
                          <Typography gutterBottom style={{fontSize: '15px', color: '#141519', fontWeight: 500, fontFamily: 'Inter'}} variant="h5" component="div" sx={{fontWeight:'bold'}}>
                          {'Promotion'}
                          </Typography>
                          {<img src={element.active?PngIcons.tick:PngIcons.tickG} width={16} height={16}/>}
                        </div>
                        <Typography variant="h6" color="text.secondary" style={{fontSize: '14px', color: '#2E3039', fontWeight: 400, fontFamily: 'Inter'}} sx={{
                          overflow: 'hidden',
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2,
                          textOverflow: 'ellipsis',
                        }}>
                          {console.log('element222',element)}
                          {element.redeemingWayType === enums.RedeemingWays.REFERRAL?"Share the love, earn the points": 
                          element.redeemingWayType === enums.RedeemingWays.INACTIVITY ? "Reactivation Bonus: "+element.rewardValue.offerPoints+" bonus points for reactivating an inactive account":
                          element.redeemingWayType === enums.RedeemingWays.BIRTHDAY ? "Delight customers with special points on their special day":
                          element.redeemingWayType === enums.RedeemingWays.PROMOTION ? "Boost loyalty with multiplied points on selected dates":
                          element.redeemingWayType === enums.RedeemingWays.PRODUCT ? 'Point Reward: Coupon “'+element.rewardValue.cwCode+'” for '+element.rewardValue.costingPoint+' points':
                          'Else'}
                        </Typography>
                      </CardContent>
                      <CardMedia
                        component="img"
                        height="140"
                        style={{ padding: 12, width: '140px' }}
                        image={element.redeemingWayType === enums.RedeemingWays.PRODUCT
                          ? PngIcons.newRewardBlock
                          : element.redeemingWayType ===
                            enums.RedeemingWays.BIRTHDAY
                            ? PngIcons.newBD
                            : element.redeemingWayType === enums.RedeemingWays.REFERRAL
                            ? PngIcons.NewRBlock
                            : element.redeemingWayType ===
                            enums.RedeemingWays.PROMOTION
                            ? PngIcons.newPBlock
                            : element.redeemingWayType ===
                            enums.RedeemingWays.TAG
                            ? PngIcons.automationTags
                            : element.redeemingWayType ===
                            enums.RedeemingWays.INACTIVITY
                            ? PngIcons.NewIABlock
                            :''}
                        alt={element.earningName}
                      />
                    </CardActionArea>
                  </Card>
                ))
              }
              <Card 
                style={{
                    backgroundColor: selected === 'Custom' && '#F2F6FF',
                    border: selected === 'Custom' && '2px solid #2960EC'
                    }}
                key={0} sx={{ flex: '1 1 300px', height: 'fit-content' }} onClick={() => {
                    setSelected('Custom')
                    setSelectedIndex('')
                //   
                }}>
                  <CardActionArea style={{ alignItems: 'flex-start', display: 'flex' }}>
                    <CardContent style={{width: '100%'}} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Typography gutterBottom style={{fontSize: '15px', color: '#141519', fontWeight: 500, fontFamily: 'Inter'}} variant="h5" component="div" sx={{fontWeight:'bold'}}>
                    {"Compose Custom Emails"}
                        </Typography>
                      <Typography variant="h6" style={{ fontFamily: 'Inter'}} color="text.secondary" sx={{
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2, // Limits to 2 lines
                        textOverflow: 'ellipsis',
                      }}>
                        Send personalized emails with offers, updates, and messages to engage customers.
                      </Typography>
                    </CardContent>
                    <CardMedia
                      component="img"
                      height="140"
                      // width="140px"
                      style={{ padding: 12, width: '140px' }}
                      image={PngIcons.newCouponBlock}
                      alt={'Referral'}
                    />
                  </CardActionArea>
                </Card>
            </div>
            <div className='bar mt_10 mb_20'></div>
            {selected!==''&&
            <div className='pt_28 mt_42 ' style={{background: '#F5F7FC'}}>
                <div className='Heading22M color-neutral100' style={{ padding: "15px", maxWidth: "800px", margin: "auto" }}>
                    {'Design your own email using our easy-to-use builder:  '}
                    <a className='underline' target="_blank" href='https://usewaypoint.github.io/email-builder-js/#sample/reservation-reminder'>{' Email Builder'}</a>.<br/>
                        Simply create your email, copy the HTML, and send it to your audience!</div>
                <div className='d-flex'>
                    <div>
                        <div style={{ padding: "20px", maxWidth: "800px", margin: "auto" }}>
                            <h2>Subject:</h2>
                            <input
                                value={subject}
                                onChange={handleChangeSubject}
                                // rows="15"
                                // cols="80"
                                style={{
                                    width: "100%",
                                    // height: "300px",
                                    fontFamily: "monospace",
                                    fontSize: "14px",
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                }}
                            />
                        </div>
                        <div style={{ padding: "20px", maxWidth: "800px", margin: "auto" }}>
                            <h2>Paste Custom HTML here:</h2>
                            <textarea
                                value={htmlCode}
                                onChange={handleChange}
                                rows="15"
                                cols="80"
                                style={{
                                    width: "100%",
                                    height: "300px",
                                    fontFamily: "monospace",
                                    fontSize: "14px",
                                    padding: "10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "5px",
                                }}
                            />
                        </div>
                    </div>
                    <div className='middle'><img src={'https://imagedelivery.net/xom6JLKEu4N3wOOpuxFt7Q/ceda1dc4-c77e-4e2e-1b6e-61a6e75a5100/Digi'} /></div>
                </div>
            </div>}
            {selected===''&&<div className='middle pt_28 mt_42' style={{background: '#F5F7FC'}}><img src={'https://imagedelivery.net/xom6JLKEu4N3wOOpuxFt7Q/3bfabb3f-2f39-45d7-0693-35e7063d7700/Digi'} /></div>}
    
            <CustomsModal
        open={model}
        minWidth={"600px"}
        component={<>
          <div className="H22M">Confirm</div>
          <div className="B14R mt_10 mb_20">{'Are you sure you want to send bulk sms to '+ca?.length+' audience?'}</div>
          <div className="d-flex justify-content-end gap-10">
            <CustomButton
              varient="primary"
              btntext={"YES"}
              icon={deleteLoader &&
                <CircularProgress
                    size={20}
                    color={'inherit'} />}
              onClick={async() => {
                setDeleteLoader(true)
                // 
                if(selected===''){
                  const response = await axios.post('https://api.walletly.ai/api/v4/pass/sendBulkPromo', {
                    brandId: brand._id,
                  });
              
                  // Handle the response
                  console.log('Response:', response.data);
              }
              else{
                  const api = await PassService.sendBulkCustomInPerson({toaster: true,payload: {
                      brandId: brand._id,
                      subject: subject,
                      data: htmlCode
  
                  }})
                  console.log('api',api)
              }
                // 
              setDeleteLoader(false)
              setModel(false)
              }}
            />
            <CustomButton
              varient="secondary"
              btntext={"NO"}
              onClick={async() => {
                setModel(false)
              }}
            />
          </div>

        </>
        }
        // onClose={() => setrefmodel(false)}
      />
    
    </div>
  )
}

export default BulkCW