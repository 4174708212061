import React, { useContext, useEffect, useState } from 'react';
import {Redirect, Switch} from "react-router-dom";

import {BrandContextComponent } from '../context/Brand.context';


import SidebarNav from '../components/sidebar/SidebarNav';
import Loyalty from '../pages/loyalty/Loyalty';
import GrowthTools from '../pages/growthTool/GrowthTool';
import Dashboard from './dashboard/Dashboard';
import WalletScan from './walletscan/WalletScan';
import Automation from './automation/Automation';
import Communication from '../pages/communication/Communication';
import Redemption from '../pages/redemption/Redemption';
import Referral from '../pages/referral/Referral';
import Setting from '../pages/setting/Setting';
import Profile from '../pages/userprofile/Profile';

import { RoleGuard } from '../utils/RouteGuards';
import { UserContext } from '../context/User.context';
import { LayoutContext } from '../context/Layout.context';
import {User } from '../enums';

import Campaigns from './campaigns/Campaigns';
import inventory from './inventory/Inventory';
import DealMain from './deal/Main';
import Giveaway from './giveaway/Giveaway';
import SettingHeader from '../components/setting/SettingHeader';
import Audience from './dashboard/subpages/Audience';
import VipClub from './VipClub/VipClub';
import Transaction from './Transaction';
import UpdateAudience from './dashboard/subpages/UpdateAudience';

function Main(props) {
  const user = useContext(UserContext)
  const layout = useContext(LayoutContext)

  const [mobileCheck, setMobileCheck] = useState(false)

  const getHomePage = () => {
    if(!user.activePermission) return '/'
    if(user.activePermission.roles.includes(User.Roles.ADMIN)) return '/dashboard'
    if(user.activePermission.roles.includes(User.Roles.MARKETER)) return '/walletscan'
  }

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };


  useEffect(() => {

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };

  }, []);

  useEffect(()=>{
    if(scrollPosition > 0){
        layout.setLayout({
          scrollShadow: true,
      });
    }
    else{
      layout.setLayout({
        scrollShadow: false,
    });
    }
  },[scrollPosition])

  return (
      <BrandContextComponent {...props} >
        <div id="Main" className="d-flex">
          <SidebarNav mobileCheck={mobileCheck} setMobileCheck={setMobileCheck}/>
            <div className='w-100' style={{backgroundColor : '#FCFDFD'}}>
            <SettingHeader/>
              <Switch> 
                  <RoleGuard role = {[User.Roles.ADMIN]} path = {props.match.url+ '/dashboard'}     component  = {Dashboard}    />
                  <RoleGuard exact role = {[User.Roles.ADMIN]} path = {props.match.url+ '/audience'}       component = {Audience}    />
                  <RoleGuard exact role = {[User.Roles.ADMIN]} path = {props.match.url+ '/audience/update'}       component = {UpdateAudience}    />
                  <RoleGuard role = {[User.Roles.ADMIN]} path = {props.match.url+ '/transaction'}       component = {Transaction}    />
                  <RoleGuard role = {[User.Roles.ADMIN]} path = {props.match.url+ '/loyalty'}       component  = {Loyalty}    />
                  <RoleGuard role = {[User.Roles.ADMIN]} path = {props.match.url+ '/redemption'}    component  = {Redemption}   />
                  <RoleGuard role = {[User.Roles.ADMIN]} path = {props.match.url+ '/referral'}      component  = {Referral}     />
                  <RoleGuard role = {[User.Roles.ADMIN]} path = {props.match.url+ '/growthtools'}   component  = {GrowthTools}  />
                  {/* <RoleGuard role = {[User.Roles.ADMIN]} path = {props.match.url+ '/automation'}    component  = {Automation}  /> */}
                  <RoleGuard role = {[User.Roles.ADMIN]} path = {props.match.url+ '/inventory'}     component = {inventory}  />
                  <RoleGuard role = {[User.Roles.ADMIN]} path = {props.match.url+ '/deal'}          component = {DealMain}  />
                  <RoleGuard role = {[User.Roles.ADMIN]} path = {props.match.url+ '/giveaway'}      component = {Giveaway}  />
                  <RoleGuard role = {[User.Roles.ADMIN]} path = {props.match.url+ '/campaigns'}     component = {Campaigns}    />
                  <RoleGuard role = {[User.Roles.ADMIN]} path = {props.match.url+ '/communication'} component = {Communication}/>
                  <RoleGuard role = {[User.Roles.ADMIN]} path = {props.match.url+ '/vipclub'} component       = {VipClub}/>
                  {/* <RoleGuard role={[User.Roles.ADMIN]} path = {props.match.url+ '/automation'}    component = {Automation}   /> */}
                  <RoleGuard role={[User.Roles.ADMIN]} path = {props.match.url+ '/setting'}       component = {Setting}      />
                  
                  <RoleGuard role={[User.Roles.ADMIN, User.Roles.MARKETER]} path = {props.match.url+ '/walletscan'}    component = {WalletScan}   />
                  
                  <RoleGuard path = {props.match.url+ '/profile'}  component = {Profile} />

                  <Redirect path = {props.match.url+ '/*'} to = {props.match.url+ getHomePage()} />
                  <Redirect path = {props.match.url} to = {props.match.url+ getHomePage()} />
              </Switch>
            </div>
          </div> 
      </BrandContextComponent>
    )
}

export default Main;
