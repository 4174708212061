import React, { useContext, useEffect, useState } from 'react'
import CustomButton from '../../../components/CustomButton'
import { BrandContext } from '../../../context/Brand.context'
import { BrandService, PassService, RedeemingWayService } from '../../../services'
import localforage from 'localforage'
import { Box, Card, CardActionArea, CardContent, CardMedia, CircularProgress, Tab, Tabs, Typography } from '@mui/material'
import Loader from '../../../components/Loader'
import PropTypes from 'prop-types';
import PushNotificationList from '../../communication/subpages/Notification/PushNotificationList'
import Bulk from './Bulk'
import ReferralPage from './ReferralPage'
import BulkCW from './BulkCW'
import { PngIcons } from '../../../icons'
import { LayoutContext } from '../../../context/Layout.context'
import CustomsModal from '../../../components/CustomModal'


function Notifications(props) {

    const brand = useContext(BrandContext)
    const layout = useContext(LayoutContext)

    

    const [state, setState] = useState({
        loader: false,
        generalLoader: false,
        apiError: '',
        brandingLoader: false,
        termConditions :false,
        promoData: {}
    })
    
    const [data, setdata] = useState({
        emailCheck: brand?.emailCheck||false,
        TransactionSmsCheck: brand?.TransactionSmsCheck||false,
        TransactionEmailCheck: brand?.TransactionEmailCheck||false,
        WelcomeSMSCheck: brand?.WelcomeSMSCheck||false,
        WelcomeEmailCheck: brand?.WelcomeEmailCheck||false,
        RedeemEmailCheck: brand?.RedeemEmailCheck||false,
        RedeemSMSCheck: brand?.RedeemSMSCheck||false,
        PointExpireSMSCheck: brand?.PointExpireSMSCheck||false,
        PointExpireEmailCheck: brand?.PointExpireEmailCheck||false,
        InactivityEmailCheck: brand?.InactivityEmailCheck||false,
        InactivitySMSCheck: brand?.InactivitySMSCheck||false,
        PromotionEmailCheck: brand?.PromotionEmailCheck||false,
        PromotionSMSCheck: brand?.PromotionSMSCheck||false,
        ReferralEmailCheck: brand?.ReferralEmailCheck||false,
        ReferralSMSCheck: brand?.ReferralSMSCheck||false,
        BdayFollowUpEmailCheck: brand?.BdayFollowUpEmailCheck||false,
        BdayFollowUpSMSCheck: brand?.BdayFollowUpSMSCheck||false,
        BdayPromotionEmailCheck: brand?.BdayPromotionEmailCheck||false,
        BdayPromotionSMSCheck: brand?.BdayPromotionSMSCheck||false,
        // promoCheck: false
    })
    
    const [ca, setca] = useState()
    useEffect(async() => {
        const customers = await localforage.getItem("customersAudience");
        setca(customers)
    }, [])
    

    // useEffect(async() => {
    //     const query = { brandId: brand._id, delete: false, redeemingWayType: 'promotion' }
    //     const redeemingWays = await RedeemingWayService.GetQueriedEarningWays({ query })
    //     console.log(redeemingWays.response.data[0].notification)
    //     setdata({...data, promoCheck: redeemingWays.response.data[0].notification})
    //     setState({...state, promoData: redeemingWays.response.data[0], loader: false})
    //     // redeemingWays.response.data
    // }, [])
    
    const [value, setValue] = React.useState(0);
    const [message, setMessage] = React.useState('');

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const [input, setInput] = useState('');

  const sendMessage = () => {
    if (input.trim()) {
    //   setMessages([...messages, { id: messages.length + 1, text: input, sender: "self" }]);
      setInput('');
    }
  };

    useEffect(() => {
        layout.setLayout({
          borderBottom: false,
          edit: false,
          switch: "",
          title: "Settings",
          button: 
          value===0?
          <CustomButton className={data?.emailCheck!==true&&'disabled'} btntext={"Update"} icon={state.generalLoader && <CircularProgress color='inherit' size={14} />} onClick={async()=>{
            setState({ generalLoader: true, apiError: '' })
            await handlePublicProfileUpdateFunc();
            setState({ generalLoader: false, apiError: '' })
        }} />
        :<></>
        })
      }, [data, state, value])


    const handlePublicProfileUpdateFunc = async () => {
        setState({ generalLoader: true, apiError: '' })

        // const user = await localforage.getItem('user')
        // const permissions = await localforage.getItem('permissions')

        const payload = {
            _id: brand._id,
            // emailCheck: data.emailCheck,
            TransactionSmsCheck: data.TransactionSmsCheck,
            TransactionEmailCheck: data.TransactionEmailCheck,
            WelcomeEmailCheck: data.WelcomeEmailCheck,
            RedeemEmailCheck: data.RedeemEmailCheck,
            WelcomeSMSCheck: data.WelcomeSMSCheck,
            RedeemSMSCheck: data.RedeemSMSCheck,
            PointExpireSMSCheck: data.PointExpireSMSCheck,
            PointExpireEmailCheck: data.PointExpireEmailCheck,
            InactivitySMSCheck: data.InactivitySMSCheck,
            InactivityEmailCheck: data.InactivityEmailCheck,
            PromotionEmailCheck: data.PromotionEmailCheck,
            PromotionSMSCheck: data.PromotionSMSCheck,
            ReferralEmailCheck: data.ReferralEmailCheck,
            ReferralSMSCheck: data.ReferralSMSCheck,
            BdayFollowUpEmailCheck: data.BdayFollowUpEmailCheck,
            BdayFollowUpSMSCheck: data.BdayFollowUpSMSCheck,
            BdayPromotionEmailCheck: data.BdayPromotionEmailCheck,
            BdayPromotionSMSCheck: data.BdayPromotionSMSCheck
        }

        const { error, response } = await BrandService.Update({ toaster: true, payload })
        console.log('error ', error);
        console.log('response', response);

        // const updateWays = await RedeemingWayService.Update({ payload: {_id: state?.promoData?._id , notification: data.promoCheck} });
        // console.log('updateWays',updateWays);
        
        if (error) return setState({ generalLoader: false, brandingLoader: false, apiError: error.error })

        brand.updateBrand(response.data)
        // return setState({ generalLoader: false, apiError: '' })





        
        
    }

    const [model, setModel] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);
    

  return (
    <div id='notificationsAll'>
        {console.log('value',value)}
        
    {
        state.loader?<Loader />:
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs 
            sx={{
                '& .MuiTab-root': {
                  color: '#aaa', // Light color for unselected tabs
                  textTransform: 'none', // Prevents uppercase transformation
                  minWidth: 120, // Adjusts the width of tabs
                  marginRight: 2, // Adds spacing between tabs
                },
                '& .Mui-selected': {
                  color: '#000', // Darker color for selected tab
                  fontWeight: 'bold', // Bold text for selected tab
                },
              }}
            value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label={<div className='tab-text'>SMS / EMAIL</div>} {...a11yProps(0)} />
                <Tab label={<div className='tab-text'>REFERRAL</div>} {...a11yProps(1)} />
                <Tab label={<div className='tab-text'>PROMOTION</div>} {...a11yProps(2)} />
                <Tab label={<div className='tab-text'>BULK SMS</div>} {...a11yProps(3)} />
                {!brand?.integrations?.cloudwaitress?.length>0&&<Tab label={<div className='tab-text'>PUSH NOTIFICATIONS</div>} {...a11yProps(4)} />}
            </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <div className={data?.emailCheck!==true&&'disabled'} style={{width: 'fit-content'}}>
                    <div className='d-flex align-items-center space-between mb_8'>
                        <div className='B16R color-neutral100'>Manage SMS and email updates for loyalty, promotions, and engagement.</div>
                        {/* <CustomButton btntext={"Update"} icon={state.generalLoader && <CircularProgress color='inherit' size={14} />} onClick={async()=>{
                            setState({ generalLoader: true, apiError: '' })
                            await handlePublicProfileUpdateFunc();
                            setState({ generalLoader: false, apiError: '' })
                        }} /> */}
                    </div>
                    <div className='bar mt_10 mb_20'></div>
                    {/* <input type="checkbox" checked={data.emailCheck} onChange={(e)=>{
                        if(e.target.checked === false){
                            setdata({...data, emailCheck: e.target.checked
                                // , promoCheck: e.target.checked
                            })
                        }
                        else{
                            setdata({...data, emailCheck: e.target.checked})
                        }
                        }} />
                    <label className='notiText5 ml_10' for="vehicle1"> Turn on Email/SMS notifications</label>
                    <div className='bar mt_10 mb_20'></div> */}
                    <div className='row notiText1'>
                        <div className='col-5'>NAME</div>
                        <div className='col-3 col-end'>EMAIL</div>
                        <div className='col-4 col-center'>SMS</div>
                    </div>
                    <div className='bar mt_10 mb_20'></div>
                    <div className='notiText2'>Customer Activity Notifications</div>
                    <div className='bar mt_10 mb_20'></div>
                    <div className='row'>
                        <div className='col-5'>
                            <div className='notiText4'>Welcome</div>
                            <div className='notiText3'>Notify customers when they sign up for loyalty; this email/SMS is triggered upon loyalty signup.</div>
                        </div>
                        <div className='col-3 col-end'>
                            <input 
                            // className='disabled' 
                            checked={data.WelcomeEmailCheck} onChange={(e)=>{if(data.emailCheck===true)setdata({...data, WelcomeEmailCheck: e.target.checked})}}
                            type="checkbox"
                            />
                            </div>
                        <div className='col-4 col-center'>
                            <input 
                            checked={data.WelcomeSMSCheck} 
                            onChange={(e)=>{if(data.emailCheck===true)setdata({...data, WelcomeSMSCheck: e.target.checked})}}
                            // className='disabled'
                            
                            type="checkbox"/></div>
                    </div>
                    <div className='bar mt_10 mb_20'></div>
                    <div className='row'>
                        <div className='col-5'>
                            <div className='notiText4'>Points Update</div>
                            <div className='notiText3'>Notify customers when their points balance updates; this email/SMS is triggered upon each transaction.</div>
                        </div>
                        <div className='col-3 col-end'>
                            <input 
                            
                            checked={data.TransactionEmailCheck}
                            onChange={(e)=>{if(data.emailCheck===true)setdata({...data, TransactionEmailCheck: e.target.checked})}}
                            type="checkbox"/></div>
                        <div className='col-4 col-center'>
                        <input type="checkbox" 
                        checked={data.TransactionSmsCheck}
                         onChange={(e)=>{
                                if(data.emailCheck===true)setdata({...data, TransactionSmsCheck: e.target.checked})}
                                } />
                            </div>
                    </div>
                    <div className='bar mt_10 mb_20'></div>
                    <div className='row'>
                        <div className='col-5'>
                            <div className='notiText4'>Redeem</div>
                            <div className='notiText3'>Notify customers when they redeem points for rewards or discounts.</div>
                        </div>
                        <div className='col-3 col-end'>
                            <input 
                            // className='disabled' 
                            checked={data.RedeemEmailCheck}
                            onChange={(e)=>{if(data.emailCheck===true)setdata({...data, RedeemEmailCheck: e.target.checked})}}
                            type="checkbox"/>
                            </div>
                        <div className='col-4 col-center'>
                            <input 
                            // className='disabled' 
                            
                            checked={data.RedeemSMSCheck}
                            onChange={(e)=>{if(data.emailCheck===true)setdata({...data, RedeemSMSCheck: e.target.checked})}}
                            type="checkbox"/></div>
                    </div>
                    <div className='bar mt_10 mb_20'></div>
                    <div className='row'>
                        <div className='col-5'>
                            <div className='notiText4'>Point Expire</div>
                            <div className='notiText3'>Notify customers when their loyalty points are close to expiring, encouraging them to redeem.</div>
                        </div>
                        <div className='col-3 col-end'>
                            <input 
                            checked={data.PointExpireEmailCheck}
                            onChange={(e)=>{if(data.emailCheck===true)setdata({...data, PointExpireEmailCheck: e.target.checked})}}
                             type="checkbox"/></div>
                        <div className='col-4 col-center'><input checked={data.PointExpireSMSCheck}
                            onChange={(e)=>{if(data.emailCheck===true)setdata({...data, PointExpireSMSCheck: e.target.checked})}} type="checkbox"/></div>
                    </div>
                    <div className='bar mt_10 mb_20'></div>
                    <div className='row'>
                        <div className='col-5'>
                            <div className='notiText4'>Inactivity</div>
                            <div className='notiText3'>Notify customers when they’ve been inactive for a while, inviting them back to engage with your brand.</div>
                        </div>
                        <div className='col-3 col-end'>
                            <input
                            
                            checked={data.InactivityEmailCheck}
                            onChange={(e)=>{if(data.emailCheck===true)setdata({...data, InactivityEmailCheck: e.target.checked})}} type="checkbox"/></div>
                        <div className='col-4 col-center'><input checked={data.InactivitySMSCheck}
                            onChange={(e)=>{if(data.emailCheck===true)setdata({...data, InactivitySMSCheck: e.target.checked})}} type="checkbox"/></div>
                    </div>
                    <div className='bar mt_10 mb_20'></div>
                    <div className='notiText2'>Planned Engagement Notifications</div>
                    <div className='bar mt_10 mb_20'></div>
                    <div className='row'>
                        <div className='col-5'>
                            <div className='notiText4'>Promotion</div>
                            <div className='notiText3'>Notify customers when there’s a promotional offer or special deal available.</div>
                        </div>
                        <div className='col-3 col-end'>
                            {/* <input className='' checked={data.emailCheck} type="checkbox"/> */}
                            <input type="checkbox" checked={data.PromotionEmailCheck} onChange={(e)=>{if(data.emailCheck===true)setdata({...data, PromotionEmailCheck: e.target.checked})}}
                            // onChange={(e)=>{
                            //     if(data.emailCheck===true)setdata({...data, promoCheck: e.target.checked})}
                            //     } 
                                />
                            </div>
                        <div className='col-4 col-center'><input checked={data.PromotionSMSCheck} onChange={(e)=>{if(data.emailCheck===true)setdata({...data, PromotionSMSCheck: e.target.checked})}} type="checkbox"/></div>
                    </div>
                    <div className='bar mt_10 mb_20'></div>
                    <div className='row'>
                        <div className='col-5'>
                            <div className='notiText4'>Referral</div>
                            <div className='notiText3'>Notify customers when they can earn rewards for referring friends to your brand.</div>
                        </div>
                        <div className='col-3 col-end'><input checked={data.ReferralEmailCheck} onChange={(e)=>{if(data.emailCheck===true)setdata({...data, ReferralEmailCheck: e.target.checked})}} type="checkbox"/></div>
                        <div className='col-4 col-center'><input checked={data.ReferralSMSCheck} onChange={(e)=>{if(data.emailCheck===true)setdata({...data, ReferralSMSCheck: e.target.checked})}} type="checkbox"/></div>
                    </div>
                    <div className='bar mt_10 mb_20'></div>
                    <div className='row'>
                        <div className='col-5'>
                            <div className='notiText4'>Birthday Followup</div>
                            <div className='notiText3'>Notify customers when it’s time to celebrate their birthday with a follow-up message.</div>
                        </div>
                        <div className='col-3 col-end'><input checked={data.BdayFollowUpEmailCheck} onChange={(e)=>{if(data.emailCheck===true)setdata({...data, BdayFollowUpEmailCheck: e.target.checked})}} type="checkbox"/></div>
                        <div className='col-4 col-center'><input checked={data.BdayFollowUpSMSCheck} onChange={(e)=>{if(data.emailCheck===true)setdata({...data, BdayFollowUpSMSCheck: e.target.checked})}} type="checkbox"/></div>
                    </div>
                    <div className='bar mt_10 mb_20'></div>
                    <div className='row'>
                        <div className='col-5'>
                            <div className='notiText4'>Birthday Promotion</div>
                            <div className='notiText3'>Notify customers when a special birthday promotion or reward is available to them.</div>
                        </div>
                        <div className='col-3 col-end'><input checked={data.BdayPromotionEmailCheck} onChange={(e)=>{if(data.emailCheck===true)setdata({...data, BdayPromotionEmailCheck: e.target.checked})}} type="checkbox"/></div>
                        <div className='col-4 col-center'><input checked={data.BdayPromotionSMSCheck} onChange={(e)=>{if(data.emailCheck===true)setdata({...data, BdayPromotionSMSCheck: e.target.checked})}} type="checkbox"/></div>
                    </div>
                    <div className='bar mt_10 mb_20'></div>

                </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <ReferralPage />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                {brand?.integrations?.cloudwaitress?.length>0?<BulkCW />:<Bulk/>}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
            <div className='B16R color-neutral100'>Engage Your Customers with Bulk SMS</div>
            <div className='bar mt_10 mb_20'></div>
            <div style={{ 
                                display: 'grid', 
                                // gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', 
                                gridTemplateColumns: 'repeat(2, 1fr)', 
                                gap: '16px' 
                                }}>

            <Card 
                style={{
                    backgroundColor: '#F2F6FF',
                    border: '2px solid #2960EC'
                    }}
                key={0} sx={{ flex: '1 1 300px', height: 'fit-content' }} >
                  <CardActionArea style={{ alignItems: 'flex-start', display: 'flex' }}>
                    <CardContent style={{width: '100%'}} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Typography gutterBottom style={{fontSize: '15px', color: '#141519', fontWeight: 500, fontFamily: 'Inter'}} variant="h5" component="div" sx={{fontWeight:'bold'}}>
                    {"Send Personalized SMS to Customers"}
                        </Typography>
                      <Typography variant="h6" style={{ fontFamily: 'Inter'}} color="text.secondary" sx={{
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        // WebkitLineClamp: 2, // Limits to 2 lines
                        // textOverflow: 'ellipsis',
                      }}>
                        Send custom SMS instantly to share updates, promotions, or reminders
                      </Typography>
                    </CardContent>
                    <CardMedia
                      component="img"
                      height="140"
                      // width="140px"
                      style={{ padding: 12, width: '140px' }}
                      image={PngIcons.newCouponBlock}
                      alt={'Referral'}
                    />
                  </CardActionArea>
                </Card>
                                </div>
                {/* <div className='d-flex align-items-center space-between mb_8'>
                    <div className='Heading22M color-neutral100'>Engage Your Customers with Bulk SMS</div>
                    <CustomButton btntext={"Send"} icon={state.generalLoader && <CircularProgress color='inherit' size={14} />} 
                    onClick={async()=>{
                        setState({ generalLoader: true, apiError: '' })
                        // await handlePublicProfileUpdateFunc();
                        const api = await PassService.sendBulkSMS({toaster: true,payload: {
                            brandId: brand._id,
                            data: message
        
                        }})
                        console.log('api',api)
                        
                        setState({ generalLoader: false, apiError: '' })
                    }} />
                </div> */}
                {/* <div className='bar mt_10 mb_20'></div> */}
                <div className='pt_28 d-flex gap-16 mt_42 p_20' style={{background: '#F5F7FC'}}>
                    {/* <div className='w-100'>
                        <h2>Custom Message:</h2>
                        <input
                            value={message}
                            onChange={(e)=>setMessage(e.target.value)}
                            // rows="15"
                            // cols="80"
                            style={{
                                width: "100%",
                                // height: "300px",
                                fontFamily: "monospace",
                                fontSize: "14px",
                                padding: "10px",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                            }}
                        />
                    </div> */}
                    <div className="sms-container">
                        <div className="sms-header">Custom Message</div>
                        <div className="sms-messages">
                            {[{ id: 1, text: message||"Hey, how's it going?", sender: "other" }].map((msg) => (
                            <div key={msg.id} className={`sms-bubble ${msg.sender}`}>
                                {msg.text}
                            </div>
                            ))}
                        </div>
                        <div className="sms-input-container">
                            <input
                            type="text"
                            className="sms-input"
                            placeholder="Type a message..."
                            value={message}
                            onChange={(e)=>setMessage(e.target.value)}
                            />
                            <button className="sms-send-button" 
                            onClick={async()=>{
                                // setState({ generalLoader: true, apiError: '' })
                                // await handlePublicProfileUpdateFunc();
                                setModel(true)
                                
                                // setState({ generalLoader: false, apiError: '' })
                            }}
                            >
                            {state.generalLoader && <CircularProgress className='mr_8' color='inherit' size={14} />}
                            Send
                            </button>
                        </div>
                        </div>
                </div>
            </CustomTabPanel>
            {!brand?.integrations?.cloudwaitress?.length>0&&<CustomTabPanel value={value} index={4}>
                <PushNotificationList {...props} />
            </CustomTabPanel>}
        </Box>
    }


<CustomsModal
                open={model}
                minWidth={"600px"}
                component={<>
                <div className="H22M">Confirm</div>
                <div className="B14R mt_10 mb_20">{'Are you sure you want to send bulk sms to '+ca?.length+' audience?'}</div>
                <div className="d-flex justify-content-end gap-10">
                    <CustomButton
                    varient="primary"
                    btntext={"YES"}
                    icon={deleteLoader &&
                        <CircularProgress
                            size={20}
                            color={'inherit'} />}
                    onClick={async() => {
                        setDeleteLoader(true)
                        // 
                        const api = await PassService.sendBulkSMS({toaster: true,payload: {
                            brandId: brand._id,
                            data: message
        
                        }})
                        console.log('api',api)
                        // 
                    setDeleteLoader(false)
                    setModel(false)
                    }}
                    />
                    <CustomButton
                    varient="secondary"
                    btntext={"NO"}
                    onClick={async() => {
                        setModel(false)
                    }}
                    />
                    </div>
                    </>
                    }
                    // onClose={() => setrefmodel(false)}
                    />
    </div>
  )
}

export default Notifications



function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }