import { Box, Card, CardActionArea, CardContent, CardMedia, CircularProgress, Tab, Tabs, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import TextEditor from './TextEditor';
import { PngIcons } from '../../../icons';
import CustomButton from '../../../components/CustomButton';
import { PassService } from '../../../services';
import { BrandContext } from '../../../context/Brand.context';
import axios from 'axios';
import CustomsModal from '../../../components/CustomModal';
import localforage from 'localforage';

function ReferralPage() {
    const [value, setValue] = React.useState(0);
    const brand = useContext(BrandContext)
    const [selected, setSelected] = React.useState('Referral');
    const [msg, setMsg] = React.useState('');

    const [state, setState] = React.useState({
        loader: true,
        generalLoader: false,
        apiError: '',
        brandingLoader: false,
        termConditions :false,
        promoData: {}
    })

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };


    const [model, setModel] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);

    const [ca, setca] = useState()
    useEffect(async() => {
        const customers = await localforage.getItem("customersAudience");
        setca(customers)
    }, [])
    

  return (
    <div>
        <div className='d-flex align-items-center space-between mb_8'>
            <div className='B16R color-neutral100'>Grow Your Business with Referrals and Competitions</div>
            <CustomButton btntext={"Send"} icon={state.generalLoader && <CircularProgress color='inherit' size={14} />} onClick={async()=>{
                // setState({ generalLoader: true, apiError: '' })
                // await handlePublicProfileUpdateFunc();
                setModel(true)
                // setState({ generalLoader: false, apiError: '' })
            }} />
        </div>
        <div className='bar mt_10 mb_20'></div>
        <div>
          {
          
            <div style={{ 
              display: 'flex', 
              gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))', 
              gap: '16px' 
            }}>
                <Card 
                style={{
                    backgroundColor: selected === 'Referral' && '#F2F6FF',
                    border: selected === 'Referral' && '2px solid #2960EC'
                    }}
                key={0} sx={{ flex: '1 1 300px', height: 'fit-content' }} onClick={() => {
                    setSelected('Referral')
                //   
                }}>
                  <CardActionArea style={{ alignItems: 'flex-start', display: 'flex' }}>
                    <CardContent style={{width: '100%'}} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Typography gutterBottom style={{fontSize: '15px', color: '#141519', fontWeight: 500, fontFamily: 'Inter'}} variant="h5" component="div" sx={{fontWeight:'bold'}}>
                    Send Bulk Referral Emails
                      </Typography>
                      <Typography variant="h6" style={{ fontFamily: 'Inter'}} color="text.secondary" sx={{
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        // WebkitLineClamp: 2, // Limits to 2 lines
                        // textOverflow: 'ellipsis',
                      }}>
                        Easily send referral emails to your customers and reward them for bringing in new customers.
                      </Typography>
                    </CardContent>
                    <CardMedia
                      component="img"
                      height="140"
                      // width="140px"
                      style={{ padding: 12, width: '140px' }}
                      image={PngIcons.NewRBlock}
                      alt={'Referral'}
                    />
                  </CardActionArea>
                </Card>
                <Card 
                style={{
                    backgroundColor: selected === 'Competition' && '#F2F6FF',
                    border: selected === 'Competition' && '2px solid #2960EC'
                    }}
                key={1} sx={{ flex: '1 1 300px', height: 'fit-content' }} onClick={() => {
                    setSelected('Competition')
                //   
                }}>
                  <CardActionArea style={{ alignItems: 'flex-start', display: 'flex' }}>
                    <CardContent style={{width: '100%'}} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Typography gutterBottom style={{fontSize: '15px', color: '#141519', fontWeight: 500, fontFamily: 'Inter'}} variant="h5" component="div" sx={{fontWeight:'bold'}}>
                    Send Bulk Competition Emails
                      </Typography>
                      <Typography variant="h6" style={{ fontFamily: 'Inter'}} color="text.secondary" sx={{
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        // WebkitLineClamp: 2, // Limits to 2 lines
                        // textOverflow: 'ellipsis',
                      }}>
                        Run competitions and send bulk emails to boost engagement, loyalty, and reach.
                      </Typography>
                    </CardContent>
                    <CardMedia
                      component="img"
                      height="140"
                      // width="140px"
                      style={{ padding: 12, width: '140px' }}
                      image={PngIcons.NewCompetitionBlock}
                      alt={'Competition'}
                    />
                  </CardActionArea>
                </Card>
            </div>
           }
        </div>

        <div className='middle pt_28 mt_42' style={{background: '#F5F7FC'}}>
            <div className='d-flex'>
                {selected==='Competition'&&<div style={{ padding: "20px" }}>
                    <h2>Paste custom content here:</h2>
                    <textarea
                        value={msg}
                        onChange={(e)=>setMsg(e.target.value)}
                        rows="15"
                        cols="80"
                        style={{
                            width: "100%",
                            height: "400px",
                            fontFamily: "monospace",
                            fontSize: "14px",
                            padding: "10px",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                        }}
                    />
                </div>}
                {selected==='Referral'&&<img src={
                    // selected==='Referral'?
                    'https://imagedelivery.net/xom6JLKEu4N3wOOpuxFt7Q/c948a54c-1317-4690-d527-d3283f943400/Digi'
                    // :'https://imagedelivery.net/xom6JLKEu4N3wOOpuxFt7Q/78b546d0-cff6-4ebc-0c39-67524b0b5200/Digi'
                } />}
                {selected==='Competition'&&<div
  style={{
    backgroundColor: '#E5E5E5',
    color: '#262626',
    fontFamily: '"Helvetica Neue", "Arial Nova", "Nimbus Sans", Arial, sans-serif',
    fontSize: '16px',
    fontWeight: 400,
    letterSpacing: '0.15008px',
    lineHeight: 1.5,
    margin: 0,
    // padding: '32px 0',
    minHeight: '100%',
    width: '100%',
  }}
>
  <table
    align="center"
    width="100%"
    style={{
      margin: '0 auto',
      maxWidth: '600px',
      backgroundColor: '#fafafa',
    }}
    role="presentation"
    cellSpacing="0"
    cellPadding="0"
    border="0"
  >
    <tbody>
      <tr style={{ width: '100%' }}>
        <td>
          <div
            style={{
              padding: '16px 24px 24px 24px',
              backgroundColor: '#fafafa',
              textAlign: 'center',
            }}
          >
            <img
              alt="Sample product"
              src={brand.brandLogo||"https://imagedelivery.net/xom6JLKEu4N3wOOpuxFt7Q/d2c1fd33-babc-4b43-d890-217e019e7600/logo400x400"}
              width="100"
              style={{
                width: '100px',
                // borderRadius: '50%',
                outline: 'none',
                border: 'none',
                textDecoration: 'none',
                verticalAlign: 'middle',
                display: 'inline-block',
                maxWidth: '100%',
              }}
            />
          </div>
          <div
            style={{
              padding: '0px 24px 0px 24px',
              backgroundColor: '#fafafa',
              textAlign: 'center',
            }}
          >
            <img
              alt="Sample product"
              src="https://imagedelivery.net/xom6JLKEu4N3wOOpuxFt7Q/b9a8fec1-10ec-4ee8-8c7f-aa232fd06900/walletly"
              width="200"
              height="200"
              style={{
                width: '200px',
                height: '200px',
                outline: 'none',
                border: 'none',
                textDecoration: 'none',
                verticalAlign: 'middle',
                display: 'inline-block',
                maxWidth: '100%',
              }}
            />
          </div>
          <div
            style={{
              color: '#5e5e5e',
              backgroundColor: '#fafafa',
              fontSize: '18px',
              fontFamily: '"Helvetica Neue", "Arial Nova", "Nimbus Sans", Arial, sans-serif',
              fontWeight: 'normal',
              textAlign: 'center',
              padding: '20px 24px 16px 24px',
            }}
          >
            Hi [Recipient's Name],
          </div>
          <div
            style={{
              color: '#5e5e5e',
              fontSize: '18px',
              fontWeight: 'normal',
              textAlign: 'center',
              padding: '0px 24px 8px 24px',
            }}
          >
            {msg || "Want a chance to win a one-month supply of supplements?"}
          </div>
          <div
            style={{
              color: '#5e5e5e',
              fontSize: '20px',
              fontWeight: 'normal',
              textAlign: 'center',
              padding: '0px 24px 28px 24px',
            }}
          >
            It's easy!
          </div>
          <div
            style={{
              fontWeight: 'normal',
              textAlign: 'center',
              padding: '16px 24px 16px 24px',
            }}
          >
            Here's how it works:
          </div>
          <div style={{ padding: '0px 0px 0px 0px' }}>
            <table
              align="center"
              width="100%"
              cellPadding="0"
              border="0"
              style={{
                tableLayout: 'fixed',
                borderCollapse: 'collapse',
              }}
            >
              <tbody style={{ width: '100%' }}>
                <tr style={{ width: '100%' }}>
                  <td
                    style={{
                      boxSizing: 'content-box',
                      verticalAlign: 'middle',
                      paddingLeft: '0',
                      paddingRight: '10.666666666666666px',
                    }}
                  >
                    <div style={{ padding: '0px 0px 0px 16px' }}>
                      <img
                        alt="Sample product"
                        src="https://imagedelivery.net/xom6JLKEu4N3wOOpuxFt7Q/2dfedd75-66c8-400c-6d2c-7e2d8617b500/walletly"
                        width="155"
                        height="76"
                        style={{
                          width: '155px',
                          height: '76px',
                          outline: 'none',
                          border: 'none',
                          textDecoration: 'none',
                          verticalAlign: 'middle',
                          display: 'inline-block',
                          maxWidth: '100%',
                        }}
                      />
                    </div>
                  </td>
                  <td
                    style={{
                      boxSizing: 'content-box',
                      verticalAlign: 'middle',
                      paddingLeft: '5.333333333333333px',
                      paddingRight: '5.333333333333333px',
                    }}
                  >
                    <div style={{ padding: '0px 0px 0px 8px' }}>
                      <img
                        alt="Sample product"
                        src="https://imagedelivery.net/xom6JLKEu4N3wOOpuxFt7Q/713dc89b-904f-47b1-0779-189052e73200/walletly"
                        width="170"
                        style={{
                          width: '170px',
                          outline: 'none',
                          border: 'none',
                          textDecoration: 'none',
                          verticalAlign: 'middle',
                          display: 'inline-block',
                          maxWidth: '100%',
                        }}
                      />
                    </div>
                  </td>
                  <td
                    style={{
                      boxSizing: 'content-box',
                      verticalAlign: 'middle',
                      paddingLeft: '10.666666666666666px',
                      paddingRight: '0',
                    }}
                  >
                    <div style={{ padding: '0px 0px 0px 4px' }}>
                      <img
                        alt="Sample product"
                        src="https://imagedelivery.net/xom6JLKEu4N3wOOpuxFt7Q/1f92336c-7112-4ac5-7a38-a93098980900/walletly"
                        width="100"
                        style={{
                          width: '100px',
                          outline: 'none',
                          border: 'none',
                          textDecoration: 'none',
                          verticalAlign: 'middle',
                          display: 'inline-block',
                          maxWidth: '100%',
                        }}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style={{ padding: '0px 24px 32px 24px' }}>
            <table
              align="center"
              width="100%"
              cellPadding="0"
              border="0"
              style={{
                tableLayout: 'fixed',
                borderCollapse: 'collapse',
              }}
            >
              <tbody style={{ width: '100%' }}>
                <tr style={{ width: '100%' }}>
                  <td
                    style={{
                      boxSizing: 'content-box',
                      verticalAlign: 'top',
                      paddingLeft: '0',
                      paddingRight: '10.666666666666666px',
                    }}
                  >
                    <div
                      style={{
                        color: '#5e5e5e',
                        fontWeight: 'normal',
                        padding: '0px 0px 0px 0px',
                      }}
                    >
                      Share this with your friends.
                    </div>
                  </td>
                  <td
                    style={{
                      boxSizing: 'content-box',
                      verticalAlign: 'top',
                      paddingLeft: '5.333333333333333px',
                      paddingRight: '5.333333333333333px',
                    }}
                  >
                    <div
                      style={{
                        color: '#5e5e5e',
                        fontWeight: 'normal',
                        padding: '0px 0px 0px 0px',
                      }}
                    >
                      For every share, you earn an entry into the competition.
                    </div>
                  </td>
                  <td
                    style={{
                      boxSizing: 'content-box',
                      verticalAlign: 'top',
                      paddingLeft: '10.666666666666666px',
                      paddingRight: '0',
                    }}
                  >
                    <div
                      style={{
                        color: '#5e5e5e',
                        fontWeight: 'normal',
                        padding: '0px 0px 0px 0px',
                      }}
                    >
                      The more you share, the higher your chances of winning!
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            style={{
              backgroundColor: '#fafafa',
              textAlign: 'center',
              padding: '0px 0px 24px 0px',
            }}
          >
            <a
              href="https://www.usewaypoint.com"
              style={{
                color: '#FFFFFF',
                fontSize: '22px',
                fontWeight: 'bold',
                backgroundColor: '#2196F3',
                borderRadius: '4px',
                display: 'block',
                padding: '16px 32px',
                textDecoration: 'none',
              }}
              target="_blank"
            >
              <span>
                <i
                  style={{
                    letterSpacing: '32px',
                    msoFontWidth: '-100%',
                    msoTextRaise: '48',
                  }}
                  hidden
                >
                  &nbsp;
                </i>
              </span>
              <span>Start Sharing Now</span>
              <span>
                <i
                  style={{
                    letterSpacing: '32px',
                    msoFontWidth: '-100%',
                  }}
                  hidden
                >
                  &nbsp;
                </i>
              </span>
            </a>
          </div>
          <div
            style={{
              color: '#5e5e5e',
              fontWeight: 'normal',
              padding: '16px 24px 16px 24px',
            }}
          >
            Don't miss out—start sharing today and get closer to winning your supplements for free!
          </div>
          <div
            style={{
              color: '#5e5e5e',
              fontWeight: 'normal',
              padding: '0px 24px 24px 24px',
            }}
          >
            Good luck!
          </div>
          <div
            style={{
              color: '#5e5e5e',
              fontWeight: 'bold',
              padding: '0px 24px 8px 24px',
            }}
          >
            Best regards,
          </div>
          <div
            style={{
              color: '#5e5e5e',
              fontWeight: 'normal',
              padding: '0px 24px 24px 24px',
            }}
          >
            [Your Name/Company Name]
          </div>
          <div
            style={{
              padding: '16px 24px 16px 24px',
              backgroundColor: '#fafafa',
              textAlign: 'center',
            }}
          >
            <img
              alt="Sample product"
              src="https://imagedelivery.net/xom6JLKEu4N3wOOpuxFt7Q/d2c1fd33-babc-4b43-d890-217e019e7600/logo400x400"
              width="150"
              style={{
                width: '150px',
                outline: 'none',
                border: 'none',
                textDecoration: 'none',
                verticalAlign: 'middle',
                display: 'inline-block',
                maxWidth: '100%',
              }}
            />
          </div>
          <div
            style={{
              backgroundColor: '#fafafa',
              fontSize: '22px',
              fontFamily: '"Helvetica Neue", "Arial Nova", "Nimbus Sans", Arial, sans-serif',
              fontWeight: 'bold',
              textAlign: 'center',
              padding: '20px 24px 8px 24px',
            }}
          >
            Point check for Adeel:
          </div>
          <div
            style={{
              backgroundColor: '#fafafa',
              fontSize: '18px',
              fontFamily: '"Helvetica Neue", "Arial Nova", "Nimbus Sans", Arial, sans-serif',
              fontWeight: 'normal',
              textAlign: 'center',
              padding: '0px 24px 16px 24px',
            }}
          >
            Member | 187 Points*
          </div>
          <div
            style={{
              color: '#A3A3A3',
              backgroundColor: '#fafafa',
              fontSize: '10px',
              fontFamily: '"Helvetica Neue", "Arial Nova", "Nimbus Sans", Arial, sans-serif',
              fontWeight: 'normal',
              padding: '16px 24px 16px 24px',
            }}
          >
            The content of this email is confidential and intended for the recipient specified in message only. It is strictly forbidden to share any part of this message with any third party, without a written consent of the sender. If you received this message by mistake, please reply to this message and follow with its deletion, so that we can ensure such a mistake does not occur in the future.
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
}
            </div>
        </div>
        {/* <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label={<div className='tab-text'>Referral</div>} {...a11yProps(0)} />
                <Tab label={<div className='tab-text'>Custom</div>} {...a11yProps(1)} />
            </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <div className="middle">
                    <img style={{borderRadius: '12.171px', border: '1.521px solid #2960EC', background: '#FFF', boxShadow: '0px 21.299px 36.513px 0px rgba(0, 0, 0, 0.05)'}} src='https://imagedelivery.net/xom6JLKEu4N3wOOpuxFt7Q/8f0a66f3-ff70-4d39-4f41-7ca934a64400/Digi' />
                </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <ReferralPage />
                <TextEditor />
            </CustomTabPanel>
        </Box> */}


    <CustomsModal
        open={model}
        minWidth={"600px"}
        component={<>
          <div className="H22M">Confirm</div>
          <div className="B14R mt_10 mb_20">{'Are you sure you want to send bulk sms to '+ca?.length+' audience?'}</div>
          <div className="d-flex justify-content-end gap-10">
            <CustomButton
              varient="primary"
              btntext={"YES"}
              icon={deleteLoader &&
                <CircularProgress
                    size={20}
                    color={'inherit'} />}
              onClick={async() => {
                setDeleteLoader(true)
                // 
                if(brand?.integrations?.cloudwaitress?.length>0){
                    const response = await axios.post('https://api.walletly.ai/api/v4/pass/sendBulkReferral', {
                        brandId: brand._id,
                        data: selected,
                        msg: msg
                      });
                  
                      // Handle the response
                      console.log('Response:', response.data);
                }
                else{
                    const api = await PassService.sendBulkReferralInPerson({toaster: true,payload: {
                        brandId: brand._id,
                        data: selected,
                        msg: msg
    
                    }})
                    console.log('api',api)
                }
                // 
              setDeleteLoader(false)
              setModel(false)
              }}
            />
            <CustomButton
              varient="secondary"
              btntext={"NO"}
              onClick={async() => {
                setModel(false)
              }}
            />
          </div>

        </>
        }
        // onClose={() => setrefmodel(false)}
      />
    </div>
  )
}

export default ReferralPage



function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }