import localforage from 'localforage';
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { utils } from '../../../utils';
import { ColorSchemeCode } from '../../../enums/ColorScheme';
import { UserService } from '../../../services';
import Loader from '../../../components/Loader';
import { LayoutContext } from '../../../context/Layout.context';

function UpdateAudience() {
    const history = useHistory();

    const [found, setfound] = useState()
    const [subscriber, setSubscriber] = useState([])
    const [loader, setLoader] = useState(true)
    const layout = useContext(LayoutContext)

    useEffect(() => {
        layout.setLayout({
            borderBottom: true,
            edit        : false,
            title       : history.location.state.pass.userData.firstName,
            backTitles  : ['Audience'],
            switch : '',
            button: <></>
        })
    }, [history.location.state])
    

    useEffect(async() => {
        setLoader(true)
        const customers = await localforage.getItem('customersAudience');
        let found = customers.find((currentCustomer) => {
          return currentCustomer?.pass?._id == history?.location?.state?.pass?.referredBy
        })
        setfound(found)

        const a = history.location.state.pass.brandId+'&'+history.location.state.pass.userId
        console.log(a)
        const {error, response} = await UserService.GetSubscriber({payload: {_id: a}})
        console.log('error',error);
        console.log('response',response);
        setSubscriber(response.data.tags)

        setLoader(false)
        
    }, [])
    
  return (
    <div>
        {console.log(history.location.state)}
        {loader?<Loader />:
        <div className='row m_0'>
            <div className='col-3 p_0 position-relative'>
                <img className='w-100' height={'123px'} src='https://imagedelivery.net/xom6JLKEu4N3wOOpuxFt7Q/014bf620-d970-41ab-6e5e-5a19e8de1a00/Digi' />
                <div className='position-absolute' style={{top: '70px', left: '15px'}}>
                    {utils.getAvatar({
                        firstName: history.location.state.pass.userData.firstName,
                        lastName: history.location.state.pass.userData.lastName,
                        id: history.location.state.pass._id,
                        tableName: "customers",
                        customers: history.location.state,
                        width: "94px",
                        heigth: "94px",
                        className: 'ua-whitehead',
                        border: true,
                        bgColor: '#2960EC',
                        })
                    }
                </div>
                <div className='mt_60 ua-head ml_20'>{history.location.state.pass.userData.firstName + ' '+ history.location.state.pass.userData.lastName}</div>
                <div className='ua-desc ml_20 mt_4 mb_4'>{history.location.state.pass.userData.email}</div>
                <div className='ua-desc ml_20'>{new Date(history.location.state.pass.birthday).toDateString()}</div>
                <div className='bar mt_26 mb_26'></div>
                <div className='ua-head ml_20 mb_4'>Phone Number</div>
                <div className='ua-desc ml_20'>{history.location.state.pass.userData.phone}</div>
                <div className='bar mt_26 mb_26'></div>
                <div className='ua-head ml_20 mb_4'>Subscription Date</div>
                <div className='ua-desc ml_20'>{new Date(history.location.state.pass.created).toDateString()}</div>
            </div>
            <div className='col-9' style={{background: '#F5F7FC', height: 'calc(100vh - 60px)'}}>
                <div className='p_26 mt_30 ml_11 mr_11' style={{background: 'white',borderRadius: '16px'}}>
                    <div className='row'>
                        <div className='ua-head1 col-4'>Referral</div>
                        <div className='col-8 ua-desc d-flex gap-12'>
                            <span class="icon link-icon cp" 
                            onClick={()=>{
                                const url = history.location.state.user.referralCode; // Replace with your desired URL
                                window.open(url, "_blank");
                            }}
                            >🔗</span>
                            <div className='cp' onClick={()=>{
                                navigator.clipboard.writeText(history.location.state.user.referralCode).then(() => {
                                //   alert("Text copied: " + history.location.state.user.referralCode);
                                }).catch(err => {
                                  console.error("Failed to copy text: ", err);
                                });
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.54218 2.14202C7.61271 2.07043 7.69678 2.01358 7.78948 1.97478C7.88219 1.93599 7.98168 1.91601 8.08218 1.91602H11.7842C12.5738 1.91608 13.3334 2.21852 13.9069 2.76121C14.4805 3.3039 14.8245 4.04562 14.8682 4.83402C15.6566 4.87773 16.3983 5.22169 16.941 5.79525C17.4837 6.36881 17.7861 7.12841 17.7862 7.91802V15.494C17.7862 16.3131 17.4609 17.0986 16.8818 17.6779C16.3027 18.2572 15.5173 18.5828 14.6982 18.583H10.0362C9.24641 18.5829 8.48667 18.2804 7.91308 17.7375C7.3395 17.1946 6.99564 16.4526 6.95218 15.664C6.16378 15.6203 5.42206 15.2763 4.87937 14.7028C4.33669 14.1292 4.03425 13.3696 4.03418 12.58V6.01102C4.03418 5.81202 4.11218 5.62102 4.25218 5.47902L7.54218 2.14202ZM5.55018 7.51002V12.58C5.55018 13.45 6.25418 14.154 7.12318 14.154H11.7852C12.6552 14.154 13.3592 13.449 13.3592 12.58V5.00502C13.3592 4.13502 12.6542 3.43102 11.7852 3.43102H9.62818V6.75302C9.62818 7.17102 9.28818 7.51002 8.87018 7.51002H5.55018ZM8.11418 3.72102L5.87018 5.99502H8.11218V3.72102H8.11418ZM14.8742 6.35502V12.58C14.8742 13.3993 14.5487 14.185 13.9694 14.7643C13.3901 15.3436 12.6044 15.669 11.7852 15.669H8.47218C8.51545 16.0535 8.69884 16.4087 8.98734 16.6665C9.27584 16.9244 9.64923 17.067 10.0362 17.067H14.6982C15.5672 17.067 16.2712 16.363 16.2712 15.494V7.91802C16.2712 7.10802 15.6602 6.44102 14.8742 6.35502Z" fill="#2E3039"/>
                                </svg>
                            </div>
                            {/* {history.location.state.user.referralCode} */}
                            </div>
                    </div>
                    <div className='bar mt_29 mb_29'></div>
                    <div className='row'>
                        <div className='ua-head1 col-4'>Referred By</div>
                        <div className='col-8 ua-desc'>{found ? (found?.pass?.userData?.firstName + " " + found?.pass?.userData?.lastName) : "-"}</div>
                    </div>
                    <div className='bar mt_29 mb_20'></div>
                    <div className='row'>
                        <div className='ua-head1 col-4 align-content-center'>Tags</div>
                        <div className='col-8 ua-desc d-flex gap-8'>
                            {subscriber.length>0&&subscriber.map((value, index)=>(<div className='ua-bg'>{value}</div>))}
                        </div>
                    </div>
                    <div className='bar mt_20 mb_29'></div>
                    <div className='row'>
                        <div className='ua-head1 col-4'>Point Summary</div>
                        <div className='col-8 ua-desc'>{history.location.state.pass.loyaltyCard.currentPoints}</div>
                    </div>
                    <div className='bar mt_29 mb_29'></div>
                    <div className='row'>
                        <div className='ua-head1 col-4'>Total Spending</div>
                        <div className='col-8 ua-desc'>{history.location.state.pass.loyaltyCard.totalSpending}</div>
                    </div>
                    <div className='bar mt_29 mb_29'></div>
                    <div className='row'>
                        <div className='ua-head1 col-4'>Total Visits</div>
                        <div className='col-8 ua-desc'>{history.location.state.pass.loyaltyCard.redeemCount}</div>
                    </div>
                    <div className='bar mt_29 mb_29'></div>
                    <div className='row'>
                        {/* <div className='ua-head1 col-4'>Current Tier</div> */}
                        <div className='ua-head1 col-4'>Referral Count</div>
                        <div className='col-8 ua-desc'>{history.location.state.pass?.refer || '-'}</div>
                        {/* <div className='col-8 ua-desc'>{history.location.state.pass.loyaltyCard.currentTier &&
                history.location.state.pass.loyaltyCard.currentTier.currentTierName
                ? history.location.state.pass.loyaltyCard.currentTier.currentTierName
                : "-"}</div> */}
                    </div>
                    <div className='bar mt_29 mb_29'></div>
                    {/* <div className='row'>
                        <div className='ua-head1 col-4'>Pass Status</div>
                        <div className='col-8 ua-desc'>{history.location.state.pass.isWallet ? 'Subscribed' : 'Un-Subscribed'}</div>
                    </div> */}

                </div>
            </div>
        </div>}
    </div>
  )
}

export default UpdateAudience