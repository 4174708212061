import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CustomButton from "../../../../components/CustomButton";
import { LayoutContext } from "../../../../context/Layout.context";
import { PngIcons, SvgIcons } from "../../../../icons";
import localforage from "localforage";
import { CampaignService, CommunicationService, DealService, EarningWayService, FlowService } from "../../../../services";
import { BrandContext } from "../../../../context/Brand.context";
import { ClickOutside, Options, utils } from "../../../../utils";
import { ColorSchemeCode } from "../../../../enums/ColorScheme";
import { rulebuilder_points } from "../../../../enums/enums";

import ViewDetail from "../../../../components/ViewDetail";
import $ from 'jquery';
import InputContentEditable from "../../../../components/InputContentEditable";
import { AgencyContext } from "../../../../context/Agency.context";
import { Card, CardActionArea, CardContent, CardMedia, CircularProgress, FormControl, FormHelperText, IconButton, RadioGroup, Typography } from "@mui/material";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import localForage from 'localforage'
import DeleteModal from "../../../../components/DeleteModal";
import CustomsModal from "../../../../components/CustomModal";
import CustomTextField from '../../../../components/CustomTextField';
import CustomModal from '../../../../components/CustomModal';
import CustomDatePicker from '../../../../components/CustomDatePicker';
import { Campaign } from "../../../../enums";
import Loader from "../../../../components/Loader";
import DatePicker from "react-datepicker";
import CustomSelect from "../../../../components/CustomSelect";
import CustomRadio from "../../../../components/CustomRadio";


function RewardBuilder() {

    const history = useHistory();
    const layout = useContext(LayoutContext);
    const brand = useContext(BrandContext);
    const agency = useContext(AgencyContext);
    const [spare, setSpare] = useState(false)

    const [name, setName] = useState("Automation Name");
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);

    const [btnloader, setBtnloader] = useState(false);

    const [sms, setSms] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState([]);

    const [addtrigger, setAddtrigger] = useState(false)
    const [idx, setIdx] = useState('')
    const [viewMore, setViewMore] = useState(false)

    const [addtrigger1, setAddtrigger1] = useState(false)
    const [viewMore1, setViewMore1] = useState(false)

    const [show, setShow] = useState({
        modal: false,
        value: '',
        title: '',
        key: '',
        dealModal: false,
        onChange: () => console.log("")
    })
    useEffect(() => {
        setSpare(!spare)
    }, [])
    // console.log('hisnoo',history.location.state.flow[history.location.state.index].block);

    console.log('hisnoo', history.location.state.solo);

    // console.log('FINALL',finalstate);
    const [dropIdx, setDropIdx] = useState('')

    const [dropdownTrigger, setDropdownTrigger] = useState([

        {
            name: 'Customer',
            key: 'Customer',
            img: PngIcons.RewardBuilder.checkin,
            description: 'It will trigger for all customer not depending on any condition.'
        }, {
            name: 'All Customer',
            key: 'allCustomer',
            img: PngIcons.RewardBuilder.checkin,
            description: 'It will trigger for all customer not depending on any condition.'
        },
        {
            name: 'One Time Deal',
            key: 'oneTimeDeal',
            img: PngIcons.RewardBuilder.oneTimeDeal,
            description: 'It will trigger deal will be rewarded to customer only one time.'
        },
        {
            name: 'Customer Tag',
            key: 'customerTag',
            img: PngIcons.RewardBuilder.tagCustomer,
            description: 'It triggers when a customer has the tag you tagged them. e.g., Loyal Customer'
        },
        {
            name: 'Customer Type',
            key: 'customerType',
            img: PngIcons.RewardBuilder.customerType2,
            description: 'It triggers when a customer has the tag you tagged them. e.g., Favorite Customer'
        },
        {
            name: 'Customer Inactivity',
            key: 'customerInactivity',
            img: PngIcons.RewardBuilder.customerInactivity,
            description: 'It triggers when a customer has no activity for a certain period.'
        },
        {
            name: 'Points',
            key: 'point',
            img: PngIcons.RewardBuilder.points,
            description: 'It triggers when a specific amount of points are achieved.'
        },
        {
            name: 'Visits',
            key: 'visit',
            img: PngIcons.RewardBuilder.visits,
            description: 'It triggers when a customer has a certain amount of visits to your brand.'
        },
        {
            name: 'Tiers',
            key: 'tiers',
            img: PngIcons.RewardBuilder.oneTimeDeal,
            description: 'It will trigger deal will be rewarded to customer only one time.'
        },
        {
            name: 'Activity',
            key: 'Activity',
            img: PngIcons.RewardBuilder.actions,
            description: 'It triggers when there is a change in the status of a Deal, i.e. (Assigned, Redeemed, Expired)'
        },
        {
            name: 'Reward Status',
            key: 'dealStatus',
            img: PngIcons.RewardBuilder.dealStatus,
            description: 'It triggers when there is a change in the status of a Deal, i.e. (Assigned, Redeemed, Expired)'
        },
        {
            name: 'Event',
            key: 'event',
            img: PngIcons.RewardBuilder.event,
            description: 'It triggers when a specific event occurs.'
        },
        // {
        //     name: 'Birthday',
        //     key: 'birthday',
        //     img: PngIcons.RewardBuilder.birthDay,
        //     description: 'It is for birthday.'
        // },


        {
            name: 'Ref',
            key: 'ref',
            img: PngIcons.RewardBuilder.oneTimeDeal,
            description: 'It triggers when a customer Reffered numbers of customer'
        },
        {
            name: 'Referral reward for sender',
            key: 'referral',
            img: PngIcons.RewardBuilder.oneTimeDeal,
            description: 'It triggers when a customer Reffered numbers of customer'
        },
        {
            name: 'Referral reward for receiver',
            key: 'referrallogin',
            img: PngIcons.RewardBuilder.checkin,
            description: 'It triggers when a customer create pass using referral'
        },
        {
            name: 'External Systems',
            key: 'sharedLoyality',
            img: PngIcons.RewardBuilder.sharedLoyality,
            description: 'For shared loyality'
        },
        {
            name: 'Shared Loyality',
            key: 'sharedLoyality',
            img: PngIcons.RewardBuilder.sharedLoyality,
            description: 'For shared loyality'
        },
    ])

    const [dropdownAction, setDropdownAction] = useState([
        {
            name: 'Add Tag',
            key: 'addTag',
            img: PngIcons.RewardBuilder.addTag,
            description: 'Description'
        },
        {
            name: 'Give Points',
            key: 'givePoint',
            img: PngIcons.RewardBuilder.addPoints,
            description: 'Description'
        },
        {
            name: 'Deduct Points',
            key: 'deductPoint',
            img: PngIcons.RewardBuilder.deductPoints,
            description: 'Description'
        },
        {
            name: 'Customer Type',
            key: 'customerType',
            img: PngIcons.RewardBuilder.customerType,
            description: 'It triggers when a customer has the tag you tagged them. e.g., Loyal Customer'
        },
        {
            name: 'Reward',
            key: 'rewardDeal',
            img: PngIcons.RewardBuilder.rewardDeal,
            description: 'Description'
        },
        {
            name: 'Bonus Points',
            key: 'bonusPoints',
            img: PngIcons.RewardBuilder.doublepoints,
            description: 'Description',
            bonuspoints: rulebuilder_points.DOUBLE_THE_POINTS_X_2
        },
        {
            name: 'Remove Tag',
            key: 'removeTag',
            img: PngIcons.RewardBuilder.removeTag,
            description: 'Description'
        },
        {
            name: 'Send SMS',
            key: 'sendSms',
            img: PngIcons.RewardBuilder.sendSMS,
            description: 'Description'
        },
    ])

    const [finalstate, setFinalstate] = useState(history.location.state?.flow || [{ state: [], state1: [] }])

    console.log('history.location.state1',history.location.state);

    useEffect(async () => {
        // const smsApi = await CommunicationService.GetQueriedCommunication({ query: { delete: false, brandId: brand._id, smsType: 'draft', communicationType: 'sms' } });
        // if (smsApi.response) setSms(smsApi.response.data)

        let deal = await localforage.getItem("deal");
        if ((!deal) || deal.length === 0) {
            const query = { delete: false, brandId: brand._id };
            const { error, response } = await DealService.Get({ query });
            if (response) deal = response.data;
            localForage.setItem('deal', response.data);
        }

        let temp = [];
        if (deal && deal.length > 0)
            for (const value of deal) {
                temp.push({
                    label: value.name,
                    value: value._id,
                    image: <img src={value.image} width="20px" heigth="20px" />,
                    data: value,
                });
            }
        setFilteredOptions(temp);

    }, []);

    // useEffect(() => {
    //     console.log('history?.location?.state', history?.location?.state);
    //     layout.setLayout({
    //         edit: true,
    //         // placeholder : history.location.state?.flow[history.location.state.index]?.block ? history.location.state.flow[history.location.state.index].block : "Automation",
    //         placeholder: layout.elements?.editTitle || (history?.location?.state?.flow && history?.location?.state?.flow[history?.location?.state?.index]?.block) || "New Rule",
    //         borderBottom: true,
    //         switch: "",
    //         // backTitles: ["Loyalty Suite", "Reward Builder"],
    //         button: <>
    //             {!history.location.state?.delete && <CustomButton
    //                 varient="warningSecondary"
    //                 btntext={"Delete"}
    //                 className="mr-4"
    //                 onClick={() => { setDeleteModal(true) }}
    //             />}
    //             <CustomButton
    //                 icon={btnloader &&
    //                     <CircularProgress
    //                         size={20}
    //                         color={'inherit'} />}
    //                 varient={"primary"}
    //                 btntext={"Save Changes"}
    //                 onClick={async () => {
    //                     setBtnloader(true)
    //                     finalstate[history.location.state.index].block = layout.elements.editTitle || name || 'Data Flow'
    //                     const payload = {
    //                         name: 'Data Flow',
    //                         brandId: brand._id,
    //                         agencyId: agency._id,
    //                         flowType: 'new',
    //                         flow: finalstate
    //                     }
    //                     // console.log('payload',payload)
    //                     if (!history.location.state?.new) {
    //                         // console.log('aba');
    //                         payload._id = history.location.state?._id
    //                         console.log('payload', payload);
    //                         const { response, error } = await FlowService.Update({ payload })
    //                         console.log('error ', error)
    //                         console.log('response ', response)
    //                         let temp = [response.data]

    //                         // console.log('response',response, error);
    //                         let flow = await localForage.getItem('flow');
    //                         if (flow && flow.length > 0) {
    //                             temp = []
    //                             for (const value of flow) {
    //                                 if (value._id === history.location.state?._id) temp.push(response.data)
    //                                 else temp.push(value)
    //                             }
    //                         }
    //                         localForage.setItem('flow', temp);
    //                     }
    //                     else {
    //                         // console.log('jaba');
    //                         // console.log('payload',payload);
    //                         const { response, error } = await FlowService.Create({ payload })
    //                         console.log('keep', response, error);
    //                         let temp = [response.data]
    //                         let flow = await localForage.getItem('flow');
    //                         if (flow) {
    //                             flow.push({ ...response.data, created: new Date().valueOf() })
    //                         }
    //                         else {
    //                             flow = []
    //                             flow.push({ ...response.data, created: new Date().valueOf() })
    //                         }
    //                         // if(flow && flow.length > 0){
    //                         //     temp = []
    //                         //     for (const value of flow) {
    //                         //         if(value._id === history.location.state?._id) temp.push(response.data)
    //                         //         else temp.push(value)
    //                         //     }
    //                         // }
    //                         localForage.setItem('flow', flow);
    //                     }
    //                     setBtnloader(false)

    //                     const location = window.location.pathname.split('/')
    //                     location.pop()
    //                     history.push(location.join('/'))

    //                 }} />

    //         </>
    //         ,
    //     });
    // }, [layout.elements.editTitle, spare, finalstate, btnloader, deleteModal, deleteLoader, window.location.pathname]);


    useEffect(() => {
        layout.setLayout({
          borderBottom: false,
          edit: false,
          switch: "",
          title: history.location.state.solo.state[0].key === "referrallogin"? //receiver
          "Referral Receiver":
          history.location.state.solo.state[0].key === "referral"? //sender
          "Referral Sender":
          history.location.state.solo.state[0].key === "customerInactivity"? 
          "Inactivity":
          history.location.state.solo.state[0].key === "point"? 
          "Reward Loyalty":
          (history.location.state.solo.state[0].key === "event" && history.location.state.solo.state[0].festival === "Birth Day" ) ? 
          "Birthday":
          (history.location.state.solo.state[0].key === "event" && (history.location.state.solo.state[0].festival === "Custom Date"||history.location.state.solo.state[0].festival === "Week") && ((history.location.state.solo.state1[0]?.key === "rewardDeal" || history.location.state.solo.state1[0]?.key === "addTag" || history.location.state.solo.state1[0]?.key === "bonusPoints" )) ) ? 
          "Promotion":
          (
            history.location.state.solo.state[0].key === "visit"||history.location.state.solo.state[0].key === "dealStatus" 
          ) ? 
          "Reward Coupon":"Automation",
          button: <></>
        })
      }, [])

    // useEffect(() => {
    //     try {
    //         layout.setLayout({
    //             switch: "",
    //             editTitle: !history?.location?.state?.flow[history?.location?.state?.index]?.block ? "New Rule" : history?.location?.state?.flow[history?.location?.state?.index]?.block || 'Data Flow'
    //             // editTitle: !history?.location?.state?.name?"New Rule": history.location.state.name || "Reward " + (filteredOptions.length + 1),
    //         })
    //     } catch (error) {
    //         layout.setLayout({
    //             editTitle: 'Data Flow',
    //             switch: "",
    //         })
    //     }
    // }, [])



    const handleDelete = async () => {
        setDeleteLoader(true)
        if (!history.location.state?.new) {
            finalstate[history.location.state.index].block = layout.elements.editTitle || name || 'Data Flow'
            finalstate.splice(history.location.state.index, 1)
            const payload = {
                name: 'Data Flow',
                brandId: brand._id,
                agencyId: agency._id,
                flowType: 'new',
                flow: finalstate
            }
            // console.log('payload',payload)
            // console.log('aba');
            payload._id = history.location.state?._id
            console.log('payload', payload);
            const { response, error } = await FlowService.Update({ payload })
            console.log('error ', error)
            console.log('response ', response)
            let temp = [response.data]
            // console.log('response',response, error);
            let flow = await localForage.getItem('flow');
            if (flow && flow.length > 0) {
                temp = []
                for (const value of flow) {
                    if (value._id === history.location.state?._id) temp.push(response.data)
                    else temp.push(value)
                }
            }
            localForage.setItem('flow', temp);
        }
        else {
            let temp = []
            await FlowService.Update({ payload: { _id: history.location.state?._id, brandId: brand._id, agencyId: agency._id, delete: true } })
            let flow = await localForage.getItem('flow');
            if (flow && flow.length > 0) {
                flow.map((value, index) => {
                    if (history.location.state?._id !== value._id) temp.push(value)
                })
            }
            localForage.setItem('flow', temp);
        }

        setViewMore(false)
        setDeleteLoader(false)
        setDeleteModal(false)

        const location = window.location.pathname.split('/')
        location.pop()
        history.push(location.join('/'))
    }
    const deleteFunc = (index, i, state) => {
        if (state) {
            finalstate[history.location.state.index].state.splice(index, 1)
            setViewMore(false)
            setIdx('')
            setFinalstate([...finalstate])
        }
        else {
            finalstate[history.location.state.index].state1.splice(index, 1)
            setViewMore1(false)
            setIdx('')
            setFinalstate([...finalstate])
        }
    }

    const func = (value, key) => {
        // console.log('====================================');
        // console.log('abc',value, 'key', key);
        // console.log('====================================');
        [key] = value
        setFinalstate([...finalstate])
    }

    const [innerLoader, setInnerLoader] = useState(true)
    const [earning, setEarning] = useState()
    
    const [type, setType] = useState(
        history?.location?.state?.solo?.state[0]?.key==='dealStatus'?'Deal Redeemed':
        history?.location?.state?.solo?.state[0]?.key==='oneTimeDeal'?'One Time':
        history?.location?.state?.solo?.state[0]?.key==='allCustomer'?'One Time':
        'Visit')
    const [pts1, setPts1] = useState(history?.location?.state?.solo?.state1[history?.location?.state?.solo?.state1.findIndex(item => item?.key === "givePoint")]?.key==='givePoint'?'Loyalty':history?.location?.state?.solo?.state1[history?.location?.state?.solo?.state1.findIndex(item => item?.key === "rewardDeal")]?.key==='rewardDeal'?'Coupon':'Coupon')
    const [pts, setPts] = useState(
        (history?.location?.state?.solo?.state[0]?.key==="point"||(history?.location?.state?.solo?.state1[history?.location?.state?.solo?.state1.findIndex(item => item?.key === "givePoint")]?.key==='givePoint'&&history?.location?.state?.solo?.state[0]?.key!=='dealStatus')||history?.location?.state?.solo?.state1[0]?.key==='bonusPoints')?'Loyalty':
        (
            history?.location?.state?.solo?.state[0]?.key==='dealStatus'||
            history?.location?.state?.solo?.state[0]?.key==='oneTimeDeal'||
            history?.location?.state?.solo?.state[0]?.key==='allCustomer'||
            history?.location?.state?.solo?.state[0]?.key==='visit'||history?.location?.state?.solo?.state1[history?.location?.state?.solo?.state1.findIndex(item => item?.key === "rewardDeal")]?.key==='rewardDeal')
        ?'Coupon':'Points')

    useEffect(async () => {
        const query = {
            brandId: brand._id,
            campaignType: 5
        }
        const campaign = await CampaignService.GetQueriedCampaign({ query })
        // console.log('====================================');
        // console.log('campaign',campaign);
        // console.log('====================================');
        let selectedLoyalityEarningWays;
        // selectedLoyalityEarningWays = await localForage.getItem('selectedLoyalityEarningWays');
        selectedLoyalityEarningWays = await localForage.getItem('earningways');
        if (!selectedLoyalityEarningWays || selectedLoyalityEarningWays[0].campaignId !== campaign.response.data[0]._id) {
            const query = { campaignId: campaign.response.data[0]._id }
            if (campaign.response.data[0]._id) {
                const { error, response } = await EarningWayService.GetQueriedEarningWays({ query })
                // console.log('respones', response);
                let temp = []
                if (response) temp.push(response.data.filter((e) => e.activity === Campaign.Activities.EARNING.CHECKIN)[0])
                if (response) temp.push(response.data.filter((e) => e.activity === Campaign.Activities.EARNING.SIGNUP)[0])
                if (response) temp.push(response.data.filter((e) => e.activity === Campaign.Activities.EARNING.BIRTHDAY)[0])
                if (response) temp.push(response.data.filter((e) => e.activity === Campaign.Activities.EARNING.SPENDING)[0])
                // if(response) localForage.setItem('selectedLoyalityEarningWays', temp)
                if (response) localForage.setItem('earningways', temp)
                if (response) selectedLoyalityEarningWays = response.data
                if (response) setEarning(response.data.filter((e) => e.activity === Campaign.Activities.EARNING.SPENDING)[0])

            }
        }
        // console.log('selectedLoyalityEarningWays',selectedLoyalityEarningWays);
        let temp = []
        if (selectedLoyalityEarningWays) {
            temp.push(selectedLoyalityEarningWays.filter((e) => e.activity === Campaign.Activities.EARNING.CHECKIN)[0])
            temp.push(selectedLoyalityEarningWays.filter((e) => e.activity === Campaign.Activities.EARNING.SIGNUP)[0])
            temp.push(selectedLoyalityEarningWays.filter((e) => e.activity === Campaign.Activities.EARNING.BIRTHDAY)[0])
            temp.push(selectedLoyalityEarningWays.filter((e) => e.activity === Campaign.Activities.EARNING.SPENDING)[0])
            setEarning(selectedLoyalityEarningWays.filter((e) => e.activity === Campaign.Activities.EARNING.SPENDING)[0])
            // console.log('temp-',temp);
        }
        setInnerLoader(false)

        // document.getElementsByClassName('for-w')[0].style.width = (document.getElementById('f-select').selectedOptions[0].label.length  * 10 ) + 'px'
    }, [])

   
    function initialsDates(index) {
        if (finalstate[history.location.state.index].state[index].eventStart) {
            return {
                startDate:
                    `${(new Date(finalstate[history.location.state.index].state[index].eventStart).getMonth() + 1).toString().padStart(2, '0')}/${new Date(finalstate[history.location.state.index].state[index].eventStart).getDate().toString().padStart(2, '0')}/${new Date(finalstate[history.location.state.index].state[index].eventStart).getFullYear()}`,
                endDate:
                    `${(new Date(finalstate[history.location.state.index].state[index].eventEnd).getMonth() + 1).toString().padStart(2, '0')}/${new Date(finalstate[history.location.state.index].state[index].eventEnd).getDate().toString().padStart(2, '0')}/${new Date(finalstate[history.location.state.index].state[index].eventEnd).getFullYear()}`
            }
        }
        else {
            return {
                startDate:
                    `${(new Date().getMonth() + 1).toString().padStart(2, '0')}/${new Date().getDate().toString().padStart(2, '0')}/${new Date().getFullYear()}`,
                endDate:
                    `${(new Date().getMonth() + 1).toString().padStart(2, '0')}/${new Date().getDate().toString().padStart(2, '0')}/${new Date().getFullYear()}`
            }
        }

    }

    const [filterAudience, setFilterAudience] = useState(history?.location?.state?.solo?.state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "rewardDeal")]?.key==="rewardDeal"?[history?.location?.state?.solo?.state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "rewardDeal")]?.rewardDeal]:[]);
    const [filterAudience1, setFilterAudience1] = useState(history?.location?.state?.solo?.state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "rewardDeal")]?.key==="rewardDeal"?[history?.location?.state?.solo?.state[0]?.deal]:[]);
    const [coupon, setCoupon] = useState(history?.location?.state?.solo?.state[0]?.festival==='Custom Date'?'customDate':'visit');

    return (
        <>

            {innerLoader ? <Loader />
                :
                <div id="RewardBuilder" className="middle">
                    {/* {console.log('history.location.state',history.location.state)} */}
                    <CustomsModal
                        open={deleteModal}
                        minWidth={'600px'}
                        component={<DeleteModal icon={deleteLoader} title="Deal" onCancel={() => setDeleteModal(false)} onDelete={handleDelete} />}
                        onClose={() => setDeleteModal(false)}
                    />
                    {console.log(finalstate)}

                    {
                        history?.location?.state?.solo?.state[0]?.key!=='referral'&&
                        history?.location?.state?.solo?.state[0]?.key!=='oneTimeDeal'&&
                        history?.location?.state?.solo?.state[0]?.key!=='allCustomer'&&
                        history?.location?.state?.solo?.state[0]?.key!=='referrallogin'&&
                        history?.location?.state?.solo?.state[0]?.key!=='customerInactivity'&&
                        history?.location?.state?.solo?.state[0]?.key!=='point'&&
                        history?.location?.state?.solo?.state[0]?.key!=='visit'&&
                        history?.location?.state?.solo?.state[0]?.key!=='event'&&
                        history?.location?.state?.solo?.state[0]?.key!=='dealStatus'&&
                        history?.location?.state?.solo?.state[0]?.key!=='sharedLoyality'&&
                        history?.location?.state?.solo?.state[0]?.festival!=='Birth Day' &&
                        // (history?.location?.state?.solo?.state[0]?.key!=='event'&&history?.location?.state?.solo?.state[0]?.festival!=='Birth Day')&&
                        // finalstate.length>0&&finalstate.map((v,i)=>(
                        <><div className="">
                            <div className='d-flex p_8'>
                                <div className='color-neutral40 B12R w-100'>When this happens</div>
                                <div className='color-neutral40 B12R ml_24 w-100'>Perform these actions</div>
                                {
                                    console.log('finalstate[history.location.state.index]?.state', finalstate[history.location.state.index]?.state)
                                }
                            </div>
                            <div className={`d-flex w-100 gap-24 expanded`}>
                                <div className=" w-50 position-relative">
                                    {

                                        finalstate[history.location.state.index]?.state?.length > 0 && finalstate[history.location.state.index].state.map((value, index) => (
                                            <>
                                                {value !== null && <div className="childBox position-relative ">
                                                    <div className="w-100">
                                                        <div className="w-100 align-items-center d-flex space-between mb_8">
                                                            <div className="w-100 align-items-center d-flex gap-8">
                                                                <img src={value.img} height={20} width={20} />
                                                                <div className="d-flex space-between hpx-24 align-items-center"><div className="Body14M color-neutral70">{
                                                                    (value.name === "Referral reward for receiver" || value.name === "Referral reward for sender") ?
                                                                        "Referral" :
                                                                        value.name === "Deal Status" ?
                                                                            "Reward Status" :
                                                                            // value.name === "Visits" ? 
                                                                            // "When customer visit":
                                                                            // value.name === "Points" ? 
                                                                            // "When points":
                                                                            value.name === "One Time Deal" ?
                                                                                "Offer customer one time reward" :
                                                                                (value.name === "All Customer" || value.name === "Customer Tag" || value.name === 'Customer Type' || value.name === 'Visits' || value.name === 'Customer Inactivity' || value.name === 'Points') ?
                                                                                    "Customers" :
                                                                                    value.name}</div>
                                                                </div>

                                                            </div>
                                                            <div className="cp showcross svg-hover"
                                                                onClick={() => deleteFunc(index, history.location.state.index, true)
                                                                    // {setIdx(index); setViewMore(!viewMore)}
                                                                } ><SvgIcons.CrossIcon /></div>
                                                        </div>
                                                        <div className="w-100">
                                                            {
                                                                value.name === 'Event' &&
                                                                <>
                                                                    <div className="subTextBox d-flex align-items-center">
                                                                        <div className="B14M color-neutral80 underline">
                                                                            <select
                                                                                style={{ backgroundColor: 'inherit' }}
                                                                                className={'border-none focusactive underline cp'}
                                                                                onChange={(e) => { finalstate[history.location.state.index].state[index].festival = e.target.value; setFinalstate([...finalstate]) }}
                                                                                value={finalstate[history.location.state.index].state[index].festival}
                                                                            >
                                                                                {Options.getFestivals()}
                                                                            </select>
                                                                        </div>
                                                                        {finalstate[history.location.state.index].state[index].festival === 'Custom Date' ?
                                                                            <>
                                                                                <div className='underline color-neutral90 B14M w-100'>
                                                                                    <div className=" mainfocusactive wpx-230 cp h-fit-content B14M color-neutral80 underline">

                                                                                        <DateRangePicker

                                                                                            // initialSettings={{ startDate: `${(new Date(finalstate[history.location.state.index].state[index].eventStart).getMonth() + 1).toString().padStart(2, '0')}/${new Date(finalstate[history.location.state.index].state[index].eventStart).getDate().toString().padStart(2, '0')}/${new Date(finalstate[history.location.state.index].state[index].eventStart).getFullYear()}`, endDate: `${(new Date(finalstate[history.location.state.index].state[index].eventEnd).getMonth() + 1).toString().padStart(2, '0')}/${new Date(finalstate[history.location.state.index].state[index].eventEnd).getDate().toString().padStart(2, '0')}/${new Date(finalstate[history.location.state.index].state[index].eventEnd).getFullYear()}` }}
                                                                                            initialSettings={initialsDates(index)}
                                                                                            // onEvent={(event, picker)=>console.log('picker',picker.startDate, 'event', event)} 
                                                                                            onCallback={(start, end, label) => {
                                                                                                console.log('start, end', start._d, end._d)
                                                                                                finalstate[history.location.state.index].state[index].eventStart = new Date(start._d).valueOf();
                                                                                                finalstate[history.location.state.index].state[index].eventEnd = new Date(end._d).valueOf();
                                                                                                setFinalstate([...finalstate])
                                                                                            }
                                                                                            }>
                                                                                            <input type="text" className="cp form-control color-neutral80 underline shadow-none border-none" style={{ fontSize: '14px', fontWeight: '500' }} />
                                                                                        </DateRangePicker>
                                                                                    </div>

                                                                                </div>
                                                                            </>
                                                                            : finalstate[history.location.state.index].state[index].festival === 'Week' ? <>
                                                                                <div className='underline color-neutral90 B14M w-100'>
                                                                                    <select
                                                                                        style={{ backgroundColor: 'inherit' }}
                                                                                        className={'border-none focusactive underline cp'}
                                                                                        onChange={(e) => { finalstate[history.location.state.index].state[index].week = e.target.value; setFinalstate([...finalstate]) }}
                                                                                        value={finalstate[history.location.state.index].state[index].week}
                                                                                    >
                                                                                        <option value={1}>{"Monday"}</option>
                                                                                        <option value={2}>{"Tuesday"}</option>
                                                                                        <option value={3}>{"Wednesday"}</option>
                                                                                        <option value={4}>{"Thursday"}</option>
                                                                                        <option value={5}>{"Friday"}</option>
                                                                                        <option value={6}>{"Saturday"}</option>
                                                                                        <option value={0}>{"Sunday"}</option>

                                                                                    </select></div>
                                                                            </>
                                                                                : finalstate[history.location.state.index].state[index].festival === 'Month' ? <>
                                                                                    <div className='underline color-neutral90 B14M w-100'>
                                                                                        <select
                                                                                            style={{ backgroundColor: 'inherit' }}
                                                                                            className={'border-none focusactive underline cp'}
                                                                                            onChange={(e) => { finalstate[history.location.state.index].state[index].month = e.target.value; setFinalstate([...finalstate]) }}
                                                                                            value={finalstate[history.location.state.index].state[index].month}
                                                                                        >
                                                                                            <option value={0}>{"January"}</option>
                                                                                            <option value={1}>{"February"}</option>
                                                                                            <option value={2}>{"March"}</option>
                                                                                            <option value={3}>{"April"}</option>
                                                                                            <option value={4}>{"May"}</option>
                                                                                            <option value={5}>{"June"}</option>
                                                                                            <option value={6}>{"July"}</option>
                                                                                            <option value={7}>{"August"}</option>
                                                                                            <option value={8}>{"September"}</option>
                                                                                            <option value={9}>{"October"}</option>
                                                                                            <option value={10}>{"November"}</option>
                                                                                            <option value={11}>{"December"}</option>

                                                                                        </select></div>
                                                                                </> : console.log('none')

                                                                        }

                                                                        {
                                                                            finalstate[history.location.state.index].state[index].festival === 'Birth Day' && (

                                                                                <div className="d-flex  align-items-center">
                                                                                    <div className="B14M color-neutral70 mr_6">Active for</div>
                                                                                    <div className="B14M color-neutral80 underline">
                                                                                        <select
                                                                                            style={{ backgroundColor: 'inherit' }}
                                                                                            className={"border-none focusactive underline"}
                                                                                            onChange={(e) => { finalstate[history.location.state.index].state[index].birthdayTime = e.target.value; setFinalstate([...finalstate]) }}
                                                                                            value={finalstate[history.location.state.index].state[index].birthdayTime}
                                                                                        >
                                                                                            <option value={1}>{"1 day"}</option>
                                                                                            <option value={7}>{' 7 days '}</option>
                                                                                            <option value={14}>{' 14 days '}</option>
                                                                                            <option value={30}>{' 30 days '}</option>

                                                                                        </select>
                                                                                    </div>
                                                                                    <div className="B14M color-neutral70 mr_6">{' when its user birthday. '}</div>
                                                                                </div>

                                                                            )
                                                                        }
                                                                    </div>
                                                                </>
                                                            }


                                                            {
                                                                value.name === 'Points' &&
                                                                <>
                                                                    <div className='border borderRadius-6 color-neutral60 B14M w-100 pt_8 pb_8 pl_16 pr_16 mt_8'>All Customers</div>
                                                                    <div className='color-neutral90 B14M mt_8 mb_8'>Onlyy contacts, that match all of the following conditions:</div>
                                                                    <div className="d-flex align-items-center subTextBox">
                                                                        <div className="B14M color-neutral70 mr_6">When points are</div>
                                                                        <div className="B14M color-neutral80 underline">
                                                                            <select
                                                                                style={{ backgroundColor: 'inherit' }}
                                                                                className={
                                                                                    finalstate[history.location.state.index].state[index].condition === 'equal' ? "border-none underline focusactive wpx-50" :
                                                                                        finalstate[history.location.state.index].state[index].condition === 'lessThan' ? "border-none underline focusactive wpx-85" :
                                                                                            finalstate[history.location.state.index].state[index].condition === 'moreThan' ? "border-none underline focusactive wpx-92" :
                                                                                                "border-none focusactive underline"}
                                                                                onChange={(e) => { finalstate[history.location.state.index].state[index].condition = e.target.value; setFinalstate([...finalstate]) }}
                                                                                value={finalstate[history.location.state.index].state[index].condition}
                                                                            >
                                                                                <option value={"equal"}>{"equal"}</option>
                                                                                <option value={"lessThan"}>{"less than"}</option>
                                                                                <option value={"moreThan"}>{"more than"}</option>
                                                                                <option value={"lessThanEqual"}>{"less than equal"}</option>
                                                                                <option value={"moreThanEqual"}>{"more than equal"}</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="ml_2 cp mainfocusactive d-flex B14M color-neutral80 underline border-none"
                                                                            onClick={() => setShow({ modal: true, title: 'Points', value: finalstate[history.location.state.index].state[index].points || '0', key: 'finalstate[history.location.state.index].state[index].points', onChange: (e) => { finalstate[history.location.state.index].state[index].points = e.target.value; setFinalstate([...finalstate]) } })}
                                                                        >
                                                                            <div>{finalstate[history.location.state.index].state[index].points || '0'}</div>
                                                                            {/* <InputContentEditable value={finalstate[history.location.state.index].state[index].points || '0'} onChange = {(e)=>{finalstate[history.location.state.index].state[index].points = e; setFinalstate([...finalstate])}} /> */}
                                                                            <div>&nbsp;</div>
                                                                            <div>Points</div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }

                                                            {
                                                                value.name === 'Visits' &&
                                                                <>
                                                                    <div className='border borderRadius-6 color-neutral60 B14M w-100 pt_8 pb_8 pl_16 pr_16 mt_8'>All Customers</div>
                                                                    <div className='color-neutral90 B14M mt_8 mb_8'>Only contacts, that match all of the following conditions:</div>
                                                                    <div className="d-flex align-items-center subTextBox">
                                                                        <div className=" B14M color-neutral70 mr_6">When customer visit</div>
                                                                        <div className="B14M color-neutral80 underline">
                                                                            <select
                                                                                style={{ backgroundColor: 'inherit' }}
                                                                                className={
                                                                                    finalstate[history.location.state.index].state[index].condition === 'equal' ? "border-none underline focusactive wpx-50" :
                                                                                        finalstate[history.location.state.index].state[index].condition === 'lessThan' ? "border-none underline focusactive wpx-85" :
                                                                                            finalstate[history.location.state.index].state[index].condition === 'moreThan' ? "border-none underline focusactive wpx-92" :
                                                                                                "border-none focusactive underline"}
                                                                                onChange={(e) => { finalstate[history.location.state.index].state[index].condition = e.target.value; setFinalstate([...finalstate]) }}
                                                                                value={finalstate[history.location.state.index].state[index].condition}
                                                                            >
                                                                                <option value={"equal"}>{"equal"}</option>
                                                                                <option value={"lessThan"}>{"less than"}</option>
                                                                                <option value={"moreThan"}>{"more than"}</option>
                                                                                <option value={"lessThanEqual"}>{"less than equal"}</option>
                                                                                <option value={"moreThanEqual"}>{"more than equal"}</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="ml_2 cp d-flex mainfocusactive Body14M color-neutral80 underline border-none"
                                                                            onClick={() => setShow({ modal: true, title: 'Visits', value: finalstate[history.location.state.index].state[index].visits || '0', key: 'finalstate[history.location.state.index].state[index].visits', onChange: (e) => { finalstate[history.location.state.index].state[index].visits = e.target.value; setFinalstate([...finalstate]) } })}
                                                                        >
                                                                            <div>{finalstate[history.location.state.index].state[index].visits || '0'}</div>
                                                                            {/* <InputContentEditable value={finalstate[history.location.state.index].state[index].visits || '0'} onChange = {(e)=>{finalstate[history.location.state.index].state[index].visits = e; setFinalstate([...finalstate])}} /> */}
                                                                            <div>&nbsp;</div>
                                                                            <div>Visits</div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            {
                                                                value.name === 'Customer Inactivity' &&
                                                                <>
                                                                    <div className='border borderRadius-6 color-neutral60 B14M w-100 pt_8 pb_8 pl_16 pr_16 mt_8'>All Customers</div>
                                                                    <div className='color-neutral90 B14M mt_8 mb_8'>Only contacts, that match all of the following conditions:</div>
                                                                    <div className="d-flex subTextBox align-items-center">
                                                                        <div className="B14M color-neutral70 mr_6 ">Inactivity is</div>
                                                                        <div className="B14M color-neutral80 underline">
                                                                            <select
                                                                                // style={{ backgroundColor: 'inherit' }}
                                                                                className={
                                                                                    finalstate[history.location.state.index].state[index].condition === 'equal' ? "border-none underline focusactive wpx-50" :
                                                                                        finalstate[history.location.state.index].state[index].condition === 'lessThan' ? "border-none underline focusactive wpx-85" :
                                                                                            finalstate[history.location.state.index].state[index].condition === 'moreThan' ? "border-none underline focusactive wpx-92" :
                                                                                                "border-none underline focusactive"}
                                                                                onChange={(e) => { finalstate[history.location.state.index].state[index].condition = e.target.value; setFinalstate([...finalstate]) }}
                                                                                value={finalstate[history.location.state.index].state[index].condition}
                                                                            >
                                                                                <option value={"equal"}>{"equal"}</option>
                                                                                <option value={"lessThan"}>{"less than"}</option>
                                                                                <option value={"moreThan"}>{"more than"}</option>
                                                                                <option value={"lessThanEqual"}>{"less than equal"}</option>
                                                                                <option value={"moreThanEqual"}>{"more than equal"}</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="ml_2 cp d-flex mainfocusactive B14M color-neutral80 underline border-none"
                                                                            onClick={() => setShow({ modal: true, title: 'Inactivity', value: finalstate[history.location.state.index].state[index].days || '0', key: 'finalstate[history.location.state.index].state[index].days', onChange: (e) => { finalstate[history.location.state.index].state[index].days = e.target.value; setFinalstate([...finalstate]) } })}
                                                                        >
                                                                            {/* <InputContentEditable value={finalstate[history.location.state.index].state[index].days || '0'} onChange = {(e)=>{finalstate[history.location.state.index].state[index].days = e; setFinalstate([...finalstate])}} /> */}
                                                                            <div>{finalstate[history.location.state.index].state[index].days || '0'}</div>
                                                                            <div>&nbsp;</div>
                                                                            <div>Days</div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            {
                                                                value.name === 'Referral reward for sender' &&
                                                                <>
                                                                    <div className=' border borderRadius-6 color-neutral60 B14M w-100 pt_8 pb_8 pl_16 pr_16 mt_8'>Sender</div>
                                                                    <div className='color-neutral90 B14M mt_8 mb_8'>Only contacts, that match all of the following conditions:</div>
                                                                    <div className="d-flex gap-4 align-items-center subTextBox ">

                                                                        <div className="ml_2 mainfocusactive d-flex B14M color-neutral80 underline border-none cp"
                                                                            onClick={() => setShow({ modal: true, title: 'Customer Invites', 
                                                                                key: 'finalstate[history.location.state.index].state[index].customer', 
                                                                                value: finalstate[history.location.state.index].state[index].customer || '0',
                                                                                onChange: (e) => { finalstate[history.location.state.index].state[index].customer = e.target.value; setFinalstate([...finalstate]) } })}
                                                                        >
                                                                            <div>{finalstate[history.location.state.index].state[index].customer || '0'}</div>
                                                                            {/* <InputContentEditable value={finalstate[history.location.state.index].state[index].customer || '0'} onChange = {(e)=>{finalstate[history.location.state.index].state[index].customer = e; setFinalstate([...finalstate])}} /> */}
                                                                            <div>&nbsp;</div>
                                                                            <div>Invites</div>
                                                                        </div>
                                                                        <div className="B14M color-neutral70">When receiver perform</div>
                                                                        <div className="B14M color-neutral80 underline">
                                                                            <select
                                                                                style={{ backgroundColor: 'inherit' }}
                                                                                className={"border-none focusactive underline"}
                                                                                onChange={(e) => { finalstate[history.location.state.index].state[index].type = e.target.value; setFinalstate([...finalstate]) }}
                                                                                value={finalstate[history.location.state.index].state[index].type}
                                                                            >
                                                                                <option value={'signup'}>{'signup'}</option>
                                                                                <option value={'activity'}>{'activity'}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            {
                                                                (value.name === 'Reward Status' || value.name === 'Deal Status') &&
                                                                <>
                                                                    <div className=" space-between subTextBox align-items-center flex-wrap-wrap">
                                                                        <div className="d-flex gap-8 align-items-center">
                                                                            <div className=" cp dealViewIcon" onClick={() => setShow({ ...show, dealModal: true, selectedDeal: finalstate[history.location.state.index].state[index] })}><SvgIcons.EyeIcon /></div>
                                                                            <div className="B14M color-neutral80 underline">

                                                                                <select
                                                                                    className="focusactive border-none underline"
                                                                                    // style={{width: '100%'}}
                                                                                    onChange={(e) => {
                                                                                        // console.log('html1',e.target.value);
                                                                                        // console.log('html2',e.target.selectedOptions[0].label);
                                                                                        // // console.log('html',e.target.innerHTML);
                                                                                        // // console.log('html text',e.target.selectedOptions[0].label);
                                                                                        finalstate[history.location.state.index].state[index].deal = e.target.value;
                                                                                        finalstate[history.location.state.index].state[index].dealname = e.target.selectedOptions[0].label;
                                                                                        setFinalstate([...finalstate])
                                                                                    }}
                                                                                    value={finalstate[history.location.state.index].state[index].deal}
                                                                                >
                                                                                    {
                                                                                        filteredOptions && filteredOptions.length > 0 && filteredOptions.map((val, ind) => (
                                                                                            <option value={val.value}>{val.label}</option>
                                                                                        ))
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                            <div className="B14M color-neutral70">is</div>
                                                                            <div className="underline color-neutral90 B14M w-100">
                                                                                <select
                                                                                    style={{ backgroundColor: 'inherit' }}
                                                                                    className="focusactive border-none underline for-w"
                                                                                    onChange={(e) => { finalstate[history.location.state.index].state[index].status = e.target.value; setFinalstate([...finalstate]) }}
                                                                                    value={finalstate[history.location.state.index].state[index].status}
                                                                                >
                                                                                    <option value={"Assigned"}>{"Assigned"}</option>
                                                                                    <option value={"Redeemed"}>{"Redeemed"}</option>
                                                                                    <option value={"Expired"}>{"Expired"}</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className='color-neutral90 B14M mt_8 mb_8'>Only contacts, that match all of the following conditions:</div> */}
                                                                </>
                                                            }
                                                            <CustomModal
                                                                component={<DealModalComponent filteredOptions={filteredOptions} onClose={() => setShow({ ...show, dealModal: false })} deal={show.selectedDeal} />}
                                                                open={show.dealModal}
                                                                boxShadow={false}
                                                                minWidth="600px"
                                                                backgroundColor='rgba(0, 0, 0, 0.2)'
                                                                onClose={() => setShow({ ...show, dealModal: false })}
                                                            />
                                                            {
                                                                value.name === 'Customer Tag' &&
                                                                <>
                                                                    <div className='border borderRadius-6 color-neutral60 B14M w-100 pt_8 pb_8 pl_16 pr_16 mt_8'>All Customers</div>
                                                                    <div className='color-neutral90 B14M mt_8 mb_8'>Only contacts, that match all of the following conditions:</div>
                                                                    <div className="d-flex subTextBox align-items-center">
                                                                        <div className="B14M color-neutral70 mr_6">Tag is</div>
                                                                        <div className="mainfocusactive d-flex B14M color-neutral80 underline border-none cp"
                                                                            onClick={() => setShow({ modal: true, title: 'Tag', value: finalstate[history.location.state.index].state[index].customerTag || 'Enter customer tag', key: 'finalstate[history.location.state.index].state[index].customerTag', onChange: (e) => { finalstate[history.location.state.index].state[index].customerTag = e.target.value; setFinalstate([...finalstate]) } })}
                                                                        >
                                                                            <div className="B14M color-neutral80">{finalstate[history.location.state.index].state[index].customerTag || 'Enter customer tag'}</div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }

                                                            {
                                                                value.name == 'Shared Loyality' &&
                                                                <>
                                                                    {
                                                                        console.log('SharedLoyality', finalstate[history.location.state.index].state[index])
                                                                    }
                                                                    <div className='border borderRadius-6 color-neutral60 B14M w-100 pt_8 pb_8 pl_16 pr_16 mt_8'>Shared Loyality</div>
                                                                    <div className='color-neutral90 B14M mt_8 mb_8'>Only contacts, that match all of the following conditions:</div>
                                                                    <div className="d-flex subTextBox align-items-center">
                                                                        <select
                                                                            style={{ backgroundColor: 'inherit' }}
                                                                            className="focusactive border-none underline B14M "
                                                                            onChange={(e) => { finalstate[history.location.state.index].state[index].sharedLoyality = e.target.value; setFinalstate([...finalstate]) }}
                                                                            value={finalstate[history.location.state.index].state[index].sharedLoyality}
                                                                        >
                                                                            <option value={"gold"}>{"Gold"}</option>
                                                                            <option value={"platinium"}>{"Platinium"}</option>
                                                                        </select>
                                                                    </div>
                                                                </>
                                                            }


                                                            {
                                                                value.name === 'Tiers' &&
                                                                <>
                                                                    <div className='border borderRadius-6 color-neutral60 B14M w-100 pt_8 pb_8 pl_16 pr_16 mt_8'>All Customers</div>
                                                                    <div className='color-neutral90 B14M mt_8 mb_8'>Only contacts, that match all of the following conditions:</div>
                                                                    <div className="d-flex subTextBox align-items-center">
                                                                        <div className="B14M color-neutral70 mr_6">Whose tier is</div>
                                                                        {/* <div className="B14M color-neutral70">is</div> */}
                                                                        <div className="B14M color-neutral80 underline">
                                                                            <select
                                                                                style={{ backgroundColor: 'inherit' }}
                                                                                className="focusactive border-none underline"
                                                                                onChange={(e) => { finalstate[history.location.state.index].state[index].tier = e.target.value; setFinalstate([...finalstate]) }}
                                                                                value={finalstate[history.location.state.index].state[index].tier}
                                                                            >
                                                                                <option value={"Silver"}>{"Silver"}</option>
                                                                                <option value={"Gold"}>{"Gold"}</option>
                                                                                <option value={"Platinum"}>{"Platinum"}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            {
                                                                value.name === 'Customer Type' &&
                                                                <>
                                                                    <div className='border borderRadius-6 color-neutral60 B14M w-100 pt_8 pb_8 pl_16 pr_16 mt_8'>All Customers</div>
                                                                    <div className='color-neutral90 B14M mt_8 mb_8'>Only contacts, that match all of the following conditions:</div>
                                                                    <div className="d-flex subTextBox align-items-center">
                                                                        <div className="B14M color-neutral70 mr_6">Type is</div>
                                                                        {/* <div className="B14M color-neutral70">is</div> */}
                                                                        <div className="B14M color-neutral80 underline">
                                                                            <select
                                                                                style={{ backgroundColor: 'inherit' }}
                                                                                className="focusactive border-none underline"
                                                                                onChange={(e) => { finalstate[history.location.state.index].state[index].customerType = e.target.value; setFinalstate([...finalstate]) }}
                                                                                value={finalstate[history.location.state.index].state[index].customerType}
                                                                            >
                                                                                <option value={"loyal"}>{"Loyal Customers"}</option>
                                                                                <option value={"walkIn"}>{"Walk-In Customers"}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            {
                                                                value.name === 'All Customer' &&
                                                                <div className='border borderRadius-6 color-neutral60 B14M w-100 pt_8 pb_8 pl_16 pr_16 mt_8'>All Customers</div>
                                                            }
                                                            {/* {
                                                                value.name === 'All Customer' &&
                                                                <div className='border borderRadius-6 color-neutral60 B14M w-100 pt_8 pb_8 pl_16 pr_16 mt_8'>All Customers</div>
                                                            } */}
                                                            {/* {
                                                                value.name === 'External Systems' &&
                                                                <div className='border borderRadius-6 color-neutral60 B14M w-100 pt_8 pb_8 pl_16 pr_16 mt_8'>External Systems</div>
                                                            } */}

                                                            {console.log('finalstate[history.location.state.index].state[index].sharedLoyality', finalstate[history.location.state.index].state[index].sharedLoyality)}
                                                            {
                                                                value.name === 'Birthday' &&
                                                                <>
                                                                    <div className=' border borderRadius-6 color-neutral60 B14M w-100 pt_8 pb_8 pl_16 pr_16 mt_8'>Birthday</div>
                                                                    <div className='color-neutral90 B14M mt_8 mb_8'>Only contacts, that match all of the following conditions:</div>
                                                                    <div className="d-flex subTextBox align-items-center">
                                                                        <div className="B14M color-neutral70 mr_6">Active for</div>
                                                                        <div className="B14M color-neutral80 underline">
                                                                            <select
                                                                                style={{ backgroundColor: 'inherit' }}
                                                                                className={"border-none focusactive underline"}
                                                                                onChange={(e) => { finalstate[history.location.state.index].state[index].birthdayTime = e.target.value; setFinalstate([...finalstate]) }}
                                                                                value={finalstate[history.location.state.index].state[index].birthdayTime}
                                                                            >
                                                                                <option value={0}>{' 1 day '}</option>
                                                                                <option value={7}>{' 7 days '}</option>
                                                                                <option value={14}>{' 14 days '}</option>
                                                                                <option value={30}>{' 30 days '}</option>

                                                                            </select>
                                                                        </div>
                                                                        <div className="B14M color-neutral70 mr_6">{' when its user birthday. '}</div>
                                                                    </div>
                                                                </>
                                                            }
                                                            {
                                                                value.name === 'Referral reward for receiver' &&
                                                                <>
                                                                    <div className=' border borderRadius-6 color-neutral60 B14M w-100 pt_8 pb_8 pl_16 pr_16 mt_8'>Receiver</div>
                                                                    <div className='color-neutral90 B14M mt_8 mb_8'>Only contacts, that match all of the following conditions:</div>
                                                                    <div className="d-flex subTextBox align-items-center">
                                                                        <div className="B14M color-neutral70 mr_6">is true When receiver perform</div>
                                                                        <div className="B14M color-neutral80 underline">
                                                                            <select
                                                                                style={{ backgroundColor: 'inherit' }}
                                                                                className={"border-none focusactive underline"}
                                                                                onChange={(e) => { finalstate[history.location.state.index].state[index].type = e.target.value; setFinalstate([...finalstate]) }}
                                                                                value={finalstate[history.location.state.index].state[index].type}
                                                                            >
                                                                                <option value={'signup'}>{'signup'}</option>
                                                                                <option value={'activity'}>{'activity'}</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            {
                                                                value.name === 'One Time Deal' &&
                                                                <div className="d-flex subTextBox align-items-center">
                                                                    <div className="pt_2 pb_2 pr_2 d-flex B14M color-neutral70 border-none">
                                                                        Offer customer one time reward Coupon
                                                                    </div>
                                                                </div>
                                                            }







                                                            {/* <ClickOutside onClick={()=>setViewMore(false)}> */}
                                                            {viewMore &&
                                                                index == idx &&
                                                                dropIdx === history.location.state.index &&
                                                                <div className="childbox-dropdown-trigger">
                                                                    <ViewDetail items={[
                                                                        {
                                                                            name: 'Delete',
                                                                            function: () => deleteFunc(index, history.location.state.index, true),
                                                                            icon: <SvgIcons.DeleteIcon />,
                                                                        }
                                                                        // {
                                                                        //     name    : 'View',
                                                                        //     function: handleSelectedCustomer
                                                                        // }
                                                                    ]} />
                                                                </div>
                                                            }
                                                            {/* </ClickOutside> */}
                                                        </div>
                                                    </div>
                                                </div>}
                                                {index !== finalstate[history.location.state.index].state.length - 1 && <div className="text-align-center B16M mt_12 mb_12 color-neutral50">and</div>}
                                            </>
                                        ))
                                    }
                                    <div className='w-100 hpx-48 trigger1 mt_24' onClick={() => { setAddtrigger(!addtrigger); setDropIdx(history.location.state.index) }} varient='tertiary'>Add Trigger</div>
                                    {addtrigger && dropIdx === history.location.state.index && <ClickOutside onClick={(e) => {
                                        // e.preventDefault();
                                        setAddtrigger(false)
                                        e.stopPropagation()
                                    }} >
                                        <div className="p_8 mt_5 dropDownActions w-100 bg-color-white h-fit-content scroll borderRadius-4 " >
                                            {
                                                dropdownTrigger.map((value, index) => (
                                                    <div className={value.name == 'Customer' || value.name == 'Activity' || value.name == 'Ref' || value.name == 'External Systems' ? 'd-flex sbgc-F5F7FC mt_2 mb_2 p_6 gap-16 cp  borderRadius-6' : 'd-flex p_6 gap-16 cp hoverclass borderRadius-6'} style={{ backgroundColor: value.name == "Reward Status" && filteredOptions?.length < 1 && '#F5F7FC', pointerEvents: value.name == "Reward Status" && filteredOptions?.length < 1 && 'none' }} onClick={() => {

                                                        if (value.name === 'Reward Status') { // && filteredOptions&&filteredOptions.length===1
                                                            if (finalstate.length == history.location.state.index) {
                                                                finalstate.push({ state: [{ ...value, deal: filteredOptions[0].value, dealname: filteredOptions[0].label, status: 'Assigned' }], state1: [] })
                                                                console.log('aka');
                                                            }
                                                            else {
                                                                console.log('kak');
                                                                finalstate[history.location.state.index].state.push({ ...value, deal: filteredOptions[0].value, dealname: filteredOptions[0].label, status: 'Assigned' })
                                                            }
                                                            setFinalstate([...finalstate])
                                                            setAddtrigger(false)
                                                        }
                                                        else if (value.name === 'Customer Inactivity') {
                                                            if (finalstate.length == history.location.state.index) finalstate.push({ state: [{ ...value, condition: 'equal' }], state1: [] })
                                                            else finalstate[history.location.state.index].state.push({ ...value, condition: 'equal' })
                                                            setAddtrigger(false)
                                                            setFinalstate([...finalstate])
                                                        }
                                                        else if (value.name == 'Shared Loyality') {
                                                            if (finalstate.length == history.location.state.index) finalstate.push({ state: [{ ...value, sharedLoyality: 'gold' }], state1: [] })
                                                            else finalstate[history.location.state.index].state.push({ ...value, sharedLoyality: 'gold' })
                                                            setAddtrigger(false)
                                                            setFinalstate([...finalstate])
                                                        }
                                                        else if (value.name === 'Customer Type') {
                                                            if (finalstate.length == history.location.state.index) finalstate.push({ state: [{ ...value, customerType: 'loyal' }], state1: [] })
                                                            else finalstate[history.location.state.index].state.push({ ...value, customerType: 'loyal' })
                                                            setAddtrigger(false)
                                                            setFinalstate([...finalstate])
                                                        }
                                                        else if (value.name === 'Event') {
                                                            if (finalstate.length == history.location.state.index) finalstate.push({ state: [{ ...value, festival: 'Custom Date' }], state1: [] })
                                                            else finalstate[history.location.state.index].state.push({ ...value, festival: 'Custom Date' })
                                                            setAddtrigger(false)
                                                            setFinalstate([...finalstate])
                                                        }
                                                        else if (value.name === 'Tiers') {
                                                            if (finalstate.length == history.location.state.index) finalstate.push({ state: [{ ...value, tier: 'Silver' }], state1: [] })
                                                            else finalstate[history.location.state.index].state.push({ ...value, tier: 'Silver' })
                                                            setAddtrigger(false)
                                                            setFinalstate([...finalstate])
                                                        }
                                                        else if (value.name === 'Visits') {
                                                            if (finalstate.length == history.location.state.index) finalstate.push({ state: [{ ...value, condition: 'equal' }], state1: [] })
                                                            else finalstate[history.location.state.index].state.push({ ...value, condition: 'equal' })
                                                            setAddtrigger(false)
                                                            setFinalstate([...finalstate])
                                                        }
                                                        else if (value.name === 'Points') {
                                                            if (finalstate.length == history.location.state.index) finalstate.push({ state: [{ ...value, condition: 'equal' }], state1: [] })
                                                            else finalstate[history.location.state.index].state.push({ ...value, condition: 'equal' })
                                                            setAddtrigger(false)
                                                            setFinalstate([...finalstate])
                                                        }
                                                        else if (value.name === 'Referral reward for sender') {
                                                            if (finalstate.length == history.location.state.index) finalstate.push({ state: [{ ...value, type: 'signup' }], state1: [] })
                                                            else finalstate[history.location.state.index].state.push({ ...value, type: 'signup' })
                                                            setAddtrigger(false)
                                                            setFinalstate([...finalstate])
                                                        }
                                                        else if (value.name === 'Referral reward for receiver') {
                                                            if (finalstate.length == history.location.state.index) finalstate.push({ state: [{ ...value, type: 'signup' }], state1: [] })
                                                            else finalstate[history.location.state.index].state.push({ ...value, type: 'signup' })
                                                            setAddtrigger(false)
                                                            setFinalstate([...finalstate])
                                                        }
                                                        else if (value.name === "Ref") { }
                                                        else if (value.name === "Customer") { }
                                                        else if (value.name === "Activity") { }
                                                        else if (value.name === "External Systems") { }
                                                        else {
                                                            setAddtrigger(false)
                                                            // console.log(finalstate);
                                                            if (finalstate.length == history.location.state.index) finalstate.push({ state: [value], state1: [] })
                                                            else finalstate[history.location.state.index].state.push(value)
                                                            setFinalstate([...finalstate])
                                                        }

                                                    }}>
                                                        {/* <img className="borderRadius-4 border p_8 " src={value.img} width={32} height={32} /> */}
                                                        {/* <div className="borderRadius-4 border h-fit-content" style={{padding: '4px'}}>
                                            <img  src={value.img} width={24} height={24} />
                                        </div>
                                        <div>
                                            <div className="Body12M lh-20 color-neutral90">{value.name}</div>
                                            <div className="Body12R lh-16 color-neutral60 ">{value.description}</div>
                                        </div> */}
                                                        <div className="d-flex gap-16" >
                                                            {(value.name === "Customer" || value.name === "Deal Status" || value.name === "Ref" || value.name === "External Systems" || value.name === "Activity" || value.name === "Birthday") && <img src={value.img} width={20} height={20} />}
                                                            <div className={(value.name === "Customer" || value.name === "Deal Status" || value.name === "Ref" | value.name === "External Systems" || value.name === "Birthday" || value.name === "Activity") ? "B14M lh-20 color-neutral90" : 'B14M lh-20 color-neutral90 ml_35'}>{
                                                                value.name === "Ref" ? "Referral" :
                                                                    value.name === "Referral reward for sender" ? (value.name !== "Customer" && value.name !== "Event" && value.name !== "Reward Status" && value.name !== "Deal Status" && value.name !== "Ref") ? (<>{"Sender"} <span className="B10R italic ml_4">(condition)</span></>) : "Type" :
                                                                        value.name === "Referral reward for receiver" ? (value.name !== "Customer" && value.name !== "Event" && value.name !== "Reward Status" && value.name !== "Deal Status" && value.name !== "Ref") ? (<>{"Receiver"} <span className="B10R italic ml_4">(condition)</span></>) : "Type" :
                                                                            value.name === "Customer Type" ? (value.name !== "Customer" && value.name !== "Event" && value.name !== "Reward Status" && value.name !== "Deal Status" && value.name !== "Ref") ? (<>{"Type"} <span className="B10R italic ml_4">(condition)</span></>) : "Type" :
                                                                                value.name === "Customer Tag" ? (value.name !== "Customer" && value.name !== "Event" && value.name !== "Reward Status" && value.name !== "Deal Status" && value.name !== "Ref") ? (<>{"Tag"} <span className="B10R italic ml_4">(condition)</span></>) : "Tag" :
                                                                                    value.name === 'Customer Inactivity' ? (value.name !== "Customer" && value.name !== "Event" && value.name !== "Reward Status" && value.name !== "Deal Status" && value.name !== "Ref") ? (<>{"Inactivity"} <span className="B10R italic ml_4">(condition)</span></>) : 'Inactivity' :
                                                                                        (value.name !== "Customer" && value.name !== "Activity" && value.name !== "Event" && value.name !== "Reward Status" && value.name !== "Deal Status" && value.name !== "Ref" && value.name !== "External Systems" && value.name !== "Birthday") ? (<>{value.name} <span className="B10R italic ml_4">(condition)</span></>) : value.name
                                                            }</div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </ClickOutside>}
                                    <div className="mt_24">&nbsp;</div>
                                </div>
                                <div className=" w-50 position-relative">
                                    <div className={finalstate[history.location.state.index]?.state1?.length > 0 ? "childBoxAction" : ""}>
                                        {
                                            finalstate[history.location.state.index]?.state1?.length > 0 && finalstate[history.location.state.index].state1.map((value, index) => (
                                                value !== null && <div className="childBox2 position-relative mb_16">
                                                    <div className="">

                                                        <div className="w-100">
                                                            <div className="d-flex space-between hpx-24 align-items-center mb_8">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="p_6 h-fit-content">
                                                                        <img src={value.img} height={20} width={20} />
                                                                    </div>
                                                                    <div className="Body14M color-neutral60">{(value.name === "Reward Deal" || value.name === "Reward") ? "Reward" : value.name}</div>
                                                                </div>
                                                                <div className="cp more-option showcross svg-hover"
                                                                    onClick={() => deleteFunc(index, history.location.state.index, false)
                                                                    } ><SvgIcons.CrossIcon /></div></div>
                                                            {
                                                                (value.name === 'Reward Deal' || value.name === 'Reward') &&
                                                                <div className="subTextBox">
                                                                    <div className="d-flex space-between align-items-center">
                                                                        <div className="d-flex gap-5 align-items-center">
                                                                            <div className=" cp dealViewIcon" onClick={() => setShow({ ...show, dealModal: true, selectedDeal: finalstate[history.location.state.index].state1[index] })}><SvgIcons.EyeIcon /></div>
                                                                            <CustomModal
                                                                                component={<DealModalComponent filteredOptions={filteredOptions} onClose={() => setShow({ ...show, dealModal: false })} deal={show.selectedDeal} />}
                                                                                open={show.dealModal}
                                                                                boxShadow={false}
                                                                                minWidth="600px"
                                                                                backgroundColor='rgba(0, 0, 0, 0.2)'
                                                                                onClose={() => setShow({ ...show, dealModal: false })}
                                                                            />
                                                                            <div className="B14M color-neutral80 underline for-wid">
                                                                                {/* <CustomSelect
                                                                                 className="border-none focusactive underline for-w"
                                                                                 style={{ padding: '0px' }} 
                                                                                 onChange={(e) => {
                                                                                     console.log('html' , e.target.selectedOptions);
                                                                                     console.log('html' , e.target);

                                                                                     // console.log('html text',e.target.selectedOptions[0].label);
                                                                                     finalstate[history.location.state.index].state1[index].rewardDeal = e.target.value;
                                                                                     finalstate[history.location.state.index].state1[index].dealname = e.target.value;

                                                                                    //  finalstate[history.location.state.index].state1[index].dealname = e.target.selectedOptions[0].label;
                                                                                     setFinalstate([...finalstate])
                                                                                 }}
                                                                                 value={finalstate[history.location.state.index].state1[index].rewardDeal}
                                                                                 options={  filteredOptions && filteredOptions.length > 0 && filteredOptions.map((val, ind) => (

                                                                                                  val?.label

                                                                                ))}
                                                                                /> */}
                                                                                <select
                                                                                    className="border-none focusactive underline for-w"
                                                                                    id="f-select"
                                                                                    onChange={(e) => {
                                                                                        // console.log('html' , document.getElementsByClassName('for-w')[0].style.width);
                                                                                        // console.log('html text',e.target.selectedOptions[0].label);
                                                                                        console.log("event", e.target.selectedOptions)
                                                                                        console.log("ss", document.getElementById('f-select').selectedOptions);
                                                                                        console.log("event2", e.target)
                                                                                        finalstate[history.location.state.index].state1[index].rewardDeal = e.target.value;
                                                                                        finalstate[history.location.state.index].state1[index].dealname = e.target.selectedOptions[0].label;
                                                                                        setFinalstate([...finalstate])


                                                                                        // document.getElementsByClassName('for-w')[0].style.width = (e.target.selectedOptions[0].label.length  * 10 ) + 'px'
                                                                                        // document.getElementById('f-select').style.minWidth = '50px'
                                                                                    }}
                                                                                    value={finalstate[history.location.state.index].state1[index].rewardDeal}
                                                                                >
                                                                                    {
                                                                                        filteredOptions && filteredOptions.length > 0 && filteredOptions.map((val, ind) => (

                                                                                            <option value={val.value}>{`${val.label}`}</option>


                                                                                        ))
                                                                                    }
                                                                                </select>

                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="Body14R pt_2 pb_2 pr_2 color-neutral70">Expiration: </div>
                                                                            <div className="Body14M color-neutral70 underline">
                                                                                <select
                                                                                    style={{ backgroundColor: 'inherit' }}
                                                                                    className="focusactive border-none underline"
                                                                                    onChange={(e) => { finalstate[history.location.state.index].state1[index].dealExpiration = e.target.value; setFinalstate([...finalstate]) }}
                                                                                    value={finalstate[history.location.state.index].state1[index].dealExpiration}
                                                                                >
                                                                                    <option value={"0"}>{"none"}</option>
                                                                                    <option value={"2629800000"}>{"30 Days"}</option>
                                                                                    <option value={"5259600000"}>{"60 Days"}</option>
                                                                                    <option value={"7889400000"}>{"90 Days"}</option>
                                                                                </select>
                                                                            </div>
                                                                            <div className="d-flex" >
                                                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => { document.getElementsByClassName('react-datepicker-wrapper')[0].childNodes[0].childNodes[0].click() }}>
                                                                                    <SvgIcons.CalanderIcon />
                                                                                </div>
                                                                                <DatePicker
                                                                                    onChange={(e) => {
                                                                                        console.log('finalstate[history.location.state.index].state1[index].ExpiryData', new Date(e).valueOf());
                                                                                        finalstate[history.location.state.index].state1[index].ExpiryData = new Date(e).valueOf(); setFinalstate([...finalstate])
                                                                                    }}
                                                                                    icon="fa fa-calendar"
                                                                                    style={{ display: 'none' }}
                                                                                    className="d-none"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            }
                                                            {
                                                                value.name === 'Customer Type' &&
                                                                <div className="d-flex subTextBox align-items-center">
                                                                    <div className="B14M color-neutral80 underline">
                                                                        <select
                                                                            style={{ backgroundColor: 'inherit' }}
                                                                            className="focusactive border-none underline"
                                                                            onChange={(e) => { finalstate[history.location.state.index].state1[index].customerType = e.target.value; setFinalstate([...finalstate]) }}
                                                                            value={finalstate[history.location.state.index].state1[index].customerType}
                                                                        >
                                                                            <option value={"loyal"}>{"Loyal"}</option>
                                                                            <option value={"walkIn"}>{"Walk-In"}</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                value.name === 'Give Points' &&
                                                                <div className="d-flex subTextBox align-items-center">
                                                                    <div className=" d-flex cp mainfocusactive B14M color-neutral80 underline border-none"
                                                                        onClick={() => setShow({ modal: true, title: 'Points', value: finalstate[history.location.state.index].state1[index].givePoints || 0, key: 'finalstate[history.location.state.index].state1[index].givePoints', onChange: (e) => { finalstate[history.location.state.index].state1[index].givePoints = e.target.value; setFinalstate([...finalstate]) } })}
                                                                    >
                                                                        <div>{finalstate[history.location.state.index].state1[index].givePoints || 0}</div>
                                                                        <div>&nbsp;</div>
                                                                        <div>Points</div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                value.name === 'Deduct Points' &&
                                                                <div className="d-flex subTextBox align-items-center ">
                                                                    <div className="d-flex cp mainfocusactive B14M color-neutral80 underline border-none"
                                                                        onClick={() => setShow({ modal: true, title: 'Deduct Points', value: finalstate[history.location.state.index].state1[index].deductPoints || 0, key: 'finalstate[history.location.state.index].state1[index].deductPoints', onChange: (e) => { finalstate[history.location.state.index].state1[index].deductPoints = e.target.value; setFinalstate([...finalstate]) } })}
                                                                    >
                                                                        <div>{finalstate[history.location.state.index].state1[index].deductPoints || 0}</div>
                                                                        <div>&nbsp;</div>
                                                                        <div>Points</div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                value.name === 'Send SMS' &&
                                                                <>
                                                                    {console.log('finalstate[history.location.state.index].state1[index].sendSms ', finalstate[history.location.state.index].state1[index].sendSms)}
                                                                    <div className="d-flex space-between subTextBox">
                                                                        <div className="B14M color-neutral80 underline">
                                                                            <select
                                                                                style={{ backgroundColor: 'inherit' }}
                                                                                className="focusactive border-none underline "
                                                                                value={finalstate[history.location.state.index].state1[index].sendSms}
                                                                                onChange={(e) => {
                                                                                    finalstate[history.location.state.index].state1[index].sendSms = e.target.value
                                                                                    setFinalstate([...finalstate])
                                                                                    // document.getElementsByClassName('for-w1')[0].style.width = (e.target.value.length  * 10 ) + 'px'
                                                                                }}
                                                                            >
                                                                                {
                                                                                    sms && sms.length > 0 && sms.map((aaa, ind) => (
                                                                                        <option value={aaa._id}>{`${aaa.communicationName}`}</option>
                                                                                    ))
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                        <div className="d-flex align-items-center mt_-5">
                                                                            <div className="Body14R pt_2 pb_2 pr_2 color-neutral70">Delay: </div>
                                                                            <div className="Body14M color-neutral70 underline">
                                                                                <select
                                                                                    className="focusactive border-none underline"
                                                                                    onChange={(e) => { finalstate[history.location.state.index].state1[index].delay = e.target.value; setFinalstate([...finalstate]) }}
                                                                                    value={finalstate[history.location.state.index].state1[index].delay}
                                                                                    style={{ backgroundColor: 'inherit' }}
                                                                                >
                                                                                    <option value={"none"}>{"none"}</option>
                                                                                    <option value={"1"}>{"1 Day"}</option>
                                                                                    <option value={"2"}>{"2 Days"}</option>
                                                                                    <option value={"3"}>{"3 Days"}</option>
                                                                                    <option value={"4"}>{"4 Days"}</option>
                                                                                    <option value={"5"}>{"5 Days"}</option>
                                                                                    <option value={"6"}>{"6 Days"}</option>
                                                                                    <option value={"7"}>{"7 Days"}</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            {
                                                                value.name === 'Add Tag' &&
                                                                <div className="d-flex subTextBox align-items-center">
                                                                    <div className=" mainfocusactive d-flex B14M color-neutral80 underline border-none cp"
                                                                        onClick={() => setShow({ modal: true, title: 'Tag', value: finalstate[history.location.state.index].state1[index].addTag || 0, key: 'finalstate[history.location.state.index].state1[index].addTag', onChange: (e) => { finalstate[history.location.state.index].state1[index].addTag = e.target.value; setFinalstate([...finalstate]) } })}
                                                                    >
                                                                        <div>{finalstate[history.location.state.index].state1[index].addTag || 'Enter tag name'}</div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                console.log('finalstate[history.location.state.index].state1[index].bonuspoints', finalstate[history.location.state.index].state1[index])
                                                            }
                                                            {

                                                                value.name === 'Bonus Points' &&
                                                                <div className="d-flex subTextBox align-items-center">
                                                                    <div className="B14M color-neutral70 mr_6 ">Select Bonus Points</div>
                                                                    <div className="Body14M color-neutral70 underline">

                                                                        <select
                                                                            className="focusactive border-none underline"
                                                                            onChange={(e) => { finalstate[history.location.state.index].state1[index].bonuspoints = e.target.value; setFinalstate([...finalstate]) }}
                                                                            value={finalstate[history.location.state.index].state1[index].bonuspoints}
                                                                            style={{ backgroundColor: 'inherit' }}
                                                                        >
                                                                            <option value={rulebuilder_points.DOUBLE_THE_POINTS_X_2}>{rulebuilder_points.DOUBLE_THE_POINTS_X_2}</option>
                                                                            <option value={rulebuilder_points.TRIPLE_THE_POINTS_X_3}>{rulebuilder_points.TRIPLE_THE_POINTS_X_3}</option>
                                                                            <option value={rulebuilder_points.QUADRUPLE_THE_POINTS_X_4}>{rulebuilder_points.QUADRUPLE_THE_POINTS_X_4}</option>

                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                value.name === 'Remove Tag' &&
                                                                <div className="d-flex subTextBox align-items-center ">
                                                                    <div className="cp mainfocusactive d-flex B14M color-neutral80 underline border-none"
                                                                        onClick={() => setShow({ modal: true, title: 'Tag', value: finalstate[history.location.state.index].state1[index].removeTag || 0, key: 'finalstate[history.location.state.index].state1[index].removeTag', onChange: (e) => { finalstate[history.location.state.index].state1[index].removeTag = e.target.value; setFinalstate([...finalstate]) } })}
                                                                    >
                                                                        <div>{finalstate[history.location.state.index].state1[index].removeTag || 'Enter tag name'}</div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className='w-100 mt_24 hpx-48 trigger2' onClick={() => { setAddtrigger1(!addtrigger1); setDropIdx(history.location.state.index) }} varient='tertiary'>Add Action</div>
                                    {addtrigger1 && dropIdx === history.location.state.index && <ClickOutside onClick={(e) => {
                                        setAddtrigger1(false)
                                        e.stopPropagation()
                                    }} >
                                        <div className="p_8 mt_5 dropDownActions w-100 bg-color-white h-fit-content scroll borderRadius-4 ">
                                            {
                                                dropdownAction.map((value, ii) => (
                                                    <div className="d-flex p_6 gap-16 cp hoverclass borderRadius-6 align-items-center" onClick={() => {
                                                        if (value.name === 'Send SMS' && sms && sms.length > 0) {
                                                            if (finalstate.length == history.location.state.index) finalstate.push({ state: [], state1: [{ ...value, sendSms: sms[0]?._id }] })
                                                            else finalstate[history.location.state.index].state1.push({ ...value, sendSms: sms[0]._id })
                                                            setAddtrigger1(false)
                                                            setFinalstate([...finalstate])
                                                        }
                                                        else if (value.name === 'Reward Deal' || value.name === 'Reward') { // && filteredOptions&&filteredOptions.length===1
                                                            if (finalstate.length == history.location.state.index) finalstate.push({ state: [], state1: [{ ...value, rewardDeal: filteredOptions[0]?.value, dealname: filteredOptions[0]?.label, uniqueId: utils.getRandomString(12) }] })
                                                            else finalstate[history.location.state.index].state1.push({ ...value, rewardDeal: filteredOptions[0]?.value, dealname: filteredOptions[0]?.label, uniqueId: utils.getRandomString(12) })
                                                            setAddtrigger1(false)
                                                            setFinalstate([...finalstate])
                                                        }
                                                        else if (value.name === 'Customer Type') {
                                                            if (finalstate.length == history.location.state.index) finalstate.push({ state: [], state1: [{ ...value, customerType: 'loyal' }] })
                                                            else finalstate[history.location.state.index].state1.push({ ...value, customerType: 'loyal' })
                                                            setAddtrigger1(false)
                                                            setFinalstate([...finalstate])
                                                        }
                                                        else {
                                                            setAddtrigger1(false)
                                                            if (finalstate.length == history.location.state.index) finalstate.push({ state: [], state1: [value] })
                                                            else finalstate[history.location.state.index].state1.push(value)
                                                            setFinalstate([...finalstate])
                                                        }

                                                    }}>
                                                        <div className=" d-flex gap-16">
                                                            <img src={value.img} width={20} height={20} />
                                                            <div className="B14M lh-20 color-neutral90">{value.name}</div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </ClickOutside>}
                                    <div className="mt_24">&nbsp;</div>
                                </div>
                            </div>
                        </div>
                        </>
                        // ))
                    }

                    <div className="w-55 mt_40">
                    {((history?.location?.state?.solo?.state[0]?.key==='event'&&history?.location?.state?.solo?.state[0]?.festival!=='Birth Day'&&(history?.location?.state?.solo?.state1[0]?.key==='rewardDeal'||history?.location?.state?.solo?.state1[0]?.key==='addTag'||history?.location?.state?.solo?.state1[0]?.key==="bonusPoints"))||history?.location?.state?.solo?.state[0]?.key==='sharedLoyality')&& 
                    <div className="mb_12 d-flex gap-16 position-relative" >
                        <img className={(finalstate[history.location.state.index].active)?"imgcard":"imgcard"}  src={PngIcons.newCouponBlock} width={68} height={68}
                               onClick={()=>{
                                             console.log('finalstate[history.location.state.index]',finalstate[history.location.state.index].active);
                                             finalstate[history.location.state.index].active=!history?.location?.state?.solo?.active || !finalstate[history.location.state.index].active
                                             setFinalstate([...finalstate])            
                                         }}
                        />
                            <div>
                                <div className="Heading22M color-neutral100">{"Promotion"}{<img style={{left: '43px'}} className="ml_8 position-absolute" src={(finalstate[history.location.state.index].active)?PngIcons.tick:PngIcons.tickG} width={16} height={16}/>}</div>                    
                                <div className="Body14R color-neutral60">{history?.location?.state?.solo?.state[0]?.key==='sharedLoyality'?"Boost loyalty with multiplied points on Gold/Platinum":"Boost loyalty with multiplied points on selected dates"}</div>
                            </div>
                        </div>}
                        {console.log('history.location.state',history.location.state)}
                        {
                        (
                            history?.location?.state?.solo?.state[0]?.key==='point'||
                            history?.location?.state?.solo?.state[0]?.key==='dealStatus'||
                            history?.location?.state?.solo?.state[0]?.key==='oneTimeDeal'||
                            history?.location?.state?.solo?.state[0]?.key==='allCustomer'||
                            (!history.location.state.solo)||history?.location?.state?.solo?.state[0]?.key==='visit')
                        && <div className="mb_12 d-flex gap-16 position-relative" >
                            <img className={(finalstate[history.location.state.index].active)?"imgcard":"imgcard"} 
                            onClick={()=>{
                                console.log('finalstate[history.location.state.index]',finalstate[history.location.state.index].active);
                                finalstate[history.location.state.index].active=!history?.location?.state?.solo?.active || !finalstate[history.location.state.index].active
                                setFinalstate([...finalstate])
                                
                            }} src={PngIcons.newRewardBlock} width={68} height={68}/>
                            <div>
                                <div className="Heading22M color-neutral100">{"Reward"}{<img style={{left: '43px'}} className="ml_8 position-absolute" src={(finalstate[history.location.state.index].active)?PngIcons.tick:PngIcons.tickG} width={16} height={16}/>}</div>
                                <div className="Body14R color-neutral60">{"Offering free product as a reward to your customers is a great way to both give back to your most loyal program members, promote new or featured products and control your inventory."}</div>
                            </div>
                        </div>}
                        {history?.location?.state?.solo?.state[0]?.key==='referral'&& <div className="mb_12 d-flex gap-16 position-relative" >
                            <img className={(finalstate[history.location.state.index].active)?"imgcard":"imgcard"} 
                            onClick={()=>{
                                console.log('finalstate[history.location.state.index]',finalstate[history.location.state.index].active);
                                finalstate[history.location.state.index].active=!history?.location?.state?.solo?.active || !finalstate[history.location.state.index].active
                                setFinalstate([...finalstate])
                                
                            }}src={PngIcons.NewRBlock} width={68} height={68}/>
                            <div>
                                <div className="Heading22M color-neutral100">{"Referral Sender"}{<img style={{left: '43px'}} className="ml_8 position-absolute" src={(finalstate[history.location.state.index].active)?PngIcons.tick:PngIcons.tickG} width={16} height={16}/>}</div>
                                <div className="Body14R color-neutral60">{"Refer a friend and you both earn 10 points when your friend places an order on our website. Use the link below to refer people."}</div>
                            </div>
                        </div>}
                        {history?.location?.state?.solo?.state[0]?.key==='referrallogin'&& <div className="mb_12 d-flex gap-16 position-relative" >
                            <img className={(finalstate[history.location.state.index].active)?"imgcard":"imgcard"} 
                            onClick={()=>{
                                console.log('finalstate[history.location.state.index]',finalstate[history.location.state.index].active);
                                finalstate[history.location.state.index].active=!history?.location?.state?.solo?.active || !finalstate[history.location.state.index].active
                                setFinalstate([...finalstate])
                                
                            }}src={PngIcons.NewRBlock} width={68} height={68}/>
                            <div>
                                <div className="Heading22M color-neutral100">{"Referral Receiver"}{<img style={{left: '43px'}} className="ml_8 position-absolute" src={(finalstate[history.location.state.index].active)?PngIcons.tick:PngIcons.tickG} width={16} height={16}/>}</div>
                                <div className="Body14R color-neutral60">{"Refer a friend and you both earn 10 points when your friend places an order on our website. Use the link below to refer people."}</div>
                            </div>
                        </div>}
                        {history?.location?.state?.solo?.state[0]?.key==='customerInactivity'&& <div className="position-relative mb_12 d-flex gap-16" >
                            <img className={(finalstate[history.location.state.index].active)?"imgcard":"imgcard"} 
                            onClick={()=>{
                                console.log('finalstate[history.location.state.index]',finalstate[history.location.state.index].active);
                                finalstate[history.location.state.index].active=!history?.location?.state?.solo?.active || !finalstate[history.location.state.index].active
                                setFinalstate([...finalstate])
                                
                            }}src={PngIcons.NewIABlock} width={68} height={68}/>
                            <div>
                                <div className="Heading22M color-neutral100">{"Inactivity"}{<img style={{left: '43px'}} className="ml_8 position-absolute" src={(finalstate[history.location.state.index].active)?PngIcons.tick:PngIcons.tickG} width={16} height={16}/>}</div>
                                <div className="Body14R color-neutral60">{"While the text you provided doesn't directly relate to inactivity, we can adapt the concept to fit this context"}</div>
                            </div>
                        </div>}
                        {(history?.location?.state?.solo?.state[0]?.key==='event'&&history?.location?.state?.solo?.state[0]?.festival==='Birth Day')&& <div className="mb_12 position-relative d-flex gap-16" >
                            <img className={(finalstate[history.location.state.index].active)?"imgcard":"imgcard"} 
                            onClick={()=>{
                                console.log('finalstate[history.location.state.index]',finalstate[history.location.state.index].active);
                                finalstate[history.location.state.index].active=!history?.location?.state?.solo?.active || !finalstate[history.location.state.index].active
                                setFinalstate([...finalstate])
                                
                            }}src={PngIcons.newBD} width={68} height={68}/>
                            <div>
                                <div className="Heading22M color-neutral100">{"Birthday"}{<img style={{left: '43px'}} className="ml_8 position-absolute" src={(finalstate[history.location.state.index].active)?PngIcons.tick:PngIcons.tickG} width={16} height={16}/>}</div>
                                <div className="Body14R color-neutral60">{"Delight customers with special points on their special day"}</div>
                            </div>
                        </div>}
                        
                    </div>

                    {history?.location?.state?.solo?.state[0]?.key==='sharedLoyality'&&<div className="d-flex w-55 mt_40">
                        <CustomSelect
                                label={"Shared Loyalty"}
                                className="w-50 pr_5"
                                options={[{name: 'Gold', value: 'gold'},{name: 'Platinum', value: 'platinium'}]}
                                onChange={(e) => { finalstate[history.location.state.index].state[history?.location?.state?.solo?.state.findIndex(item => item.key === "sharedLoyality")].sharedLoyality = e.target.value; setFinalstate([...finalstate]) }}
                                value={finalstate[history.location.state.index].state[history?.location?.state?.solo?.state.findIndex(item => item.key === "sharedLoyality")].sharedLoyality}
                                />
                        <CustomSelect
                                    label={"Point Multiplier"}
                                    options={
                                        [
                                        {
                                            name: rulebuilder_points.DOUBLE_THE_POINTS_X_2,
                                            value: rulebuilder_points.DOUBLE_THE_POINTS_X_2
                                        },
                                        {
                                            name: rulebuilder_points.TRIPLE_THE_POINTS_X_3,
                                            value: rulebuilder_points.TRIPLE_THE_POINTS_X_3
                                        },
                                        {
                                            name: rulebuilder_points.QUADRUPLE_THE_POINTS_X_4,
                                            value: rulebuilder_points.QUADRUPLE_THE_POINTS_X_4
                                        },
                                        ]
                                    }
                                    className="w-50 pr_5"
                                    onChange={(e) => { finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "bonusPoints")].bonuspoints = e.target.value; setFinalstate([...finalstate]) }}
                                    value={finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "bonusPoints")].bonuspoints}
                                /></div>}

                    <div className={
                        (history?.location?.state?.solo?.state[0]?.festival==='Birth Day')||history?.location?.state?.solo?.state[0]?.key==='customerInactivity'?
                        "w-55 ":
                        "w-55 mt_40"
                    }>
                    {(
                        history?.location?.state?.solo?.state[0]?.key==='point'||
                        history?.location?.state?.solo?.state[0]?.key==='dealStatus'||
                        history?.location?.state?.solo?.state[0]?.key==='oneTimeDeal'||
                        history?.location?.state?.solo?.state[0]?.key==='allCustomer'||
                    (!history.location.state.solo)||history?.location?.state?.solo?.state[0]?.key==='visit')&&
                    <div className={`${(finalstate[history.location.state.index].active===false)&&' disabled '}`}>
                    <div>
                    <FormControl 
                            className={
                                // (history?.location?.state?.solo?.state[0]?.key==='event'&&history?.location?.state?.solo?.state[0]?.festival!=='Birth Day')?
                                // "d-flex mb_20 disabled":
                                "d-flex mb_20"
                            }
                            >
                            <RadioGroup 
                                name     = "usedFor"
                                className="d-flex" style={{flexFlow: 'row'}}
                                value    = {pts}
                                onChange = {(e)=>{
                                    if(e.target.value === 'Loyalty'){
                                        console.log('finalstate1',finalstate[history.location.state.index].state[0].visits);
                                        finalstate[history.location.state.index].state[0] = {
                                            "name": "Points",
                                            "key": "point",
                                            "img": "/images/rewardBuilder/points.png",
                                            "description": "It triggers when a specific amount of points are achieved.",
                                            "condition": "equal",
                                            "points": ""
                                        }       
                                        
                                        if(history?.location?.state?.solo?.state1.findIndex(item => item?.key === "addTag")=== -1){
                                            let indexToRemove = history.location.state.index;
                                            let state1Array = finalstate[indexToRemove].state1;
        
                                            // Find the index of the element with key "givePoint"
                                            let elementIndex = state1Array.findIndex(item => item.key === "givePoint");
        
                                            // Remove the element at the found index
                                            if (elementIndex !== -1) {
                                                state1Array.splice(elementIndex, 1);
                                            }
        
                                            // If you need to update the finalstate object
                                            finalstate[indexToRemove].state1 = state1Array;
        
                                            console.log(finalstate[indexToRemove].state1);
        
                                            console.log("asddsa",history?.location?.state?.solo?.state1);
                                            console.log("asddsa",history?.location?.state?.solo?.state1.findIndex(item => item?.key === "givePoint"));
        
        
        
                                            if(history?.location?.state?.solo?.state1.findIndex(item => item?.key === "rewardDeal")=== -1){
                                                finalstate[history.location.state.index].state1.push({
                                                    "name": "Reward",
                                                    "key": "rewardDeal",
                                                    "img": "/images/rewardBuilder/rewardDeal.png",
                                                    "description": "Description",
                                                    "rewardDeal": "",
                                                    "dealExpiration":"0",
                                                    "dealname": "",
                                                    "uniqueId": ""
                                                })
                                            }
                                        }


                                        if(history?.location?.state?.solo?.state1.findIndex(item => item?.key === "addTag")=== -1){
                                            finalstate[history.location.state.index].state1.push({
                                                "name": "Add Tag",
                                                "key": "addTag",
                                                "img": "/images/rewardBuilder/addTag.png",
                                                "description": "Description",
                                                "addTag": "tag"
                                            })
                                        }
                                    }
                                    if(e.target.value === 'Coupon'){
                                        setType('Visit')
                                        console.log('finalstate1',finalstate[history.location.state.index].state[0].visits);
                                        finalstate[history.location.state.index].state[0] = {
                                            "name": "Visits",
                                            "key": "visit",
                                            "img": "/images/rewardBuilder/visits.png",
                                            "description": "It triggers when a customer has a certain amount of visits to your brand.",
                                            "condition": "equal",
                                            "visits": ""
                                        }                                      
                                    }         
                                                               
                                    
                                    setPts(e.target.value)}}
                            >
                                <CustomRadio 
                                    size="large"
                                    value = {'Loyalty'}
                                    label = {<div className="Body14R color-neutral100">Loyalty</div>}
                                    />
                                <CustomRadio 
                                    size="large"
                                    value={'Coupon'}
                                    label = {<div className="Body14R color-neutral100">Coupon</div>}
                                />
                            </RadioGroup>
                            <FormHelperText>
                                {/* {formik.touched.usedFor && formik.errors.usedFor ? formik.errors.usedFor : ''} */}
                            </FormHelperText>
                        </FormControl>
                    </div>
                    {console.log('history?.location?.state?.solo?.state',history?.location?.state?.solo?.state)}
                    
                    {pts==='Coupon'&&
                    <>
                    <div className="d-flex gap-10 mb_20">

                            {<CustomSelect
                                label={"Coupon Type"}
                                className={history?.location?.state?.solo?.state[0]?.key==='oneTimeDeal'&&'w-50'}
                                options={
                                    [
                                    {
                                        name: 'Visit-Based: Given on each customer visit.',
                                        value: 'Visit'
                                    },
                                    {
                                        name: 'Redeem & Get New: Receive another coupon upon redemption.',
                                        value: 'Deal Redeemed'
                                    },
                                    {
                                        name: 'One-Time Use: Can only be used once. in drop down',
                                        value: 'One Time'
                                    },
                                    ]
                                }
                                onChange={(e) => { 
                                    if(e.target.value === 'One Time'){
                                        setPts1('Coupon')
                                        finalstate[history.location.state.index].state[0] = {
                                            "name": "One Time Deal",
                                            "key": "oneTimeDeal",
                                            "img": "/images/rewardBuilder/oneTimeDeal.png",
                                            "description": "It will trigger deal will be rewarded to customer only one time."
                                        }
                                    }
                                    if(e.target.value === 'Deal Redeemed'){
                                        setPts1('Coupon')
                                        console.log('finalstate1',finalstate[history.location.state.index].state[0].visits);
                                        finalstate[history.location.state.index].state[0] = {
                                            "name": "Reward Status",
                                            "key": "dealStatus",
                                            "img": "/images/rewardBuilder/dealStatus.png",
                                            "description": "It triggers when there is a change in the status of a Deal, i.e. (Assigned, Redeemed, Expired)",
                                            "deal": "",
                                            "dealname": "",
                                            "status": "Redeemed"
                                        }  
                                        
                                        if(history?.location?.state?.solo?.state1.findIndex(item => item?.key === "addTag")=== -1){
                                            finalstate[history.location.state.index].state1.push({
                                                "name": "Add Tag",
                                                "key": "addTag",
                                                "img": "/images/rewardBuilder/addTag.png",
                                                "description": "Description",
                                                "addTag": "tag"
                                            })
                                        }

                                        // if(history?.location?.state?.solo?.state1.findIndex(item => item?.key === "givePoint")=== -1){
                                        //     finalstate[history.location.state.index].state1.push({
                                        //         "name": "Give Points",
                                        //         "key": "givePoint",
                                        //         "img": "/images/rewardBuilder/addPoints.png",
                                        //         "description": "Description",
                                        //         "givePoints": ""
                                        //     })
                                        // }
                                    }
                                    if(e.target.value === 'Visit'){

                                        if(history?.location?.state?.solo?.state1.findIndex(item => item?.key === "addTag")=== -1){
                                            finalstate[history.location.state.index].state1.push({
                                                "name": "Add Tag",
                                                "key": "addTag",
                                                "img": "/images/rewardBuilder/addTag.png",
                                                "description": "Description",
                                                "addTag": "tag"
                                            })
                                        }
                                        console.log('finalstate1',finalstate[history.location.state.index].state[0].visits);
                                        finalstate[history.location.state.index].state[0] = {
                                            "name": "Visits",
                                            "key": "visit",
                                            "img": "/images/rewardBuilder/visits.png",
                                            "description": "It triggers when a customer has a certain amount of visits to your brand.",
                                            "condition": "equal",
                                            "visits": ""
                                        }  
                                        
                                        if(history?.location?.state?.solo?.state1.findIndex(item => item?.key === "givePoint") >= 0){
                                            let indexToRemove = history.location.state.index;
                                    let state1Array = finalstate[indexToRemove].state1;

                                    // Find the index of the element with key "givePoint"
                                    let elementIndex = state1Array.findIndex(item => item.key === "givePoint");

                                    // Remove the element at the found index
                                    if (elementIndex !== -1) {
                                        state1Array.splice(elementIndex, 1);
                                    }

                                    // If you need to update the finalstate object
                                    finalstate[indexToRemove].state1 = state1Array;

                                    console.log(finalstate[indexToRemove].state1);

                                    console.log("asddsa",history?.location?.state?.solo?.state1);
                                    console.log("asddsa",history?.location?.state?.solo?.state1.findIndex(item => item?.key === "givePoint"));



                                    if(history?.location?.state?.solo?.state1.findIndex(item => item?.key === "rewardDeal")=== -1){
                                        finalstate[history.location.state.index].state1.push({
                                            "name": "Reward",
                                            "key": "rewardDeal",
                                            "img": "/images/rewardBuilder/rewardDeal.png",
                                            "description": "Description",
                                            "rewardDeal": "",
                                            "dealExpiration":"0",
                                            "dealname": "",
                                            "uniqueId": ""
                                        })
                                    }
                                        }
                                    }
                                    setType(e.target.value) }}
                                value={type}
                            />}

                            {
                            history?.location?.state?.solo?.state[0]?.key==='oneTimeDeal'&&
                            history?.location?.state?.solo?.state[0]?.key==='allCustomer'&&
                            <div className={(pts==='Loyalty')||(pts==='Coupon'&&type==="Deal Redeemed"&&pts1==='Coupon')
                                ?'w-50':'w-100'}>
                                <CustomSelect
                            label={"Coupon Expiry"}
                            className={(pts==='Loyalty')||(pts==='Coupon'&&type==="Deal Redeemed"&&pts1==='Coupon')?'pr_5':''}
                            options={
                                [
                                {
                                    name: 'None',
                                    value: '0'
                                },
                                {
                                    name: '30 Days',
                                    value: '30'
                                },
                                {
                                    name: '60 Days',
                                    value: '60'
                                },
                                {
                                    name: '90 Days',
                                    value: '90'
                                }
                                ]
                            }
                            onChange={(e) => { finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "rewardDeal")].dealExpiration = e.target.value; setFinalstate([...finalstate]) }}
                            value={finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "rewardDeal")].dealExpiration}
                                />

                            </div>}
                            
                            {
                            history?.location?.state?.solo?.state[0]?.key!=='oneTimeDeal'&&
                            history?.location?.state?.solo?.state[0]?.key!=='allCustomer'&&
                            <CustomTextField
                                label={"Tag"}
                                placeholder={"Tag"}
                                // value= {finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "addTag")].addTag || 0} 
                                value={finalstate[history.location.state.index]?.state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "addTag")]?.addTag ?? ""}
                                onChange= {(e) => { finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "addTag")].addTag = e.target.value; setFinalstate([...finalstate]) }}
                            />}

                    </div>
                    {/* <div className="d-flex gap-10 mb_20"> */}
                    
                    {/* {type==='Visit'&&<CustomSelect
                                label={"Condition"}
                                options={
                                    [
                                    {
                                        name: 'Equal',
                                        value: 'equal'
                                    },
                                    {
                                        name: 'Less Than',
                                        value: 'lessThan'
                                    },
                                    {
                                        name: 'More Than',
                                        value: 'moreThan'
                                    },
                                    {
                                        name: 'Less Than Equal',
                                        value: 'lessThanEqual'
                                    },
                                    {
                                        name: 'More Than Equal',
                                        value: 'moreThanEqual'
                                    }
                                    ]
                                }
                                onChange={(e) => { finalstate[history.location.state.index].state[0].condition = e.target.value; setFinalstate([...finalstate]) }}
                                value={finalstate[history.location.state.index].state[0].condition}
                            />} */}
                            
                    {/* </div> */}
                    {(pts==='Coupon'? (type==='Deal Redeemed') : 2===2) &&filteredOptions && filteredOptions.length > 0 &&
                        <>
                        {/* <div className="bar"></div> */}
                        <div className="mt_20 mb_10 Link16M" style={{color:"#141519"}}>{type==='Deal Redeemed'?'Choose Redeemed Product':'Choose Product'}</div>
                        <div style={{ 
                        display: 'grid', 
                        // gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', 
                        gridTemplateColumns: 'repeat(2, 1fr)', 
                        gap: '16px' 
                        }}>
                        {filteredOptions.map((value, index) => (
                            <Card key={index} style={{
                            backgroundColor: filterAudience1[0] === value.value && '#F2F6FF',
                            border: filterAudience1[0] === value.value && '2px solid #2960EC'
                            }} sx={{ height: '110px' }} onClick={() => {
                            // }} sx={{ height: 'fit-content' }} onClick={() => {
                            // Handle click
                            setFilterAudience1([value.value])
                            finalstate[history.location.state.index].state[0].deal = value.value;
                            finalstate[history.location.state.index].state[0].dealname = value.label;
                            setFinalstate([...finalstate])
                            }}>
                            {console.log('value',value)}
                            <CardActionArea style={{ display: 'flex', alignItems: 'flex-start' }}>
                                <CardContent style={{ width: '100%' }}>
                                {
                                filterAudience1[0] === value.value?
                                    <div className="d-flex gap-6">
                                    <Typography gutterBottom style={{fontSize: '15px', color: '#141519', fontWeight: 500, fontFamily: 'Inter'}} variant="h5" component="div" sx={{fontWeight:'bold'}}>
                                    {utils.capitalize(value.data.name)}
                                    </Typography>
                                    <img src={PngIcons.tick} width={16} height={16}/>
                                </div>
                                    :
                                    <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 'bold', fontFamily: 'Inter' }}>
                                    {utils.capitalize(value.data.name)}
                                </Typography>
                                }
                                <Typography variant="h6" color="text.secondary" sx={{
                                    overflow: 'hidden', fontFamily: 'Inter',
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 2, // Limits to 2 lines
                                    textOverflow: 'ellipsis',
                                    opacity: value.data.description===''&&0.5
                                }}>
                                    {
                                        value.data.description===''?
                                        'Write a description here! Share details that make your product unique, from key features to the experience it offers customers.':
                                    utils.capitalize(value.data.description)}
                                </Typography>
                                </CardContent>
                                <CardMedia
                                component="img"
                                // height="140"
                                height="110"
                                // style={{ padding: 12, width: '140px' }}
                                style={{ padding: 12, width: '110px' }}
                                image={value.data.image}
                                alt={value.data.name}
                                />
                            </CardActionArea>
                            </Card>
                        ))}
                        </div>
                        </>}
                        </>
                        }
                    {pts==='Loyalty'&&<div className="d-flex gap-10 mb_20">
                            {/* <CustomSelect
                                label={"Condition"}
                                options={
                                    [
                                    {
                                        name: 'Equal',
                                        value: 'equal'
                                    },
                                    {
                                        name: 'Less Than',
                                        value: 'lessThan'
                                    },
                                    {
                                        name: 'More Than',
                                        value: 'moreThan'
                                    },
                                    {
                                        name: 'Less Than Equal',
                                        value: 'lessThanEqual'
                                    },
                                    {
                                        name: 'More Than Equal',
                                        value: 'moreThanEqual'
                                    }
                                    ]
                                }
                                onChange={(e) => { finalstate[history.location.state.index].state[0].condition = e.target.value; setFinalstate([...finalstate]) }}
                                value={finalstate[history.location.state.index].state[0].condition}
                            /> */}
                            <CustomTextField
                                label={"Points"}
                                // type="number"
                                placeholder={"Points"}
                                onChange={(e) => { finalstate[history.location.state.index].state[0].points = e.target.value; setFinalstate([...finalstate]) }}
                                value={finalstate[history.location.state.index].state[0].points}
                            />
                            {console.log('finalstate[history.location.state.index].state11',finalstate[history.location.state.index].state1)}
                            <CustomTextField
                                label={"Tag"}
                                placeholder={"Tag"}
                                value={finalstate[history.location.state.index]?.state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "addTag")]?.addTag ?? ""}
                                onChange= {(e) => { finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "addTag")].addTag = e.target.value; setFinalstate([...finalstate]) }}
                                  
                                // value= {finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "addTag")].addTag || 0} 
                            />
                        </div>}
                    {pts==='Coupon'&&type==='Deal Redeemed'&&<FormControl 
                        // error = {formik.touched.usedFor && formik.errors.usedFor}
                        className="d-flex mt_20"
                        >
                        <RadioGroup 
                            name     = "usedFor"
                            className="d-flex" style={{flexFlow: 'row'}}
                            value    = {pts1}
                            onChange = {(e)=>{
                                if(e.target.value === 'Coupon'){

                                    // delete finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "givePoint")]
                                    // Assuming finalstate and history are defined as mentioned
                                    let indexToRemove = history.location.state.index;
                                    let state1Array = finalstate[indexToRemove].state1;

                                    // Find the index of the element with key "givePoint"
                                    let elementIndex = state1Array.findIndex(item => item.key === "givePoint");

                                    // Remove the element at the found index
                                    if (elementIndex !== -1) {
                                        state1Array.splice(elementIndex, 1);
                                    }

                                    // If you need to update the finalstate object
                                    finalstate[indexToRemove].state1 = state1Array;

                                    console.log(finalstate[indexToRemove].state1);

                                    console.log("asddsa",history?.location?.state?.solo?.state1);
                                    console.log("asddsa",history?.location?.state?.solo?.state1.findIndex(item => item?.key === "givePoint"));



                                    if(history?.location?.state?.solo?.state1.findIndex(item => item?.key === "rewardDeal")=== -1){
                                        finalstate[history.location.state.index].state1.push({
                                            "name": "Reward",
                                            "key": "rewardDeal",
                                            "img": "/images/rewardBuilder/rewardDeal.png",
                                            "description": "Description",
                                            "rewardDeal": "",
                                            "dealExpiration":"0",
                                            "dealname": "",
                                            "uniqueId": ""
                                        })
                                    }
                                }
                                if(e.target.value === 'Loyalty'){
                                    // delete finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "givePoint")]
                                    // Assuming finalstate and history are defined as mentioned
                                    let indexToRemove = history.location.state.index;
                                    let state1Array = finalstate[indexToRemove].state1;

                                    // Find the index of the element with key "givePoint"
                                    let elementIndex = state1Array.findIndex(item => item.key === "rewardDeal");

                                    // Remove the element at the found index
                                    if (elementIndex !== -1) {
                                        state1Array.splice(elementIndex, 1);
                                    }

                                    // If you need to update the finalstate object
                                    finalstate[indexToRemove].state1 = state1Array;

                                    console.log(finalstate[indexToRemove].state1);

                                    console.log("asddsa",history?.location?.state?.solo?.state1);
                                    console.log("asddsa",history?.location?.state?.solo?.state1.findIndex(item => item?.key === "rewardDeal"));


                                    if(history?.location?.state?.solo?.state1.findIndex(item => item?.key === "givePoint")=== -1){
                                        finalstate[history.location.state.index].state1.push({
                                            "name": "Give Points",
                                            "key": "givePoint",
                                            "img": "/images/rewardBuilder/addPoints.png",
                                            "description": "Description",
                                            "givePoints": ""
                                        })
                                    }

                                }
                                // delete finalstate[history.location.state.index].state1[0].givePoints
                                setFilterAudience([])
                                setPts1(e.target.value)}}
                            // onFocus  = {formik.handleBlur}
                        >
                            <CustomRadio 
                                size="large"
                                value={'Loyalty'}
                                label = {<div className="Body14R color-neutral100">Loyalty</div>}
                            />
                            <CustomRadio 
                                size="large"
                                value = {'Coupon'}
                                label = {<div className="Body14R color-neutral100">Coupon</div>}
                                />
                        </RadioGroup>
                        <FormHelperText>
                            {/* {formik.touched.usedFor && formik.errors.usedFor ? formik.errors.usedFor : ''} */}
                        </FormHelperText>
                        </FormControl>}

                        {pts1!=='Loyalty'&&history?.location?.state?.solo?.state[0]?.key!=='oneTimeDeal'&&history?.location?.state?.solo?.state[0]?.key!=='allCustomer'&&<div className="d-flex gap-10">
                            <div className={(pts==='Loyalty')||(pts==='Coupon'&&type==="Deal Redeemed"&&pts1==='Coupon')
                                ?'w-50':'w-100'}>
                                <CustomSelect
                            label={"Coupon Expiry"}
                            className={(pts==='Loyalty')||(pts==='Coupon'&&type==="Deal Redeemed"&&pts1==='Coupon')?'pr_5':''}
                            options={
                                [
                                {
                                    name: 'None',
                                    value: '0'
                                },
                                {
                                    name: '30 Days',
                                    value: '30'
                                },
                                {
                                    name: '60 Days',
                                    value: '60'
                                },
                                {
                                    name: '90 Days',
                                    value: '90'
                                }
                                ]
                            }
                            onChange={(e) => { finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "rewardDeal")].dealExpiration = e.target.value; setFinalstate([...finalstate]) }}
                            value={finalstate[history.location.state.index]?.state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "rewardDeal")]?.dealExpiration ?? ""}
                              
                            // value={finalstate[history.location.state.index]?.state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "rewardDeal")].dealExpiration}
                                />

                            </div>
                            {type==='Visit'&&pts==='Coupon'&&<CustomTextField
                                label={"Visits"}
                                // type="number"
                                placeholder={"Visits"}
                                onChange={(e) => { finalstate[history.location.state.index].state[0].visits = e.target.value; setFinalstate([...finalstate]) }}
                                value={finalstate[history.location.state.index].state[0].visits}
                            />}
                            </div>}

                        {pts==='Coupon'&&pts1==='Loyalty'&&type==='Deal Redeemed'&&<CustomTextField
                                label={"Points"}
                                type="number"
                                placeholder={"Points"}
                                className='w-50'
                                value= {finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "givePoint")].givePoints || 0}
                                onChange= {(e) => { 
                                    console.log('history?.location?.state?.solo?.state[0]',history?.location?.state?.solo?.state1);
                                    finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "givePoint")].givePoints = e.target.value; setFinalstate([...finalstate]) }}
                            />}
                        {((pts=='Coupon'&&type==='Deal Redeemed')?(pts1 !== 'Loyalty'):2===2) && filteredOptions && filteredOptions?.length > 0 &&
                                <>

                                <div className="mt_20 mb_10 Link16M" style={{color:"#141519"}}>{pts==='Coupon'&&type==='Deal Redeemed'?'Choose Reward Product':'Choose Product'}</div>
                                <div style={{ 
                                display: 'grid', 
                                // gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', 
                                gridTemplateColumns: 'repeat(2, 1fr)', 
                                gap: '16px' 
                                }}>
                                {filteredOptions.map((value, index) => (
                                    <Card key={index} style={{
                                    backgroundColor: filterAudience[0] === value.value && '#F2F6FF',
                                    border: filterAudience[0] === value.value && '2px solid #2960EC'
                                    // }} sx={{ height: 'fit-content' }} onClick={() => {
                                    }} sx={{ height: '110px' }} onClick={() => {
                                    // Handle click
                                    setFilterAudience([value.value])
                                    finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item?.key === "rewardDeal")].rewardDeal = value.value;
                                    finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item?.key === "rewardDeal")].dealname = value.label;
                                    setFinalstate([...finalstate])
                                    }}>
                                    {console.log('value',value)}
                                    <CardActionArea style={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <CardContent style={{ width: '100%' }}>
                                        {
                                        filterAudience[0] === value.value?
                                            <div className="d-flex gap-6">
                                            <Typography gutterBottom style={{fontSize: '15px', color: '#141519', fontWeight: 500, fontFamily: 'Inter'}} variant="h5" component="div" sx={{fontWeight:'bold'}}>
                                            {utils.capitalize(value.data.name)}
                                            </Typography>
                                            <img src={PngIcons.tick} width={16} height={16}/>
                                        </div>
                                            :
                                            <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 'bold', fontFamily: 'Inter' }}>
                                            {utils.capitalize(value.data.name)}
                                        </Typography>
                                        }
                                        <Typography variant="h6" color="text.secondary" sx={{
                                            overflow: 'hidden', fontFamily: 'Inter',
                                            display: '-webkit-box',
                                            WebkitBoxOrient: 'vertical',
                                            WebkitLineClamp: 2, // Limits to 2 lines
                                            textOverflow: 'ellipsis',
                                            opacity: value.data.description===''&&0.5
                                        }}>
                                            {/* {utils.capitalize(value.data.description)} */}
                                            {
                                        value.data.description===''?
                                        'Write a description here! Share details that make your product unique, from key features to the experience it offers customers.':
                                    utils.capitalize(value.data.description)}
                                        </Typography>
                                        </CardContent>
                                        <CardMedia
                                        component="img"
                                        // height="140"
                                        height="110"
                                        // style={{ padding: 12, width: '140px' }}
                                        style={{ padding: 12, width: '110px' }}
                                        image={value.data.image}
                                        alt={value.data.name}
                                        />
                                    </CardActionArea>
                                    </Card>
                                ))}

                                </div>
                                </>}
                    </div>

                        }
                    {((history?.location?.state?.solo?.state[0]?.key==='event'&&(history?.location?.state?.solo?.state1[0]?.key==='rewardDeal'||history?.location?.state?.solo?.state1[0]?.key==='addTag'||history?.location?.state?.solo?.state1[0]?.key==="bonusPoints")))&&history?.location?.state?.solo?.state[0]?.festival!=='Birth Day'&&
                    // history?.location?.state?.solo?.state[0]?.key!=='customerInactivity'&&
                    <div className={`${(finalstate[history.location.state.index].active===false)&&' disabled '}`}>
                    {/* <FormControl 
                            // error = {formik.touched.usedFor && formik.errors.usedFor}
                            className={
                                (history?.location?.state?.solo?.state[0]?.key==='event'&&history?.location?.state?.solo?.state[0]?.festival!=='Birth Day')?
                                "d-flex disabled":
                                "d-flex"}
                            >
                            <RadioGroup 
                                name     = "usedFor"
                                className="d-flex" style={{flexFlow: 'row'}}
                                value    = {pts}
                                onChange = {(e)=>{
                                    if(e.target.value === 'Coupon'){
                                        // delete finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "givePoint")]
                                        // Assuming finalstate and history are defined as mentioned
                                        let indexToRemove = history.location.state.index;
                                        let state1Array = finalstate[indexToRemove].state1;

                                        // Find the index of the element with key "givePoint"
                                        let elementIndex = state1Array.findIndex(item => item.key === "bonusPoints");

                                        // Remove the element at the found index
                                        if (elementIndex !== -1) {
                                            state1Array.splice(elementIndex, 1);
                                        }

                                        // If you need to update the finalstate object
                                        finalstate[indexToRemove].state1 = state1Array;

                                        console.log(finalstate[indexToRemove].state1);

                                        console.log("asddsa",history?.location?.state?.solo?.state1);
                                        console.log("asddsa",history?.location?.state?.solo?.state1.findIndex(item => item?.key === "bonusPoints"));



                                        if(history?.location?.state?.solo?.state1.findIndex(item => item?.key === "rewardDeal")=== -1){
                                            finalstate[history.location.state.index].state1.push({
                                                "name": "Reward",
                                                "key": "rewardDeal",
                                                "img": "/images/rewardBuilder/rewardDeal.png",
                                                "description": "Description",
                                                "rewardDeal": "",
                                                "dealExpiration":"0",
                                                "dealname": "",
                                                "uniqueId": ""
                                            })
                                        }
                                    }
                                    if(e.target.value === 'Loyalty'){
                                        // delete finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "givePoint")]
                                        // Assuming finalstate and history are defined as mentioned
                                        let indexToRemove = history.location.state.index;
                                        let state1Array = finalstate[indexToRemove].state1;

                                        // Find the index of the element with key "givePoint"
                                        let elementIndex = state1Array.findIndex(item => item.key === "rewardDeal");

                                        // Remove the element at the found index
                                        if (elementIndex !== -1) {
                                            state1Array.splice(elementIndex, 1);
                                        }

                                        // If you need to update the finalstate object
                                        finalstate[indexToRemove].state1 = state1Array;

                                        console.log(finalstate[indexToRemove].state1);

                                        console.log("asddsa",history?.location?.state?.solo?.state1);
                                        console.log("asddsa",history?.location?.state?.solo?.state1.findIndex(item => item?.key === "rewardDeal"));


                                        if(history?.location?.state?.solo?.state1.findIndex(item => item?.key === "bonusPoints")=== -1){
                                            finalstate[history.location.state.index].state1.push(
                                                {
                                                    "name": "Bonus Points",
                                                    "key": "bonusPoints",
                                                    "img": "/images/favicon/doublepoints.png",
                                                    "description": "Description",
                                                    "bonuspoints": "Double the points x 2"
                                                }
                                            )
                                        }

                                    }
                                    // delete finalstate[history.location.state.index].state1[0].givePoints
                                    setFilterAudience([])
                                    setPts(e.target.value)}}
                                // onFocus  = {formik.handleBlur}
                            >
                                <CustomRadio 
                                    size="large"
                                    value = {'Coupon'}
                                    label = {<div className="Body14R color-neutral100">Coupon</div>}
                                    />
                                <CustomRadio 
                                    size="large"
                                    value={'Loyalty'}
                                    label = {<div className="Body14R color-neutral100">Loyalty</div>}
                                />
                            </RadioGroup>
                            <FormHelperText>
                            </FormHelperText>
                        </FormControl> */}
                    <div className="d-flex gap-10">
                        {console.log('finalstate[history.location.state.index].state[0].festival',finalstate[history.location.state.index].state[0].festival)}
                        
                    <CustomSelect
                        label={"Condition"}
                        options={
                            [
                            {
                                name: 'Custom Date',
                                value: 'Custom Date'
                            },
                            {
                                name: 'Week',
                                value: 'Week'
                            }
                            ]
                        }
                        onChange={(e) => { 
                            finalstate[history.location.state.index].state[0].festival = e.target.value; setFinalstate([...finalstate]) 
                        }}
                        value={finalstate[history.location.state.index].state[0].festival}
                    />
                        
                    
                    



                                {finalstate[history.location.state.index].state[0].festival === 'Custom Date' ?
                         <>
                            <div className='underline color-neutral90 B14M w-100'>
                            <div className=" mainfocusactive w-100 wpx-230 cp h-fit-content B14M color-neutral80 underline rpInput">
                            <DateRangePicker
                                initialSettings={initialsDates(0)}
                                onCallback={(start, end, label) => {
                                    console.log('start, end', start._d, end._d)
                                    finalstate[history.location.state.index].state[0].eventStart = new Date(start._d).valueOf();
                                    finalstate[history.location.state.index].state[0].eventEnd = new Date(end._d).valueOf();
                                    setFinalstate([...finalstate])
                                }
                                }
                                >
                                <input type="text" className="cp form-control color-neutral80 underline shadow-none border-none w-100 " style={{ fontSize: '14px', fontWeight: '500' }} />
                            </DateRangePicker>
                            </div>
                            </div>
                         </>
                         : finalstate[history.location.state.index].state[0].festival === 'Week' ? <>
                         <CustomSelect
                                label={"Day"}
                                options={
                                    [
                                    {
                                        name: 'Monday',
                                        value: 1
                                    },
                                    {
                                        name: 'Tuesday',
                                        value: 2
                                    },
                                    {
                                        name: 'Wednesday',
                                        value: 3
                                    },
                                    {
                                        name: 'Thursday',
                                        value: 4
                                    },
                                    {
                                        name: 'Friday',
                                        value: 5
                                    },
                                    {
                                        name: 'Saturday',
                                        value: 6
                                    },
                                    {
                                        name: 'Sunday',
                                        value: 7
                                    },
                                    ]
                                }
                                onChange={(e) => { finalstate[history.location.state.index].state[0].week = e.target.value; setFinalstate([...finalstate]) }}
                                value={finalstate[history.location.state.index].state[0].week}
                            />
                         </> : <></>}

                    </div>
                         {pts==='Loyalty'&&<div className="mt_20 ">

                             <CustomSelect
                                 label={"Point Multiplier"}
                                 options={
                                     [
                                     {
                                         name: rulebuilder_points.DOUBLE_THE_POINTS_X_2,
                                         value: rulebuilder_points.DOUBLE_THE_POINTS_X_2
                                     },
                                     {
                                         name: rulebuilder_points.TRIPLE_THE_POINTS_X_3,
                                         value: rulebuilder_points.TRIPLE_THE_POINTS_X_3
                                     },
                                     {
                                         name: rulebuilder_points.QUADRUPLE_THE_POINTS_X_4,
                                         value: rulebuilder_points.QUADRUPLE_THE_POINTS_X_4
                                     },
                                     ]
                                 }
                                 onChange={(e) => { finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "bonusPoints")].bonuspoints = e.target.value; setFinalstate([...finalstate]) }}
                                 className="w-50 pr_5"
                                 value={finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "bonusPoints")].bonuspoints}
                             />
                         </div>
                    }

                    {pts==='Coupon'&&
                        <div className="mt_20">
                            <CustomSelect
                                label={"Coupon Expiry"}
                                className="w-50 pr_5"
                                options={
                                    [
                                    {
                                        name: 'None',
                                        value: '0'
                                    },
                                    {
                                        name: '30 Days',
                                        value: '30'
                                    },
                                    {
                                        name: '60 Days',
                                        value: '60'
                                    },
                                    {
                                        name: '90 Days',
                                        value: '90'
                                    }
                                    ]
                                }
                                onChange={(e) => { finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "rewardDeal")].dealExpiration = e.target.value; setFinalstate([...finalstate]) }}
                                value={finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "rewardDeal")].dealExpiration}
                            />
                        </div>
                    }
                    {pts==='Coupon'&&filteredOptions && filteredOptions.length > 0 &&
                                <>
                                {/* <div className="bar"></div> */}
                                <div className="mt_20 mb_10 Link16M" style={{color:"#141519"}}>Choose Product</div>
                                <div style={{ 
                                display: 'grid', 
                                // gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', 
                                gridTemplateColumns: 'repeat(2, 1fr)', 
                                gap: '16px' 
                                }}>
                                {filteredOptions.map((value, index) => (
                                    <Card key={index} style={{
                                    backgroundColor: filterAudience[0] === value.value && '#F2F6FF',
                                    border: filterAudience[0] === value.value && '2px solid #2960EC'
                                    // }} sx={{ height: 'fit-content' }} onClick={() => {
                                    }} sx={{ height: '110px' }} onClick={() => {
                                    // Handle click
                                    setFilterAudience([value.value])
                                    finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item?.key === "rewardDeal")].rewardDeal = value.value;
                                    finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item?.key === "rewardDeal")].dealname = value.label;
                                    setFinalstate([...finalstate])
                                    }}>
                                    {console.log('value',value)}
                                    <CardActionArea style={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <CardContent style={{ width: '100%' }}>
                                        {
                                        filterAudience[0] === value.value?
                                            <div className="d-flex gap-6">
                                            <Typography gutterBottom style={{fontSize: '15px', color: '#141519', fontWeight: 500, fontFamily: 'Inter'}} variant="h5" component="div" sx={{fontWeight:'bold'}}>
                                            {utils.capitalize(value.data.name)}
                                            </Typography>
                                            <img src={PngIcons.tick} width={16} height={16}/>
                                        </div>
                                            :
                                            <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 'bold', fontFamily: 'Inter' }}>
                                            {utils.capitalize(value.data.name)}
                                        </Typography>
                                        }
                                        <Typography variant="h6" color="text.secondary" sx={{
                                            overflow: 'hidden', fontFamily: 'Inter',
                                            display: '-webkit-box',
                                            WebkitBoxOrient: 'vertical',
                                            WebkitLineClamp: 2, // Limits to 2 lines
                                            textOverflow: 'ellipsis',
                                            opacity: value.data.description===''&&0.5
                                        }}>
                                            {/* {utils.capitalize(value.data.description)} */}
                                            {
                                        value.data.description===''?
                                        'Write a description here! Share details that make your product unique, from key features to the experience it offers customers.':
                                    utils.capitalize(value.data.description)}
                                        </Typography>
                                        </CardContent>
                                        <CardMedia
                                        component="img"
                                        // height="140"
                                        height="110"
                                        // style={{ padding: 12, width: '140px' }}
                                        style={{ padding: 12, width: '110px' }}
                                        image={value.data.image}
                                        alt={value.data.name}
                                        />
                                    </CardActionArea>
                                    </Card>
                                ))}
                                </div>
                                </>}
                    </div>
                    }
                    {history?.location?.state?.solo?.state[0]?.key==='referrallogin'&&<div className={`d-flex ${(finalstate[history.location.state.index].active===false)&&' disabled '} gap-10`}>
                            <CustomSelect
                                label={"Reward Type"}
                                options={
                                    [
                                    {
                                        name: 'Sign Up',
                                        value: 'signup'
                                    },
                                    {
                                        name: 'Activity',
                                        value: 'activity'
                                    }
                                    ]
                                }
                                onChange={(e) => { finalstate[history.location.state.index].state[0].type = e.target.value; setFinalstate([...finalstate]) }}
                                value={finalstate[history.location.state.index].state[0].type}
                            />
                            {/* <CustomTextField
                                label={"Incentive to invite for singup"}
                                type="number"
                                placeholder={"Points"}
                                value= {finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "givePoint")].givePoints || 0}
                                onChange= {(e) => { 
                                    console.log('history?.location?.state?.solo?.state[0]',history?.location?.state?.solo?.state1);
                                    finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "givePoint")].givePoints = e.target.value; setFinalstate([...finalstate]) }}
                            /> */}
                            <CustomTextField
                                label={"Tag"}
                                placeholder={"Tag"}
                                // value= {finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "addTag")].addTag || 0} 
                                value={finalstate[history.location.state.index]?.state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "addTag")]?.addTag ?? ""}
                                onChange= {(e) => { finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "addTag")].addTag = e.target.value; setFinalstate([...finalstate]) }}
                            />
                    </div>}
                    {history?.location?.state?.solo?.state[0]?.key==='referral'&&<div className={`d-flex ${(finalstate[history.location.state.index].active===false)&&' disabled '} gap-10`}>
                        {/* <CustomTextField
                            label={"Number of invites"}
                            type="number"
                            placeholder={"Number of invites"}
                            value= {finalstate[history.location.state.index].state[0].customer || '0'}
                            onChange={ (e) => { 
                                console.log('history?.location?.state?.solo?.state[0]',history?.location?.state?.solo?.state1);
                                finalstate[history.location.state.index].state[0].customer = e.target.value; setFinalstate([...finalstate]) }}
                        /> */}
                        <CustomSelect
                            label={"Reward Type"}
                            options={
                                [
                                {
                                    name: 'Sign Up',
                                    value: 'signup'
                                },
                                {
                                    name: 'Activity',
                                    value: 'activity'
                                }
                                ]
                            }
                            onChange={(e) => { finalstate[history.location.state.index].state[0].type = e.target.value; setFinalstate([...finalstate]) }}
                            value={finalstate[history.location.state.index].state[0].type}
                        />
                        {/* <CustomTextField
                            label={"Incentive to invite for singup"}
                            type="number"
                            placeholder={"Points"}
                            value= {finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "givePoint")].givePoints || 0}
                            onChange= {(e) => { 
                                console.log('history?.location?.state?.solo?.state[0]',history?.location?.state?.solo?.state1);
                                // console.log('history?.location?.state?.solo?.state[1]',history?.location?.state?.solo?.state[1]);
                                // let index = history?.location?.state?.solo?.state1.findIndex(item => item.key === "addTag")
                                finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "givePoint")].givePoints = e.target.value; setFinalstate([...finalstate]) }}
                        /> */}
                        <CustomTextField
                            label={"Tag"}
                            placeholder={"Tag"}
                            // value= {finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "addTag")].addTag || 0} 
                            value={finalstate[history.location.state.index]?.state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "addTag")]?.addTag ?? ""}
                            onChange= {(e) => { finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "addTag")].addTag = e.target.value; setFinalstate([...finalstate]) }}
                        />
                    </div>}
                    {
                    ((history?.location?.state?.solo?.state[0]?.key==='event'&&history?.location?.state?.solo?.state[0]?.festival==='Birth Day') ||
                    history?.location?.state?.solo?.state[0]?.key==='referrallogin' ||
                    history?.location?.state?.solo?.state[0]?.key==='customerInactivity'||
                    history?.location?.state?.solo?.state[0]?.key==='referral' )
                    &&<div className={(finalstate[history.location.state.index].active)?"mt_20":"disabled mt_20"}>
                        {console.log('history?.location?.state?.solo?.state[0]',history?.location?.state?.solo?.state[0])}
                        
                        {
                        history?.location?.state?.solo?.state[0]?.key!=='referral'&&
                        history?.location?.state?.solo?.state[0]?.key!=='referrallogin'&&
                        history?.location?.state?.solo?.state[0]?.key!=='customerInactivity'&&
                        <FormControl 
                            // error = {formik.touched.usedFor && formik.errors.usedFor}
                            className={
                                (history?.location?.state?.solo?.state[0]?.key==='referrallogin'||history?.location?.state?.solo?.state[0]?.key==='referral'||history?.location?.state?.solo?.state[0]?.key==='customerInactivity'
                                    // ||(history?.location?.state?.solo?.state[0]?.key==='event'&&history?.location?.state?.solo?.state[0]?.festival!=='Birth Day')
                                )?
                                "d-flex disabled":
                                "d-flex"
                            }
                            >
                            <RadioGroup 
                                name     = "usedFor"
                                className="d-flex" style={{flexFlow: 'row'}}
                                value    = {pts}
                                onChange = {(e)=>{
                                    if(e.target.value === 'Coupon'){
                                        // delete finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "givePoint")]
                                        // Assuming finalstate and history are defined as mentioned
                                        let indexToRemove = history.location.state.index;
                                        let state1Array = finalstate[indexToRemove].state1;

                                        // Find the index of the element with key "givePoint"
                                        let elementIndex = state1Array.findIndex(item => item.key === "givePoint");

                                        // Remove the element at the found index
                                        if (elementIndex !== -1) {
                                            state1Array.splice(elementIndex, 1);
                                        }

                                        // If you need to update the finalstate object
                                        finalstate[indexToRemove].state1 = state1Array;

                                        console.log(finalstate[indexToRemove].state1);

                                        console.log("asddsa",history?.location?.state?.solo?.state1);
                                        console.log("asddsa",history?.location?.state?.solo?.state1.findIndex(item => item?.key === "givePoint"));



                                        if(history?.location?.state?.solo?.state1.findIndex(item => item?.key === "rewardDeal")=== -1){
                                            finalstate[history.location.state.index].state1.push({
                                                "name": "Reward",
                                                "key": "rewardDeal",
                                                "img": "/images/rewardBuilder/rewardDeal.png",
                                                "description": "Description",
                                                "rewardDeal": "",
                                                "dealExpiration":"0",
                                                "dealname": "",
                                                "uniqueId": ""
                                            })
                                        }
                                    }
                                    if(e.target.value === 'Loyalty'){
                                        // delete finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "givePoint")]
                                        // Assuming finalstate and history are defined as mentioned
                                        let indexToRemove = history.location.state.index;
                                        let state1Array = finalstate[indexToRemove].state1;

                                        // Find the index of the element with key "givePoint"
                                        let elementIndex = state1Array.findIndex(item => item.key === "rewardDeal");

                                        // Remove the element at the found index
                                        if (elementIndex !== -1) {
                                            state1Array.splice(elementIndex, 1);
                                        }

                                        // If you need to update the finalstate object
                                        finalstate[indexToRemove].state1 = state1Array;

                                        console.log(finalstate[indexToRemove].state1);

                                        console.log("asddsa",history?.location?.state?.solo?.state1);
                                        console.log("asddsa",history?.location?.state?.solo?.state1.findIndex(item => item?.key === "rewardDeal"));


                                        if(history?.location?.state?.solo?.state1.findIndex(item => item?.key === "givePoint")=== -1){
                                            finalstate[history.location.state.index].state1.push({
                                                "name": "Give Points",
                                                "key": "givePoint",
                                                "img": "/images/rewardBuilder/addPoints.png",
                                                "description": "Description",
                                                "givePoints": ""
                                            })
                                        }

                                    }
                                    // delete finalstate[history.location.state.index].state1[0].givePoints
                                    setFilterAudience([])
                                    setPts(e.target.value)}}
                                // onFocus  = {formik.handleBlur}
                            >
                                <CustomRadio 
                                    size="large"
                                    value = {'Coupon'}
                                    label = {<div className="Body14R color-neutral100">Coupon</div>}
                                    />
                                <CustomRadio 
                                    size="large"
                                    value={'Loyalty'}
                                    label = {<div className="Body14R color-neutral100">Loyalty</div>}
                                />
                            </RadioGroup>
                            <FormHelperText>
                                {/* {formik.touched.usedFor && formik.errors.usedFor ? formik.errors.usedFor : ''} */}
                            </FormHelperText>
                        </FormControl>}
                        <div className="d-flex gap-10">
                            
                            
                            {(pts==='Points'||pts==='Loyalty')&&
                            <CustomTextField
                                label={"Points"}
                                type="number"
                                className={history?.location?.state?.solo?.state[0]?.key==='customerInactivity'?'':'w-50 mt_20'}
                                placeholder={"Points"}
                                // value= {finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "givePoint")].givePoints || 0}
                                value={finalstate[history.location.state.index]?.state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "givePoint")]?.givePoints ?? 0}
                                  
                                onChange= {(e) => { 
                                    console.log('history?.location?.state?.solo?.state[0]',history?.location?.state?.solo?.state1);
                                    finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "givePoint")].givePoints = e.target.value; setFinalstate([...finalstate]) }}
                            />}
                            
                                {history?.location?.state?.solo?.state[0]?.key==='customerInactivity'&&
                                    <CustomTextField
                                        label={"Tag"}
                                        placeholder={"Tag"}
                                        // value= {finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "addTag")].addTag || 0} 
                                        value={finalstate[history.location.state.index]?.state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "addTag")]?.addTag ?? ""}
                                        onChange= {(e) => { finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "addTag")].addTag = e.target.value; setFinalstate([...finalstate]) }}
                                    />
                                }
                                {history?.location?.state?.solo?.state[0]?.key==='customerInactivity'&&
                                    <CustomTextField
                                        label={"Inactivity Days"}
                                        type="number"
                                        placeholder={"Inactivity Days"}
                                        value= {finalstate[history.location.state.index].state[0].days || '0'}
                                        onChange= {(e) => { finalstate[history.location.state.index].state[0].days = e.target.value; setFinalstate([...finalstate]) } }
                                    />
                                }




                            </div>

                            {(pts==='Reward'||pts==='Coupon')&&
                            <div className="mt_20">
                            <CustomSelect
                                label={"Coupon Expiry"}
                                className="w-50 pr_5"
                                options={
                                    [
                                    {
                                        name: 'None',
                                        value: '0'
                                    },
                                    {
                                        name: '30 Days',
                                        value: '30'
                                    },
                                    {
                                        name: '60 Days',
                                        value: '60'
                                    },
                                    {
                                        name: '90 Days',
                                        value: '90'
                                    }
                                    ]
                                }
                                onChange={(e) => { finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "rewardDeal")].dealExpiration = e.target.value; setFinalstate([...finalstate]) }}
                                value={finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item.key === "rewardDeal")].dealExpiration}
                            />
                        </div>
                            }
                            {(pts==='Reward'||pts==='Coupon')&&filteredOptions && filteredOptions.length > 0 &&
                                <>
                                {/* <div className="bar"></div> */}
                                <div className="mt_20 mb_10 Link16M" style={{color:"#141519"}}>Choose Product</div>
                                <div style={{ 
                                display: 'grid', 
                                // gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', 
                                gridTemplateColumns: 'repeat(2, 1fr)', 
                                gap: '16px' 
                                }}>
                                {filteredOptions.map((value, index) => (
                                    <Card key={index} style={{
                                    backgroundColor: filterAudience[0] === value.value && '#F2F6FF',
                                    border: filterAudience[0] === value.value && '2px solid #2960EC'
                                    // }} sx={{ height: 'fit-content' }} onClick={() => {
                                    }} sx={{ height: '110px' }} onClick={() => {
                                    // Handle click
                                    setFilterAudience([value.value])
                                    finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item?.key === "rewardDeal")].rewardDeal = value.value;
                                    finalstate[history.location.state.index].state1[history?.location?.state?.solo?.state1.findIndex(item => item?.key === "rewardDeal")].dealname = value.label;
                                    setFinalstate([...finalstate])
                                    }}>
                                    {console.log('value',value)}
                                    <CardActionArea style={{ display: 'flex', alignItems: 'flex-start' }}>
                                        <CardContent style={{ width: '100%' }}>
                                        {
                                        filterAudience[0] === value.value?
                                            <div className="d-flex gap-6">
                                            <Typography gutterBottom style={{fontSize: '15px', color: '#141519', fontWeight: 500, fontFamily: 'Inter'}} variant="h5" component="div" sx={{fontWeight:'bold'}}>
                                            {utils.capitalize(value.data.name)}
                                            </Typography>
                                            <img src={PngIcons.tick} width={16} height={16}/>
                                        </div>
                                            :
                                            <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 'bold', fontFamily: 'Inter' }}>
                                            {utils.capitalize(value.data.name)}
                                        </Typography>
                                        }
                                        <Typography variant="h6" color="text.secondary" sx={{
                                            overflow: 'hidden', fontFamily: 'Inter',
                                            display: '-webkit-box',
                                            WebkitBoxOrient: 'vertical',
                                            WebkitLineClamp: 2, // Limits to 2 lines
                                            textOverflow: 'ellipsis',
                                            opacity: value.data.description===''&&0.5
                                        }}>
                                            {/* {utils.capitalize(value.data.description)} */}
                                            {
                                        value.data.description===''?
                                        'Write a description here! Share details that make your product unique, from key features to the experience it offers customers.':
                                    utils.capitalize(value.data.description)}
                                        </Typography>
                                        </CardContent>
                                        <CardMedia
                                        component="img"
                                        // height="140"
                                        height="110"
                                        // style={{ padding: 12, width: '140px' }}
                                        style={{ padding: 12, width: '110px' }}
                                        image={value.data.image}
                                        alt={value.data.name}
                                        />
                                    </CardActionArea>
                                    </Card>
                                    
                                ))}
                                </div>
                                </>}
                    </div>
                    }
                        
                    <div className="bar mt_40 mb_16"></div>
                    <div className="red-last d-flex gap-10 align-items-center">
                    <CustomButton varient = {"secondary"} btntext = {"Cancel"} onClick={()=>{history.goBack()}} />
                   {((history?.location?.state?.solo?.state[0]?.key==='point'||
                        history?.location?.state?.solo?.state[0]?.festival==='Custom Date'||
                        history?.location?.state?.solo?.state[0]?.festival==='Week'||
                        history?.location?.state?.solo?.state[0]?.key==='dealStatus'||
                        history?.location?.state?.solo?.state[0]?.key==='visit')&&(!history.location.state?.delete)) && <CustomButton
                    varient="warningSecondary"
                    btntext={"Delete"}
                    className=""
                    onClick={() => { setDeleteModal(true) }}
                />}
                <CustomButton
                    icon={btnloader &&
                        <CircularProgress
                            size={20}
                            color={'inherit'} />}
                    varient={"primary"}
                    btntext={"Save Changes"}
                    onClick={async () => {
                        setBtnloader(true)
                        finalstate[history.location.state.index].block = layout.elements.editTitle || name || 'Data Flow'
                        const payload = {
                            name: 'Data Flow',
                            brandId: brand._id,
                            agencyId: agency._id,
                            flowType: 'new',
                            flow: finalstate
                        }
                        // console.log('payload',payload)
                        if (!history.location.state?.new) {
                            // console.log('aba');
                            payload._id = history.location.state?._id
                            console.log('payload', payload);
                            const { response, error } = await FlowService.Update({ payload })
                            console.log('error ', error)
                            console.log('response ', response)
                            let temp = [response.data]

                            console.log('response',response, error);
                            let flow = await localForage.getItem('flow');
                            if (flow && flow.length > 0) {
                                temp = []
                                for (const value of flow) {
                                    if (value._id === history.location.state?._id) temp.push(response.data)
                                    else temp.push(value)
                                }
                            }
                            localForage.setItem('flow', temp);
                        }
                        else {
                            // console.log('jaba');
                            // console.log('payload',payload);
                            const { response, error } = await FlowService.Create({ payload })
                            console.log('keep', response, error);
                            let temp = [response.data]
                            let flow = await localForage.getItem('flow');
                            if (flow) {
                                flow.push({ ...response.data, created: new Date().valueOf() })
                            }
                            else {
                                flow = []
                                flow.push({ ...response.data, created: new Date().valueOf() })
                            }
                            // if(flow && flow.length > 0){
                            //     temp = []
                            //     for (const value of flow) {
                            //         if(value._id === history.location.state?._id) temp.push(response.data)
                            //         else temp.push(value)
                            //     }
                            // }
                            localForage.setItem('flow', flow);
                        }
                        setBtnloader(false)

                        const location = window.location.pathname.split('/')
                        location.pop()
                        history.push(location.join('/'))

                    }} />

            </div>
                    </div>


                    <CustomModal
                        component={<EditComponent func={func} setShow={setShow} show={show} finalstate={finalstate} setFinalstate={setFinalstate} />}
                        open={show.modal}
                        minWidth={'450px'}
                        padding="16px"
                    />
                </div>}
        </>
    )
}

const EditComponent = ({ setShow, show, func }) => {

    const handleConfirm = () => {
        setShow({ ...show, modal: false })
    }

    return (
        <div className="">
            <div className='Heading18M color-neutral100 mb_24 capitalize'>
                {show.title} title
            </div>
            <CustomTextField
                onChange={(e) => {
                    show.onChange(e)
                    show.value = e.target.value
                    func(e.target.value, show.key)
                }}
                value={show.value}
                placeholder={`Enter ${show.title}`}
                label={show.title}
            />
            <div className="d-flex justify-flex-end mt_16">
                <CustomButton
                    btntext={'Confirm'}
                    onClick={handleConfirm}
                // disabled= {layout.elements.editTitle.length < 1 && true}
                />
            </div>

        </div>
    )
}

export default RewardBuilder;


const DealModalComponent = ({ deal, onClose, filteredOptions }) => {
    // console.log('deal ', deal);
    // console.log('deal ', filteredOptions);
    const [val, setVal] = useState('')
    useEffect(() => {
        for (const value of filteredOptions) {
            if (deal?.rewardDeal === value.value || deal?.deal === value.value) setVal(value.data)
        }
    }, [deal])

    return (
        <div className="">
            <div className="d-flex mb_24 space-between">
                <h3 className="H22M color-neutral100">
                    Deal Details
                </h3>
                <div className="cp" onClick={() => onClose()}><SvgIcons.CloseIcon /></div>

            </div>
            <div className="d-flex gap-16">
                <div className="dealImageBox ">
                    <img className="border borderRadius-4 object-fit-cover" src={val?.image} alt="" width={165} height={132} />
                </div>
                <div>
                    <div className="B12M color-neutral80 mb_6">Deal Name</div>
                    <div className="B14M color-neutral100 mb_16">{deal.dealname}</div>
                    <div className="B12M color-neutral80 mb_6">Description</div>
                    <div className="B12R color-neutral50">{val?.description}</div>
                </div>

            </div>
            {/* <div className="mt_16 d-flex justify-flex-end">
                <CustomButton 
                    btntext={"Close"}
                    onClick={()=>onClose()}
                    varient="secondary"
                />
            </div> */}

        </div>
    )
}