
import localforage from 'localforage';
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { BrandContext } from '../../../../context/Brand.context';
import { LayoutContext } from '../../../../context/Layout.context';
import { PngIcons, SvgIcons } from '../../../../icons'
import { CampaignService, EarningWayService, FlowService } from '../../../../services';
import localForage from 'localforage'
import Loader from '../../../../components/Loader';
import { Campaign } from '../../../../enums';
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@material-ui/core';

function RewardBuilderListing() {

  const [filteredData, setFilteredData] = useState();
  const [earningWays, setEarningWays] = useState([]);
  const [loader, setLoader] = useState(true);

  const history = useHistory()

  const brand = useContext(BrandContext)
  const layout = useContext(LayoutContext)

  const onLoad = async () => {

  
    let campaign = await localforage.getItem('campaign');
    if ((!campaign) || campaign.length === 0) {
      const ccampaign = await CampaignService.GetPublicCampaign({ query: { brandId: brand._id, delete: false } })
      if (ccampaign.response?.data?.length > 0) campaign = ccampaign.response.data[0]
    }
    let ways = await localforage.getItem('earningways');
    if ((!ways) || ways.length === 0) {
      const wways = await EarningWayService.GetQueriedEarningWaysPublic({ query: { campaignId: campaign[0]?._id || campaign?._id, brandId: brand._id } })
      console.log('wways ', wways)
      if (wways.response?.data?.length > 0){ ways = wways.response.data
        localForage.setItem('earningways', ways) }
      if (wways.error && wways.error.error === "no earning ways found") {
        const payload = {
          brandId: brand._id,
          brandType: brand.brandType,
          campaignId: campaign[0]?._id || campaign?._id,
          campaignType: campaign[0]?.campaignType || campaign?.campaignType,
        }
        const { error, response } = await EarningWayService.CreatePreBuiltEarningWays({ payload })
        if (response?.data?.length > 0) ways = response.data
      }
    }

    console.log('ways', ways);

    if (ways && ways.length > 0) setEarningWays(ways)

    let flow = await localforage.getItem('flow');
    console.log('mskingcommunivstion', flow);

    if ((!flow) || flow.length === 0) {
      const { response, error } = await FlowService.Get({ query: { brandId: brand._id, delete: false, flowType: 'new' } })
      console.log('loyalty Xoop Jeep', response, error);


      if (response) {
        localforage.setItem('flow', response.data);
        flow = response.data
      }
    }


    let tempFlow = []

    console.log('flow',flow);
    

    if(flow[0].flow.length>0){
      for (const value of flow[0].flow) {
        if(value.active!==true&&value.active!==false) {
          // value.active===true
          tempFlow.push({...value, active: true})
        }
        else{
          tempFlow.push(value)
        }
      }
    }

console.log('tempFlow',tempFlow);

flow[0].flow=tempFlow

console.log('floww',flow);

    setFilteredData(flow)
    setLoader(false)
  }

  useEffect(onLoad, [])

  useEffect(() => {
    layout.setLayout({
      borderBottom: false,
      edit: false,
      switch: "",
      title: "Automation",
      button: <></>
    })
  }, [])



  const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  function getDayName(number) {
    if (number >= 1 && number <= 7) {
        return dayNames[number % 7]; // Using modulo operator to handle numbers greater than 7
    } else {
        return "Invalid day number"; // Handling invalid numbers
    }
}


  return (
    <div>
      {
        loader ? <Loader baseHeight={"60vh"} width={120} height={120} /> :
          <div className="pt_16 pb_16 pl_50 pr_40">
            <div style={{ 
              display: 'grid', 
              gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))', 
              gap: '16px' 
            }}>
              {earningWays && earningWays.length > 0 && 
                earningWays.map((element, index) => (
                  ((element.activity === Campaign.Activities.EARNING.BIRTHDAY && !element.active) ||
                  (element.activity === Campaign.Activities.EARNING.CHECKIN && !element.active)) ? null :
                  
                  <Card key={index} sx={{ flex: '1 1 300px' }} onClick={() => {
                    history.push({
                      pathname: window.location.pathname + '/earn',
                      state: element,
                    })
                  }}>
                    {console.log('elementE',element)
                    }
                    <CardActionArea style={{ alignItems: 'flex-start', display: 'flex' }}>
                      <CardContent style={{width: '100%'}} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <div className="d-flex gap-6">
                          <Typography gutterBottom style={{fontSize: '15px', color: '#141519', fontWeight: 500, fontFamily: 'Inter'}} variant="h5" component="div" sx={{fontWeight:'bold'}}>
                          {element.activity == Campaign.Activities.EARNING.SIGNUP?"Sign Up": element.activity === Campaign.Activities.EARNING.SPENDING ? "Points": element.earningName}
                          </Typography>
                          {/* {element.active&&<img src={PngIcons.tick} width={16} height={16}/>} */}
                          {<img src={element.active?PngIcons.tick:PngIcons.tickG} width={16} height={16}/>}
                        </div>
                        <Typography style={{fontSize: '13px', color: '#2E3039', fontWeight: 400, fontFamily: 'Inter'}} color="text.secondary" sx={{
                          overflow: 'hidden',
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2,
                          textOverflow: 'ellipsis',
                        }}>
                          {element.activity == Campaign.Activities.EARNING.SIGNUP?"Customize rewards for new customer signups.": element.activity === Campaign.Activities.EARNING.SPENDING ? "Set custom loyalty points for customer actions": element.earningName}
                        </Typography>
                      </CardContent>
                      <CardMedia
                        component="img"
                        height="140"
                        style={{ padding: 12, width: '140px' }}
                        image={element.activity == Campaign.Activities.EARNING.SIGNUP
                          ? PngIcons.newEWSU
                          : element.activity === Campaign.Activities.EARNING.SPENDING
                            ? PngIcons.newEWS
                            : ""}
                        alt={element.activity == Campaign.Activities.EARNING.SIGNUP?"Sign Up": element.activity === Campaign.Activities.EARNING.SPENDING ? "Points": element.earningName}
                      />
                    </CardActionArea>
                  </Card>
                ))
              }
              {filteredData && filteredData.length > 0 && 
                filteredData.map((element, index) => (
                  element?.flow?.length > 0 && element.flow.map((ele, ind) => (

                    
                    
                    
                      ele.state[0].festival!=='Custom Date' &&
                      ele.state[0].festival!=='Week' &&
                      ele.state[0].key !== "visit" &&
                      ele.state[0].key !== "point" &&
                      ele.state[0].key !== "dealStatus" &&
                      ele.state[0].key !== "oneTimeDeal" &&
                      ele.state[0].key !== "allCustomer" &&
                    //   !(ele.state[0].key === "event" && (ele.state[0].festival === "Custom Date"||ele.state[0].festival === "Week"))
                    // &&
                    <Card key={`${index}-${ind}`} sx={{ flex: '1 1 300px', height: 'fit-content' }} 
                    onClick={
                      () => {
                        // if(element?.flow[ind].active===true||element?.flow[ind].active===false)console.log('yes');
                        // else element?.flow[ind].active = true
                        
                        let currentUrl = window.location.href;

                        // Check and remove the trailing slash if it exists
                        if (currentUrl.endsWith('/')) {
                            currentUrl = currentUrl.slice(0, -1);

                            // Update the URL without reloading the page
                            window.history.replaceState(null, '', currentUrl);
                        }

                        history.push({
                          pathname: window.location.pathname + '/rewardadd',
                          state: { ...element, index: ind, solo: {...ele, active: ele.active||true} }
                        })
                      }
                    }>
                    {console.log(element)}
                    {console.log('ele',ele)}
                    {console.log('ele',ele.state[0].sharedLoyality)}
                    <CardActionArea style={{ alignItems: 'flex-start', display: 'flex' }}>
                      <CardContent style={{width: '100%'}} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <div className="d-flex gap-6">
                          <Typography gutterBottom style={{fontSize: '15px', color: '#141519', fontWeight: 500, fontFamily: 'Inter'}} variant="h5" component="div" sx={{fontWeight:'bold'}}>
                          {
                            ele.state[0].key === "referrallogin"? //receiver
                            "Referral Receiver":
                            ele.state[0].key === "referral"? //sender
                            "Referral Sender":
                            ele.state[0].key === "customerInactivity"? 
                            "Inactivity":
                            ele.state[0].key === "point"? 
                            "Loyalty Reward":
                            ele.state[0].key === "sharedLoyality"? 
                            "Shared Loyalty":
                            (ele.state[0].key === "event" && ele.state[0].festival === "Birth Day" ) ? 
                            "Birthday":
                            (ele.state[0].key === "event" && (ele.state[0].festival === "Custom Date"||ele.state[0].festival === "Week") && ((ele.state1[0]?.key === "rewardDeal" || ele.state1[0]?.key === "addTag" || ele.state1[0]?.key === "bonusPoints" )) ) ? 
                            "Promotion":
                            (
                              ele.state[0].key === "visit" 
                              // ||
                              // (ele.state[0].key === "event" && (ele.state[0].festival === "Custom Date"||ele.state[0].festival === "Week") && ((ele.state1[0]?.key === "rewardDeal" || ele.state1[0]?.key === "addTag")) )
                            ) ? 
                            "Visit-Based Reward":
                          ele.block || element.name || element.key}
                        </Typography>
                          {/* {ele?.active!==false&&<img src={PngIcons.tick} width={16} height={16}/>} */}
                          {<img src={ele.active?PngIcons.tick:PngIcons.tickG} width={16} height={16}/>}
                        </div>
                        <Typography style={{fontSize: '13px', color: '#2E3039', fontWeight: 400, fontFamily: 'Inter'}} color="text.secondary" sx={{
                          overflow: 'hidden',
                          display: '-webkit-box', fontFamily: 'Inter',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2,
                          textOverflow: 'ellipsis',
                        }}>
                          {
                            ele.state[0].key === "referrallogin"? //receiver
                              "Share the love, earn the points":
                            ele.state[0].key === "referral"? //sender
                              "Share the love, earn the points":
                            ele.state[0].key === "sharedLoyality"? 
                              "Boost loyalty with "+ele?.state1[0]?.bonuspoints+" on "+ele?.state[0]?.sharedLoyality:
                            ele.state[0].key === "customerInactivity"? 
                              "Re-engage dormant customers with enticing":
                              ele.state[0].key === "point"? "Loyalty Point Reward: "+ele.state1[ele.state1.findIndex(item => item.key === "rewardDeal")]?.dealname+" for "+ele.state[0].points+" points" :
                            ele.state[0].key === "visit"? 
                              "Visit-Based Coupon: "+ele.state1[ele.state1.findIndex(item => item.key === "rewardDeal")]?.dealname+" after "+ele.state[0].visits+" visits":
                            (ele.state[0].key === "event" && ele.state[0].festival === "Birth Day" ) ? 
                              "Delight customers with special points on their special day":
                            (ele.state[0].key === "event" && (ele.state[0].festival === "Custom Date"||ele.state[0].festival === "Week") && ((ele.state1[0]?.key === "bonusPoints")) ) ? 
                              ele.state[0].festival === "Week"? "Weekly Coupon: "+ele.state1[ele.state1.findIndex(item => item.key === "bonusPoints")].bonuspoints[ele.state1[ele.state1.findIndex(item => item.key === "bonusPoints")].bonuspoints.length-1]+"x Points on every "+getDayName(parseInt(ele.state[0].week)):
                              ele.state[0].festival === "Custom Date"?
                            'Date-Specific Coupon: '+ele.state1[ele.state1.findIndex(item => item.key === "bonusPoints")].bonuspoints[ele.state1[ele.state1.findIndex(item => item.key === "bonusPoints")].bonuspoints.length-1]+'x Points from '+new Date(ele.state[0].eventStart).toLocaleDateString()+' to '+new Date(ele.state[0].eventEnd).toLocaleDateString():''
                            : 
                            (ele.state[0].key === "event" && (ele.state[0].festival === "Custom Date"||ele.state[0].festival === "Week") && (!(ele.state1[0]?.key === "rewardDeal" || ele.state1[0]?.key === "addTag")) ) ? 
                            "Boost loyalty with multiplied points on selected dates":
                            (ele.state[0].key === "event" && (ele.state[0].festival === "Custom Date"||ele.state[0].festival === "Week") && ((ele.state1[0]?.key === "rewardDeal" || ele.state1[0]?.key === "addTag")) ) ? 
                            ele.state[0].festival === "Week"?
                            "Weekly Coupon: "+ele.state1[ele.state1.findIndex(item => item.key === "rewardDeal")]?.dealname+" on every "+getDayName(parseInt(ele.state[0].week)):
                            ele.state[0].festival === "Custom Date"?
                            'Date-Specific Coupon: '+ele.state1[ele.state1.findIndex(item => item.key === "rewardDeal")]?.dealname+' from '+new Date(ele.state[0].eventStart).toLocaleDateString()+' to '+new Date(ele.state[0].eventEnd).toLocaleDateString():'':
                          'desc'}
                        </Typography>
                      </CardContent>
                      <CardMedia
                        component="img"
                        height="140"
                        style={{ padding: 12, width: '140px' }}
                        image={
                          ele.state[0].key === "referrallogin"? //receiver
                            PngIcons.NewRBlock:
                            ele.state[0].key === "referral"? //sender
                            PngIcons.NewRBlock:
                            ele.state[0].key === "customerInactivity"? 
                            PngIcons.NewIABlock:
                            (ele.state[0].key === "event" && ele.state[0].festival === "Birth Day" ) ? 
                            PngIcons.newBD:
                            (ele.state[0].key === "sharedLoyality"||ele.state[0].key === "visit"||(ele.state[0].key === "event" && (ele.state[0].festival === "Custom Date"||ele.state[0].festival === "Week"))) ? 
                            PngIcons.newCouponBlock:
                            ele.state[0].key === "point"? 
                            PngIcons.newRewardBlock:
                          'https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg'}
                        alt={ele.block || element.name || element.key}
                      />
                    </CardActionArea>
                  </Card>
                ))))
              }
            </div>

            <div className="bar mt_40 mb_40"></div>
            <div style={{ 
              display: 'grid', 
              gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))', 
              gap: '16px' 
            }}>

            {filteredData && filteredData.length > 0 && 
                filteredData.map((element, index) => (
                  element?.flow?.length > 0 && element.flow.map((ele, ind) => (

                    
                    
                    
                    (ele.state[0].festival==='Custom Date' ||
                    ele.state[0].festival==='Week') &&
                    
                    <Card key={`${index}-${ind}`} sx={{ flex: '1 1 300px', height: 'fit-content' }} 
                    onClick={
                      () => {
                        // if(element?.flow[ind].active===true||element?.flow[ind].active===false)console.log('yes');
                        // else element?.flow[ind].active = true
                        let currentUrl = window.location.href;
                        // Check and remove the trailing slash if it exists
                        if (currentUrl.endsWith('/')) {
                          currentUrl = currentUrl.slice(0, -1);

                          // Update the URL without reloading the page
                          window.history.replaceState(null, '', currentUrl);
                      }
                        history.push({
                          pathname: window.location.pathname + '/rewardadd',
                          state: { ...element, index: ind, solo: {...ele, active: ele.active||true} }
                        })
                      }
                    }>
                    {console.log(element)}
                    <CardActionArea style={{ alignItems: 'flex-start', display: 'flex' }}>
                      <CardContent style={{width: '100%'}} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <div className="d-flex gap-6">
                          <Typography gutterBottom style={{fontSize: '15px', color: '#141519', fontWeight: 500, fontFamily: 'Inter'}} variant="h5" component="div" sx={{fontWeight:'bold'}}>
                          {
                            ele.state[0].key === "referrallogin"? //receiver
                            "Referral Receiver":
                            ele.state[0].key === "referral"? //sender
                            "Referral Sender":
                            ele.state[0].key === "customerInactivity"? 
                            "Inactivity":
                            ele.state[0].key === "point"? 
                            "Loyalty Reward":
                            (ele.state[0].key === "event" && ele.state[0].festival === "Birth Day" ) ? 
                            "Birthday":
                            (ele.state[0].key === "event" && (ele.state[0].festival === "Custom Date"||ele.state[0].festival === "Week") && ((ele.state1[0]?.key === "rewardDeal" || ele.state1[0]?.key === "addTag" || ele.state1[0]?.key === "bonusPoints" )) ) ? 
                            "Promotion":
                            (
                              ele.state[0].key === "visit" 
                              // ||
                              // (ele.state[0].key === "event" && (ele.state[0].festival === "Custom Date"||ele.state[0].festival === "Week") && ((ele.state1[0]?.key === "rewardDeal" || ele.state1[0]?.key === "addTag")) )
                            ) ? 
                            "Visit-Based Reward":
                          ele.block || element.name || element.key}
                        </Typography>
                          {/* {ele?.active!==false&&<img src={PngIcons.tick} width={16} height={16}/>} */}
                          {<img src={ele.active?PngIcons.tick:PngIcons.tickG} width={16} height={16}/>}
                        </div>
                        <Typography style={{fontSize: '13px', color: '#2E3039', fontWeight: 400, fontFamily: 'Inter'}} color="text.secondary" sx={{
                          overflow: 'hidden',
                          display: '-webkit-box', fontFamily: 'Inter',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2,
                          textOverflow: 'ellipsis',
                        }}>
                          {
                            ele.state[0].key === "referrallogin"? //receiver
                              "Share the love, earn the points":
                            ele.state[0].key === "referral"? //sender
                              "Share the love, earn the points":
                            ele.state[0].key === "customerInactivity"? 
                              "Re-engage dormant customers with enticing":
                              ele.state[0].key === "point"? "Loyalty Point Reward: "+ele.state1[ele.state1.findIndex(item => item.key === "rewardDeal")]?.dealname+" for "+ele.state[0].points+" points" :
                            ele.state[0].key === "visit"? 
                              "Visit-Based Coupon: "+ele.state1[ele.state1.findIndex(item => item.key === "rewardDeal")]?.dealname+" after "+ele.state[0].visits+" visits":
                            (ele.state[0].key === "event" && ele.state[0].festival === "Birth Day" ) ? 
                              "Delight customers with special points on their special day":
                            (ele.state[0].key === "event" && (ele.state[0].festival === "Custom Date"||ele.state[0].festival === "Week") && ((ele.state1[0]?.key === "bonusPoints")) ) ? 
                              ele.state[0].festival === "Week"? "Weekly Coupon: "+ele.state1[ele.state1.findIndex(item => item.key === "bonusPoints")].bonuspoints[ele.state1[ele.state1.findIndex(item => item.key === "bonusPoints")].bonuspoints.length-1]+"x Points on every "+getDayName(parseInt(ele.state[0].week)):
                              ele.state[0].festival === "Custom Date"?
                            'Date-Specific Coupon: '+ele.state1[ele.state1.findIndex(item => item.key === "bonusPoints")].bonuspoints[ele.state1[ele.state1.findIndex(item => item.key === "bonusPoints")].bonuspoints.length-1]+'x Points from '+new Date(ele.state[0].eventStart).toLocaleDateString()+' to '+new Date(ele.state[0].eventEnd).toLocaleDateString():''
                            : 
                            (ele.state[0].key === "event" && (ele.state[0].festival === "Custom Date"||ele.state[0].festival === "Week") && (!(ele.state1[0]?.key === "rewardDeal" || ele.state1[0]?.key === "addTag")) ) ? 
                            "Boost loyalty with multiplied points on selected dates":
                            (ele.state[0].key === "event" && (ele.state[0].festival === "Custom Date"||ele.state[0].festival === "Week") && ((ele.state1[0]?.key === "rewardDeal" || ele.state1[0]?.key === "addTag")) ) ? 
                            ele.state[0].festival === "Week"?
                            "Weekly Coupon: "+ele.state1[ele.state1.findIndex(item => item.key === "rewardDeal")]?.dealname+" on every "+getDayName(parseInt(ele.state[0].week)):
                            ele.state[0].festival === "Custom Date"?
                            'Date-Specific Coupon: '+ele.state1[ele.state1.findIndex(item => item.key === "rewardDeal")]?.dealname+' from '+new Date(ele.state[0].eventStart).toLocaleDateString()+' to '+new Date(ele.state[0].eventEnd).toLocaleDateString():'':
                          'desc'}
                        </Typography>
                      </CardContent>
                      <CardMedia
                        component="img"
                        height="140"
                        style={{ padding: 12, width: '140px' }}
                        image={
                          ele.state[0].key === "referrallogin"? //receiver
                            PngIcons.NewRBlock:
                            ele.state[0].key === "referral"? //sender
                            PngIcons.NewRBlock:
                            ele.state[0].key === "customerInactivity"? 
                            PngIcons.NewIABlock:
                            (ele.state[0].key === "event" && ele.state[0].festival === "Birth Day" ) ? 
                            PngIcons.newBD:
                            (ele.state[0].key === "visit"||(ele.state[0].key === "event" && (ele.state[0].festival === "Custom Date"||ele.state[0].festival === "Week"))) ? 
                            PngIcons.newCouponBlock:
                            ele.state[0].key === "point"? 
                            PngIcons.newRewardBlock:
                          'https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg'}
                        alt={ele.block || element.name || element.key}
                      />
                    </CardActionArea>
                  </Card>
                  
                ))))
              }
              <div onClick={()=>{
                filteredData[0].flow.push({
                    "state": [
                        {
                            // "name": "Points",
                            // "key": "point",
                            // "img": "/images/rewardBuilder/points.png",
                            // "description": "It triggers when a specific amount of points are achieved.",
                            // "condition": "equal",
                            // "points": ""
                            description: "It triggers when a specific event occurs.",
                            eventEnd: 1731178799999,
                            eventStart: 1730919600000,
                            // festival: "Week",
                            festival: "Custom Date",
                            img: "/images/rewardBuilder/event.png",
                            key: "event",
                            name: "Event",
                        }
                    ],
                    "state1": [
                        {
                          bonuspoints: "Double the points x 2",
                          description: "Description",
                          img: "/images/favicon/doublepoints.png",
                          key: "bonusPoints",
                          name: "Bonus Points"
                          // "name": "Add Tag",
                            // "key": "addTag",
                            // "img": "/images/rewardBuilder/addTag.png",
                            // "description": "Description",
                            // "addTag": ""
                        },
                        // {
                        //     "name": "Reward",
                        //     "key": "rewardDeal",
                        //     "img": "/images/rewardBuilder/rewardDeal.png",
                        //     "description": "Description",
                        //     "rewardDeal": "",
                        //     "dealname": "",
                        //     "uniqueId": ""
                        // }
                    ],
                    "block": "pts",
                    active: true
                })

                console.log('filteredData',filteredData);
                setFilteredData([...filteredData])
                history.push({
                  pathname: window.location.pathname + '/rewardadd',
                  state: filteredData?.length > 0 ? 
                  { ...filteredData[0], index: filteredData[0].flow.length-1, delete: true, 
                    solo: {
                            "state": [
                                {
                                  description: "It triggers when a specific event occurs.",
                                  eventEnd: 1731178799999,
                                  eventStart: 1730919600000,
                                  // festival: "Week",
                                  festival: "Custom Date",
                                  img: "/images/rewardBuilder/event.png",
                                  key: "event",
                                  name: "Event",
                                  // week: 2
                                    // "name": "Points",
                                    // "key": "point",
                                    // "img": "/images/rewardBuilder/points.png",
                                    // "description": "It triggers when a specific amount of points are achieved.",
                                    // "condition": "equal",
                                    // "points": ""
                                }
                            ],
                            "state1": [
                                {
                                  bonuspoints: "Double the points x 2",
                                  description: "Description",
                                  img: "/images/favicon/doublepoints.png",
                                  key: "bonusPoints",
                                  name: "Bonus Points"
                                    // "name": "Add Tag",
                                    // "key": "addTag",
                                    // "img": "/images/rewardBuilder/addTag.png",
                                    // "description": "Description",
                                    // "addTag": ""
                                },
                                // {
                                //     "name": "Reward",
                                //     "key": "rewardDeal",
                                //     "img": "/images/rewardBuilder/rewardDeal.png",
                                //     "description": "Description",
                                //     "rewardDeal": "",
                                //     "dealname": "",
                                //     "uniqueId": ""
                                // }
                            ],
                            "block": "pts"
                        }
                      } : 
                      { index: 0, new: true, delete: true,
                        solo: {
                          
                          "state": [
                            {
                                      description: "It triggers when a specific event occurs.",
                                      eventEnd: 1731178799999,
                                      eventStart: 1730919600000,
                                      // festival: "Week",
                                      festival: "Custom Date",
                                      img: "/images/rewardBuilder/event.png",
                                      key: "event",
                                      name: "Event",
                                      // week: 2
                                        // "name": "Points",
                                        // "key": "point",
                                        // "img": "/images/rewardBuilder/points.png",
                                        // "description": "It triggers when a specific amount of points are achieved.",
                                        // "condition": "equal",
                                        // "points": ""
                                    }
                                ],
                                "state1": [
                                    {
                                      bonuspoints: "Double the points x 2",
                                      description: "Description",
                                      img: "/images/favicon/doublepoints.png",
                                      key: "bonusPoints",
                                      name: "Bonus Points"
                                        // "name": "Add Tag",
                                        // "key": "addTag",
                                        // "img": "/images/rewardBuilder/addTag.png",
                                        // "description": "Description",
                                        // "addTag": ""
                                    },
                                    // {
                                    //     "name": "Reward",
                                    //     "key": "rewardDeal",
                                    //     "img": "/images/rewardBuilder/rewardDeal.png",
                                    //     "description": "Description",
                                    //     "rewardDeal": "",
                                    //     "dealname": "",
                                    //     "uniqueId": ""
                                    // }
                                ],
                                "block": "pts"
                            }
                        
                   }
                })
              }} className='w-100  trigger1' style={{ alignContent: 'center', color:'#2960EC', minHeight: '140px' }} varient='tertiary'>
                <SvgIcons.Add color={'#2960EC'}/><br/>
                Add New Promotion
              </div>
            </div>
            <div className="bar mt_40 mb_40"></div>
            
            <div style={{ 
              display: 'grid', 
              gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))', 
              gap: '16px' 
            }}>
              {filteredData && filteredData.length > 0 && 
                filteredData.map((element, index) => (
                  element?.flow?.length > 0 && element.flow.map((ele, ind) => (

                    ele.state[0].key !== "sharedLoyality" &&
                    ele.state[0].key !== "referrallogin" &&
                    ele.state[0].key !== "referral" &&
                    ele.state[0].key !== "customerInactivity" &&
                    ele.state[0].key !== "event" &&
                    // 
                    // ele.state[0].key !== "dealStatus" &&
                    // ele.state[0].key !== "oneTimeDeal" &&
                    // 
                    <Card key={`${index}-${ind}-`} sx={{ flex: '1 1 300px', height: 'fit-content' }} 
                  onClick={
                    () => {
                      let currentUrl = window.location.href;
                      // Check and remove the trailing slash if it exists
                      if (currentUrl.endsWith('/')) {
                        currentUrl = currentUrl.slice(0, -1);

                        // Update the URL without reloading the page
                        window.history.replaceState(null, '', currentUrl);
                    }
                      history.push({
                        pathname: window.location.pathname + '/rewardadd',
                        state: { ...element, index: ind, solo: ele }
                      })
                  }
                  }>
                    <CardActionArea style={{ alignItems: 'flex-start', display: 'flex' }}>
                      <CardContent style={{width: '100%'}} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <div className="d-flex gap-6">
                          <Typography gutterBottom style={{fontSize: '15px', color: '#141519', fontWeight: 500, fontFamily: 'Inter'}} variant="h5" component="div" sx={{fontWeight:'bold'}}>
                          {
                            ele.state[0].key === "referrallogin"? //receiver
                            "Referral Receiver":
                            ele.state[0].key === "referral"? //sender
                            "Referral Sender":
                            ele.state[0].key === "customerInactivity"? 
                            "Inactivity":
                            ele.state[0].key === "point"? 
                            "Loyalty Reward":
                            (ele.state[0].key === "event" && ele.state[0].festival === "Birth Day" ) ? 
                            "Birthday":
                            (ele.state[0].key === "event" && (ele.state[0].festival === "Custom Date"||ele.state[0].festival === "Week") && (!(ele.state1[0]?.key === "rewardDeal" || ele.state1[0]?.key === "addTag")) ) ? 
                            "Promotion":
                            ele.state[0].key === "visit" ? "Visit-Based Reward":
                            ele.state[0].key === "dealStatus" ? "Redemption Reward":
                            (ele.state[0].key === "oneTimeDeal" ||
                            ele.state[0].key === "allCustomer")?"One-Time Reward":
                            (
                              (ele.state[0].key === "event" && (ele.state[0].festival === "Custom Date"||ele.state[0].festival === "Week") && ((ele.state1[0]?.key === "rewardDeal" || ele.state1[0]?.key === "addTag")) )
                            ) && 
                            // ) ? 
                            "Reward Coupon"
                          // :ele.block || element.name || element.key
                          }
                        </Typography>
                          {/* {ele?.active!==false&&<img src={PngIcons.tick} width={16} height={16}/>} */}
                          {<img src={ele.active?PngIcons.tick:PngIcons.tickG} width={16} height={16}/>}
                        </div>
                        <Typography style={{fontSize: '13px', color: '#2E3039', fontWeight: 400, fontFamily: 'Inter'}} color="text.secondary" sx={{
                          overflow: 'hidden', fontFamily: 'Inter',
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2,
                          textOverflow: 'ellipsis',
                        }}>
                          {
                            ele.state[0].key === "referrallogin"? //receiver
                              "Share the love, earn the points":
                            ele.state[0].key === "referral"? //sender
                              "Share the love, earn the points":
                            ele.state[0].key === "customerInactivity"? 
                              "Re-engage dormant customers with enticing":
                              ele.state[0].key === "point"? "Loyalty Point Reward: "+ele.state1[ele.state1.findIndex(item => item.key === "rewardDeal")]?.dealname+" for "+ele.state[0].points+" points" :
                            ele.state[0].key === "dealStatus"? 'Redemption Coupon: '+ele.state1[ele.state1.findIndex(item => item.key === "rewardDeal")]?.dealname+' after '+ele.state[0]?.dealname: 
                            ele.state[0].key === "oneTimeDeal"? "One Time Deal: "+ele.state1[ele.state1.findIndex(item => item.key === "rewardDeal")]?.dealname:
                            ele.state[0].key === "allCustomer"? "One Time Deal: "+ele.state1[ele.state1.findIndex(item => item.key === "rewardDeal")]?.dealname:
                            ele.state[0].key === "visit"? 
                              "Visit-Based Coupon: "+ele.state1[ele.state1.findIndex(item => item.key === "rewardDeal")]?.dealname+" after "+ele.state[0].visits+" visits":
                            (ele.state[0].key === "event" && ele.state[0].festival === "Birth Day" ) ? 
                              "Delight customers with special points on their special day":
                            (ele.state[0].key === "event" && (ele.state[0].festival === "Custom Date"||ele.state[0].festival === "Week") && (!(ele.state1[0]?.key === "rewardDeal" || ele.state1[0]?.key === "addTag")) ) ? 
                            "Boost loyalty with multiplied points on selected dates":
                            (ele.state[0].key === "event" && (ele.state[0].festival === "Custom Date"||ele.state[0].festival === "Week") && ((ele.state1[0]?.key === "rewardDeal" || ele.state1[0]?.key === "addTag")) ) ? 
                            ele.state[0].festival === "Week"?
                            "Weekly Coupon: "+ele.state1[ele.state1.findIndex(item => item.key === "rewardDeal")]?.dealname+" on every "+getDayName(parseInt(ele.state[0].week)):
                            ele.state[0].festival === "Custom Date"?
                            'Date-Specific Coupon: '+ele.state1[ele.state1.findIndex(item => item.key === "rewardDeal")]?.dealname+' from '+new Date(ele.state[0].eventStart).toLocaleDateString()+' to '+new Date(ele.state[0].eventEnd).toLocaleDateString():'':
                          'desc'}
                        </Typography>
                      </CardContent>
                      <CardMedia
                        component="img"
                        height="140"
                        style={{ padding: 12, width: '140px' }}
                        image={
                          ele.state[0].key === "referrallogin"? //receiver
                            PngIcons.NewRBlock:
                            ele.state[0].key === "referral"? //sender
                            PngIcons.NewRBlock:
                            ele.state[0].key === "customerInactivity"? 
                            PngIcons.NewIABlock:
                            (ele.state[0].key === "event" && ele.state[0].festival === "Birth Day" ) ? 
                            PngIcons.newBD:
                            (
                              ele.state[0].key === "visit"||
                              ele.state[0].key === "dealStatus"||
                              ele.state[0].key === "oneTimeDeal"||
                              ele.state[0].key === "allCustomer"
                              ||(ele.state[0].key === "event" && (ele.state[0].festival === "Custom Date"||ele.state[0].festival === "Week"))) ? 
                            PngIcons.newCouponBlock:
                            ele.state[0].key === "point"? 
                            PngIcons.newRewardBlock:
                          'https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg'}
                        alt={ele.block || element.name || element.key}
                      />
                    </CardActionArea>
                  </Card>
                ))))
              }
              <div onClick={()=>{
                filteredData[0].flow.push({
                    "state": [
                        {
                            "name": "Points",
                            "key": "point",
                            "img": "/images/rewardBuilder/points.png",
                            "description": "It triggers when a specific amount of points are achieved.",
                            "condition": "equal",
                            "points": ""
                        }
                    ],
                    "state1": [
                        {
                            "name": "Add Tag",
                            "key": "addTag",
                            "img": "/images/rewardBuilder/addTag.png",
                            "description": "Description",
                            "addTag": ""
                        },
                        {
                            "name": "Reward",
                            "key": "rewardDeal",
                            "img": "/images/rewardBuilder/rewardDeal.png",
                            "description": "Description",
                            "rewardDeal": "",
                            "dealname": "",
                            "uniqueId": ""
                        }
                    ],
                    "block": "pts",
                    active: true
                })

                console.log('filteredData',filteredData);
                setFilteredData([...filteredData])
                history.push({
                  pathname: window.location.pathname + '/rewardadd',
                  state: filteredData?.length > 0 ? 
                  { ...filteredData[0], index: filteredData[0].flow.length-1, delete: true, 
                    solo: {
                            "state": [
                                {
                                    "name": "Points",
                                    "key": "point",
                                    "img": "/images/rewardBuilder/points.png",
                                    "description": "It triggers when a specific amount of points are achieved.",
                                    "condition": "equal",
                                    "points": ""
                                }
                            ],
                            "state1": [
                                {
                                    "name": "Add Tag",
                                    "key": "addTag",
                                    "img": "/images/rewardBuilder/addTag.png",
                                    "description": "Description",
                                    "addTag": ""
                                },
                                {
                                    "name": "Reward",
                                    "key": "rewardDeal",
                                    "img": "/images/rewardBuilder/rewardDeal.png",
                                    "description": "Description",
                                    "rewardDeal": "",
                                    "dealname": "",
                                    "uniqueId": ""
                                }
                            ],
                            "block": "pts"
                        }
                      } : 
                      { index: 0, new: true, delete: true,
                        solo: {
                                "state": [
                                    {
                                        "name": "Points",
                                        "key": "point",
                                        "img": "/images/rewardBuilder/points.png",
                                        "description": "It triggers when a specific amount of points are achieved.",
                                        "condition": "equal",
                                        "points": ""
                                    }
                                ],
                                "state1": [
                                    {
                                        "name": "Add Tag",
                                        "key": "addTag",
                                        "img": "/images/rewardBuilder/addTag.png",
                                        "description": "Description",
                                        "addTag": ""
                                    },
                                    {
                                        "name": "Reward",
                                        "key": "rewardDeal",
                                        "img": "/images/rewardBuilder/rewardDeal.png",
                                        "description": "Description",
                                        "rewardDeal": "",
                                        "dealname": "",
                                        "uniqueId": ""
                                    }
                                ],
                                "block": "pts"
                            }
                        
                   }
                })
              }} className='w-100  trigger1' style={{ alignContent: 'center', color:'#2960EC', minHeight: '140px' }} varient='tertiary'>
                <SvgIcons.Add color={'#2960EC'}/><br/>
                Add New Reward
              </div>
            </div>
            <div className="bar mt_40 mb_40"></div>



          </div>
      }
    </div>
  )
}

export default RewardBuilderListing





