import {
  CircularProgress,
  FormControl,
  FormHelperText,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useContext, createRef, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import CustomButton from "../../../../components/CustomButton";
import CustomTextArea from "../../../../components/CustomTextArea";
import { PngIcons, SvgIcons } from "../../../../icons";
import CustomSlider from "../../../../components/CustomSlider";
import CustomTextField from "../../../../components/CustomTextField";
import CustomImageDrop from "../../../../components/CustomImageDrop";
import CustomSelect from "../../../../components/CustomSelect";
import CustomSwitch from "../../../../components/CustomSwitch";
import GoogleMap from "../../../../components/googleMap/GoogleMap";
import Autocomplete from "../../../../components/googleMap/Autocomplete";
import { Options, utils } from "../../../../utils";
import { FormikConsumer, useFormik } from "formik";
import { UserContext } from "../../../../context/User.context";
import { LayoutContext } from "../../../../context/Layout.context";
import { SubBrandVld } from "../../../../validation";
import { AgencyContext } from "../../../../context/Agency.context";
import PageTitle from "../../../../components/setting/SettingPageTitles";
import { BrandContext } from "../../../../context/Brand.context";
import CustomsModal from "../../../../components/CustomModal";
import ImageModal from "../../../../components/ImageModal";
import { Brand, Campaign, ColorScheme, enums } from "../../../../enums";
import CustomRadio from "../../../../components/CustomRadio";

import * as localForage from "localforage";
import { BrandService, TierService } from "../../../../services";
import CustomCheckBox from "../../../../components/CustomCheckBox";
import { ColorSchemeCode } from "../../../../enums/ColorScheme";
import VenueType from "../../../../enums/VenueType";
import { RedemptionType } from "../../../../enums/enums";
import QRkjua from "../../../../components/QR-kjua/qrcode.kjua";
import env from "../../../../config";
import CoalitionService from "../../../../services/Coalition.service";
import DeleteModal from "../../../../components/DeleteModal";
import LeaveModal from "../../../../components/LeaveModal";
import { ImageModalV2 } from "../../../../components/ImageModalV2/ImageModalV2";
import LogoUploader from "../../../../components/LogoUploader";
import { showToaster } from "../../../../utils/utils";
import Loader from "../../../../components/Loader";
import QRcode from "../../../../components/QrCode";
import PhoneInput  from 'react-phone-input-2';
import LocationAutoCompleteField from "../../../../components/LocationAutoCompleteField";

function AddVenue({ selectedCampaign }) {

  
  
  const user = useContext(UserContext);
  const agency = useContext(AgencyContext);
  const brand = useContext(BrandContext);
  const layout = useContext(LayoutContext);

  const logoRef = React.createRef();
  const history = useHistory();
  const venueData = history.location.state;
  console.log('venueData',venueData);
  const [qrLoader, setQrLoader] = useState(false);
  const [downloadQrLoader, setDownloadQrLoader] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [showTierModal, setShowTierModal] = useState(false);
  const [filled, setFilled] = useState({
    generalInformation: false,
    location: false,
    redemptionType: false,
    loyaltyBenifits: false,
    socialLinks: false,
    connectBrand: false,
  });

  const [state, setstate] = useState({
    apiError: "",
    loader: false,
    deleteLoader: false,
    mainLoader: false,
  });
  const [imageModal, setImageModal] = useState({
    logo: { open: false, imageEvent: "", return: "" },
    cover: { open: false, imageEvent: "", return: "" },
    dealOfferImageFile: { open: false, imageEvent: "", return: "" },
  });
  const [_getTiers, _setTiers] = useState();
  const [_customTier, _setCustomTier] = useState(false);
  const [_getCoalition, _setCoalition] = useState();
  const [timezone, setTimezone] = useState("");
  const [tempArr, setTempArr] = useState([]);
  const [tierData, setTierData] = useState();
  const [soloBrands, setSoloBrands] = useState({
    list: [],
    connectedBrand: venueData
      ? venueData.connectedBrand && venueData.connectedBrand
      : "",
  });

  const handleImage = (e, key) => {
    // e.preventDefault();
    const file = e.dataTransfer ? e.dataTransfer.files : e.target.files;
    formik.setValues({ ...formik.values, [key]: file[0] });
  };

  useEffect(async () => {
    setstate({ ...state, mainLoader: true });
    if (venueData && venueData.edit) {
      let allTiersData = await localForage.getItem("tiers");
      setTierData(allTiersData);
    }

    const query = { agencyId: agency._id, delete: false };

    const { error, response } = await BrandService.Get({ query });
    if (response) {
      filterSharedBrandsFunc(response.data);
    }
    setstate({ ...state, mainLoader: false });
  }, []);

  const filterSharedBrandsFunc = async (data) => {
    const normalBrands = await data.filter(
      (brand) => brand.brandType != Brand.BrandTypes.SHARED_LOYALITY
    );
    setSoloBrands({ ...soloBrands, list: normalBrands });
  };

  const onAddressChange = (event) =>
    formik.setValues({
      ...formik.values,
      location: { ...formik.values.location, address: event.target.value },
    });

  const locationSummary = (location) => {
    const currency = utils.getCurrency({ location: location });
    const selectedTimezone = utils.getTimezone({ location: location });
    setTimezone(selectedTimezone);
    formik.setValues({ ...formik.values, location, currency });
  };

  const getPlace = (e) => {
    const Geocoder = new window.google.maps.Geocoder();
    const placeRequest = {
      location: { lat: e.latLng.lat(), lng: e.latLng.lng() },
    };

    Geocoder.geocode(placeRequest, (places, status) => {
      if (status !== window.google.maps.GeocoderStatus.OK) return;
      const location = utils.summarisedLocation(places[0]);
      formik.setValues({ ...formik.values, location });
    });
  };

  const checkfbVal =
    venueData &&
    venueData.social &&
    venueData.social.facebook &&
    venueData.social.facebook !== "" &&
    venueData.social.facebook;
  const checkinstaVal =
    venueData &&
    venueData.social &&
    venueData.social.instagram &&
    venueData.social.instagram !== "" &&
    venueData.social.instagram;
  const checkwebVal =
    venueData &&
    venueData.social &&
    venueData.social.website &&
    venueData.social.website !== "" &&
    venueData.social.website;

  // let tempCover = venueData ? venueData.brandCover ? venueData.brandCover : '' : ''

  const initState = {
    connectedBrand: venueData.connectedBrand ? venueData.connectedBrand : "",
    avgSpendingValue: venueData
      ? venueData.avgSpendingValue
        ? venueData.avgSpendingValue
        : 0
      : 0,
    redemptionType: venueData
      ? venueData.brandRedemptionType
        ? venueData.brandRedemptionType
        : RedemptionType.SELF
      : RedemptionType.SELF,

    logo: venueData ? (venueData.brandLogo ? venueData.brandLogo : "") : "",
    brandCover: venueData ? venueData.tempBrandCover : "",
    brandLogoFile: "",
    brandCoverFile: "",
    name: venueData ? venueData.brandName && venueData.brandName : "",
    email: venueData ? venueData.email && venueData.email : "",
    dialingCode:
      venueData && venueData.dialingCode
        ? venueData.dialingCode
        : agency
        ? agency.dialingCode
             :  "+61",
    phone    : venueData ? venueData.phone && venueData.phone      : "",
    fullPhone: venueData  ? venueData.dialingCode + venueData.phone : "",
    type     : venueData
      ? venueData.edit === true
        ? venueData.locationType && VenueType.includes(venueData.locationType)
          ? venueData.locationType
          : "other"
        : "accounting"
      : "",
    location: venueData ? venueData.location && venueData.location : "",
    description: venueData
      ? venueData.description && venueData.description
      : "",
    other: venueData
      ? venueData.locationType && VenueType.includes(venueData.locationType)
        ? ""
        : venueData.locationType
      : "",

    checkDealOffer: venueData
      ? venueData.dealOffer &&
        venueData.dealOffer.name &&
        venueData.dealOffer.name !== ""
        ? true
        : false
      : false,
    // checkDealOffer    : venueData ?  (JSON.stringify(venueData.dealOffer) === JSON.stringify({}) || !venueData.dealOffer) ? false: true: false,
    dealOfferName: venueData
      ? venueData.dealOffer && venueData.dealOffer.name
      : "",
    dealDescription: venueData
      ? venueData.dealOffer && venueData.dealOffer.dealDescription
      : "",
    dealOfferImage: venueData
      ? venueData.dealOffer && venueData.dealOffer.image
      : "",
    dealOfferImageFile: "",
    checkTierSystem: false,
    checkSocialLinks: venueData
      ? venueData.social &&
        ((venueData.social.facebook && venueData.social.facebook !== "") ||
          (venueData.social.instagram && venueData.social.instagram !== "") ||
          (venueData.social.website && venueData.social.website !== ""))
        ? true
        : false
      : false,
    tierSystemName: venueData
      ? venueData.tiers && venueData.tiers.length > 0 && venueData.tiers[0].name
      : "",

    discountValue: [
      venueData &&
        venueData.tiers &&
        venueData.tiers.length > 0 &&
        venueData.tiers.map((v, i) =>
          v.benefits.discount.discountValue === null
            ? null
            : v.benefits.discount.discountValue
        ),
    ],
    customDiscounts: [
      venueData &&
        venueData.tiers &&
        venueData.tiers.length > 0 &&
        venueData.tiers.map((v, i) =>
          v.benefits.discount.customDiscounts === null
            ? [0, 0, 0, 0, 0, 0, 0]
            : v.benefits.discount.customDiscounts
        ),
    ],
    extraPoints: [
      venueData &&
        venueData.tiers &&
        venueData.tiers.length > 0 &&
        venueData.tiers.map(
          (v, i) => v.benefits.points && v.benefits.points.extraPoints
        ),
    ],

    // checkDiscount : false,
    checkDiscount: [
      venueData &&
        venueData.tiers &&
        venueData.tiers.length > 0 &&
        venueData.tiers.map((v, i) => (v.benefits.discount ? true : false)),
    ],
    checkCustom: [
      venueData &&
        venueData.tiers &&
        venueData.tiers.length > 0 &&
        venueData.tiers.map((v, i) =>
          // v.benefits.discount && v.benefits.discount.customDiscounts && v.benefits.discount.customDiscounts.length > 0 ? true : false
          v.benefits.discount &&
          v.benefits.discount.customDiscounts &&
          v.benefits.discount.customDiscounts.length > 0
            ? "Custom Discounts"
            : "DiscountValue"
        ),
    ],
    // checkCustom   : false,
    checkExtra: [
      venueData &&
        venueData.tiers &&
        venueData.tiers.length > 0 &&
        venueData.tiers.map((v, i) => (v.benefits.points ? true : false)),
    ],
    checkBirthday: false,

    tier: venueData
      ? venueData.tiers && venueData.tiers.length > 0 && venueData.tiers
      : [],
    tierIndex: 0,

    minPoints: "",
    maxPoints: "",

    facebook: venueData
      ? venueData.social
        ? venueData.social.facebook &&
          venueData.social.facebook.includes("https://")
          ? venueData.social.facebook.slice(8)
          : venueData.social.facebook
        : ""
      : "",
    instagram: venueData
      ? venueData.social
        ? venueData.social.instagram &&
          venueData.social.instagram.includes("https://")
          ? venueData.social.instagram.slice(8)
          : venueData.social.instagram
        : ""
      : "",
    website: venueData
      ? venueData.social
        ? venueData.social.website &&
          venueData.social.website.includes("https://")
          ? venueData.social.website.slice(8)
          : venueData.social.website
        : ""
      : "",

    // checkFacebook    : venueData ? (venueData.social && venueData.social.facebook  && venueData.social.facebook !== "") ? true : false : false,
    // checkInstagram   : venueData ? (venueData.social && venueData.social.instagram  && venueData.social.instagram !== "") ? true : false : false,
    // checkWebsite     : venueData ? (venueData.social && venueData.social.website  && venueData.social.website !== "") ? true : false : false,
    checkFacebook: checkfbVal ? true : false,
    checkInstagram: checkinstaVal ? true : false,
    checkWebsite: checkwebVal ? true : false,
    selectedComponent: brand.loyaltyConnectionBrand
      ? "Connect to solo brand"
      : "General Information",
    QrDownloadUrl: "",
  };

  const handleSubmit = async () => {
    setTempArr([]);

    const fullPhone = utils.getCountryCode(formik.values.fullPhone.includes('+') ? formik.values.fullPhone : '+'+formik.values.fullPhone)


    setstate({ ...state, loader: true });

    if (
      formik.values.facebook &&
      (formik.values.facebook.includes("https://") ||
        formik.values.facebook.includes("https:"))
    ) {
      setstate({ ...state, loader: false });
      return showToaster({
        position: "top-right",
        title: "Error",
        message: "The Given Facebook Url is not Correct, Please Correct it",
        severity: "error",
      });
    }
    if (
      formik.values.instagram &&
      (formik.values.instagram.includes("https://") ||
        formik.values.instagram.includes("https:"))
    ) {
      setstate({ ...state, loader: false });
      return showToaster({
        position: "top-right",
        title: "Error",
        message: "The Given Facebook Url is not Correct, Please Correct it",
        severity: "error",
      });
    }

    const user = await localForage.getItem("user");
    // const desiredPath = `${user._id}/${formik.values.name}`;
    const desiredPath = `${user._id}/${formik.values.name}/logo/${formik.values.brandLogoFile?.name.replace(/[\s~`!@#$%^&*(){}\[\];:"'<,.>?\/\\|_+=-]/g, '')}/${formik.values.brandLogoFile?.size}`
    const desiredPath1 = `${user._id}/${formik.values.name}/cover/${formik.values.brandCoverFile?.name.replace(/[\s~`!@#$%^&*(){}\[\];:"'<,.>?\/\\|_+=-]/g, '')}/${formik.values.brandCoverFile?.size}`

    let logo;
    if (formik.values.brandLogoFile && !formik.errors.brandLogoFile) {
      logo = await utils.uploadImage({
        file: formik.values.brandLogoFile,
        desiredPath,
      });
      if (logo.error)
        return setstate({
          ...state,
          loader: false,
          apiError: logo.error.message,
        });
    }

    let cover;
    if (formik.values.brandCoverFile && !formik.errors.brandCoverFile) {
      cover = await utils.uploadImage({
        file: formik.values.brandCoverFile,
        desiredPath: desiredPath1,
      });
      if (cover.error)
        return setstate({
          ...state,
          loader: false,
          apiError: cover.error.message,
        });
    }

    let offerImage;
    if (formik.values.dealOfferImageFile && !formik.errors.dealOfferImageFile) {
      offerImage = await utils.uploadImage({
        file: formik.values.dealOfferImageFile,
        desiredPath,
      });
      if (offerImage && offerImage.error)
        return setstate({
          ...state,
          loader: false,
          apiError: offerImage.error.message,
        });
    }

    // console.log("venue Data ", venueData);

    venueData.tiers.map((v, i) => {
      tempArr.push({
        name: v.name,
        id: v.id || v._id,
        benefits: {
          discount: {
            active: true,
            customDiscounts:
              formik.values.checkCustom[0][i] === "Custom Discounts"
                ? formik.values.customDiscounts[0][i]
                : null,
            discountValue:
              formik.values.checkCustom[0][i] === "DiscountValue"
                ? formik.values.discountValue[0][i]
                : null,
            // customDiscounts : formik.values.checkCustom[0][i] ? formik.values.customDiscounts[0][i] : null,
            // discountValue   : formik.values.checkCustom[0][i] ? null : formik.values.discountValue[0][i]
          },
          points: {
            active: true,
            extraPoints: parseInt(formik.values.extraPoints[0][i]),
          },
        },
      });
    });

    let dealOffer;
    if (formik.values.checkDealOffer) {
      dealOffer = {
        name: formik.values.checkDealOffer ? formik.values.dealOfferName : "",
        dealDescription: formik.values.checkDealOffer
          ? formik.values.dealDescription
          : "",
        image: formik.values.checkDealOffer
          ? offerImage
            ? offerImage.response.data
            : venueData.dealOffer && venueData.dealOffer.image
          : "",
      };
    } else {
      dealOffer = {};
    }

    const payload = {
      brandName   : formik.values.name.toLowerCase(),
      email       : formik.values.email,
      phone       : fullPhone.withoutCountryCode.replace(/\s/g, ''),
      dialingCode : fullPhone.countryCode,
      location    : formik.values.location,
      description : formik.values.description,
      currency    : brand.currency,
      brandLogo   : logo ? logo.response.data       : venueData.brandLogo,
      brandCover  : cover ? cover.response.data     : venueData.tempBrandCover,
      tiers       : tempArr,
      dealOffer   : dealOffer,
      locationType: 
        formik.values.type === "other"
          ? formik.values.other
          : formik.values.type,
      platform: Brand.Platforms.WALLETLY,
      brandType: Brand.BrandTypes.SUB_BRAND,
      campaignId: selectedCampaign._id,
      agencyId: agency._id,
      parentBrandId: brand._id,
      brandRedemptionType: formik.values.redemptionType,
      social: {
        facebook:
          formik.values.checkSocialLinks && formik.values.checkFacebook
            ? formik.values.facebook === "" ||
              formik.values.facebook === undefined
              ? ""
              : formik.values.facebook.includes("https://")
              ? formik.values.facebook
              : "https://" + formik.values.facebook
            : "",
        instagram:
          formik.values.checkSocialLinks && formik.values.checkInstagram
            ? formik.values.instagram === "" ||
              formik.values.instagram === undefined
              ? ""
              : formik.values.instagram.includes("https://")
              ? formik.values.instagram
              : "https://" + formik.values.instagram
            : "",
        website:
          formik.values.checkSocialLinks && formik.values.checkWebsite
            ? formik.values.website === "" ||
              formik.values.website === undefined
              ? ""
              : formik.values.website.includes("https://") ||
                formik.values.website.includes("http://")
              ? formik.values.website
              : "https://" + formik.values.website
            : "",
      },
      connectedBrand: formik.values.connectedBrand,
    };

    if (formik.values.connectedBrand == "") delete payload.connectedBrand;

    if (formik.values.redemptionType === RedemptionType.SELF)
      payload.avgSpendingValue = formik.values.avgSpendingValue;
    if (
      formik.values.redemptionType !== RedemptionType.SELF &&
      payload.avgSpendingValue
    )
      delete payload.avgSpendingValue;

    // if(formik.values.type === "other") VenueType.push(formik.values.other)

    // console.log("Payload is ", payload);

    if (!venueData.brandName) {
      const { response, error } = await BrandService.Create({
        toaster: true,
        payload,
      });
      if (response) {
        let subBrands = await localForage.getItem("subBrands");
        // subBrands.push(response.data);
        if (!subBrands) subBrands = [];
        localForage.setItem("subBrands", [response.data, ...subBrands]);
        // localForage.setItem('subBrands', subBrands)
      }
      if (error) setstate({ ...state, loader: false, apiError: error.error });
      else history.goBack();
    } else {
      delete payload.campaignId;
      delete payload.agencyId;

      const { response, error } = await BrandService.Update({
        toaster: true,
        payload: { ...payload, _id: venueData._id },
      });
      if (response) {
        let subBrands = await localForage.getItem("subBrands");
        subBrands.map((element, idx) => {
          if (element._id === venueData._id) subBrands[idx] = response.data;
        });
        localForage.setItem("subBrands", subBrands);
      }

      if (error) setstate({ ...state, loader: false, apiError: error.error });
      else history.goBack();
    }

    setstate({ ...state, loader: false });
  };

  // validateOnChange : false,
  // validateOnBlur   : false,
  const formik = useFormik({
    initialValues: { ...initState },
    onSubmit: handleSubmit,
    validationSchema: SubBrandVld.Create,
    isInitialValid: venueData && venueData.edit,
  });

  const [showSoloBrand, setShowSoloBrand] = useState(
    formik.values.connectBrand ? true : false
  );

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const handleSave = () => {
    _setCustomTier(!_customTier);
  };

  const handleText = (e, index) => {
    if (e.target.value <= 100 && e.target.value >= 0) {
      formik.values.customDiscounts[0][formik.values.tierIndex][index] =
        parseInt(e.target.value);
      formik.setValues({ ...formik.values });
    }
  };

  const SendQrCode = async () => {
    setQrLoader(true);
    const payload = {
      brand: {
        ...venueData,
        agencyLogo: agency.logo,
        agencyName: agency.agencyName,
        domain: "https://" + agency.domain,
      },
    };
    const { error, response } = await BrandService.SendQrCode({
      toaster: true,
      payload,
    });
    setQrLoader(false);
  };

  const handleChange = async (e, name) => {
    formik.setValues({ ...formik.values, [name]: e.target.value });
  };

  useEffect(() => {
    setTimeout(() => {
      layout.setLayout({
        expandedBar: false,
        header: true,
        scroll: false,
        showCollapseIcon: false,
        title: "Sub-Brand",
        switch : '',
        // backTitles: ["Loyalty Suite", "Sub-Brands"],
        borderBottom: true,
        edit: false,
        button: (
          <div>
            {venueData.edit && (
              <CustomButton
                btntext={"Delete"}
                varient="warningSecondary"
                onClick={() => setDeleteModal(true)}
              />
            )}
            <CustomButton
              btntext={"Cancel"}
              varient="secondary"
              className="ml_8"
              onClick={() => {
                if (formik.dirty) setLeave(true);
                else {
                  const location = window.location.pathname.split("/");
                  location.pop();
                  history.push(location.join("/"));
                }
              }}
            />
            <CustomButton
              varient={"primary"}
              btntext={venueData.edit ? "Save changes" : "Create Brand"}
              disabled={state.loader || !formik.isValid}
              icon={
                state.loader && <CircularProgress size={20} color={"inherit"} />
              }
              onClick={handleSubmit}
              className="ml_8"
            />
          </div>
        ),
      });
    }, 100);
  }, [state.loader, formik.isValid, formik.values, venueData, formik.dirty]);

  const handleDelete = async () => {
    setstate({ ...state, deleteLoader: true });
    const coalitionDelete = await CoalitionService.Update({
      payload: {
        parentBrandId: brand._id,
        childBrandId: venueData._id,
        delete: true,
      },
    });
    if (coalitionDelete.error)
      return setstate({ ...state, apiError: "unable to delete Brand" });
    const { response, error } = await BrandService.Update({
      toaster: true,
      payload: { _id: venueData._id, agencyId: agency._id, delete: true },
    });
    if (error)
      return setstate({ ...state, apiError: "unable to delete Brand" });
    if (response) {
      let subBrands = await localForage.getItem("subBrands");
      subBrands.map((element, idx) => {
        if (element._id === response.data._id) subBrands.splice(idx, 1);
      });
      localForage.setItem("subBrands", subBrands);

      const location = window.location.pathname.split("/");
      location.pop();
      history.push({ pathname: location.join("/"), state: { reload: true } });
    }
    setstate({ ...state, deleteLoader: false });
  };

  const handleUrl = (url) => {
    return url.replace("https", "http");
  };

  const qrcode = {
    size: 250,
    typeNumber: 8,
    text:
      "https://" +
      (agency.domain || env.WALLETLY_DOMAIN) +
      "/page/default/" +
      selectedCampaign._id +
      "/" +
      venueData.parentBrandId +
      "?childBrandId=" +
      venueData._id,
    logo: handleUrl(formik.values.logo),
    color: brand ? brand.brandColor : "#2F80ED",
  };

  const downloadQr = () => {
    setDownloadQrLoader(true);
    setTimeout(() => {
      const canvas = document.getElementsByTagName("canvas");
      const pngUrl = canvas[0]
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = "Qrcode.jpg";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      // const qrcode = new QrCodeWithLogo({
      //     content: "https://github.com/zxpsuper",
      //     width: 380,
      //     download: true,
      //     logo: {
      //       src: formik.values.logo,
      //       crossOrigin: 'Anonymous',
      //     }
      // }).toImage();
      setDownloadQrLoader(false);
    }, 5000);
  };

  useEffect(() => {
    let soloBrandErrorCheck,
      checkGeneralInfoError,
      checkLocationError,
      checkRedemptionError,
      checkLoyaltyError,
      checkSocialError;
    if (
      formik.errors.name ||
      formik.errors.email ||
      formik.errors.description ||
      formik.errors.phone
    ) {
      checkGeneralInfoError = false;
    } else {
      if (formik.values.logo == "" && formik.values.brandLogoFile != "")
        checkGeneralInfoError = true;
      else if (formik.values.logo != "" && formik.values.brandLogoFile == "")
        checkGeneralInfoError = true;
      else checkGeneralInfoError = false;
    }
    if (formik.values.location == undefined) {
      checkLocationError = false;
    } else {
      checkLocationError = formik.errors.location ? false : true;
    }

    checkRedemptionError = formik.errors.redemptionType ? false : true;

    if (!formik.values.checkSocialLinks) checkSocialError = false;
    else {
      if (
        formik.errors.facebook ||
        formik.errors.instagram ||
        formik.errors.website
      )
        checkSocialError = false;
      else if (
        formik.values.checkFacebook ||
        formik.values.checkInstagram ||
        formik.values.checkWebsite
      )
        checkSocialError = true;
      else if (
        formik.values.checkFacebook &&
        formik.values.checkInstagram &&
        formik.values.checkWebsite
      )
        checkSocialError = false;
    }

    if (
      formik.errors.dealDescription ||
      formik.errors.dealOfferName ||
      formik.errors.dealOfferImageFile
    ) {
      checkLoyaltyError = false;
    } else {
      checkLoyaltyError = true;
    }

    if (formik.values.connectedBrand) soloBrandErrorCheck = true;

    setFilled({
      ...filled,
      connectBrand: soloBrandErrorCheck,
      generalInformation: checkGeneralInfoError,
      location: checkLocationError,
      redemptionType: checkRedemptionError,
      socialLinks: checkSocialError,
      loyaltyBenifits: checkLoyaltyError,
    });
  }, [formik.errors, formik.values]);

  //Ref for sectionScroll dont remove!
  let section1Ref = createRef(null);
  let section2Ref = createRef(null);
  let section3Ref = createRef(null);
  let section4Ref = createRef(null);
  let section5Ref = createRef(null);
  let section6Ref = createRef(null);

  const ComponentLists = [
    {
      title: "General Information",
      filled: filled.generalInformation,
      link: "#section-1",
      ref: section1Ref,
    },
    {
      title: "Location",
      filled: filled.location,
      link: "#section-2",
      ref: section2Ref,
    },
    {
      title: "Redemption Type",
      filled: filled.redemptionType,
      link: "#section-3",
      ref: section3Ref,
    },
    {
      title: "Loyalty & Benefits",
      filled: filled.loyaltyBenifits,
      link: "#section-4",
      ref: section4Ref,
    },
    {
      title: "Social Links",
      filled: filled.socialLinks,
      link: "#section-5",
      ref: section5Ref,
    },
    {
      title: "Connect to solo brand",
      filled: filled.connectBrand,
      link: "#section-6",
      ref: section6Ref,
    },
  ];

  !brand.loyaltyConnectionBrand && ComponentLists.splice(0, 1);

  const goToSection = (element) => {
    formik.setValues({ ...formik.values, selectedComponent: element.title });
    element.ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const createBrandOptions = () => {

    let arr = [];
    soloBrands.list.length > 0 &&
      soloBrands.list.forEach((brand)=>{
          arr.push({value : brand._id, name : brand.brandName})
    })
    return arr
  };

  const [leave, setLeave] = useState(false);

  return (
    <div id="AddVenue" className=" mb_50">
      <CustomsModal
        open={leave}
        minWidth={"600px"}
        component={
          <LeaveModal
            onCancel={() => setLeave(false)}
            onDelete={() => {
              const location = window.location.pathname.split("/");
              location.pop();
              history.push(location.join("/"));
            }}
          />
        }
        onClose={() => setLeave(false)}
      />
      <CustomsModal
        open={imageModal.cover.open}
        onClose={() =>
          setImageModal({
            ...imageModal,
            cover: { ...imageModal.cover, open: false },
          })
        }
        minWidth="470px"
        minHeight="470px"
        padding="0px"
        component={
          <ImageModalV2
            imageModal={imageModal}
            setImageModal={setImageModal}
            image={"cover"}
            type={"cover"}
            formik={formik}
          />
        }
      />
      <div className="row">
        <div className="col-md-3 col-lg-3 col-xl-2 pl_0 pr_0">
          <div className="leftSection">
            <div className="scrollspy">
              {/* formik.values.selectedComponent == element.name && */}
              {ComponentLists.map((element, idx) => (
                <li className="d-flex space-between" key={idx}>
                  <a
                    onClick={() => goToSection(element)}
                    className={`singleItem Link14M color-unselectTextColor 
                                        ${
                                          formik.values.selectedComponent ==
                                            element.title && "isCurrent"
                                        } 
                                        `}
                  >
                    {element.title}{" "}
                  </a>
                  {element.filled && (
                    <div>
                      <SvgIcons.TickIconSub />
                    </div>
                  )}
                </li>
              ))}
            </div>
          </div>
        </div>
        {!state.mainLoader ? (
          <div className="col-md-9 col-lg-9 col-xl-10 rightSideComponent">
            <div
              ref={section1Ref}
              style={{ height: "60px", marginTop: "-60px" }}
            ></div>
            <form onSubmit={formik.handleSubmit}>
              {/* <div className="pl_17 mr_37 mt_16" >
                        <PageTitle subTitle={'Subbrand subtitle goes here!'} title={"General Information"} state={state} formik={formik}/>
                    </div> */}
              <div className="pl_32 col-md-7 col-xl-7 col-lg-7 col-12 mr_50">
                <div className="d-flex">
                  <div>
                    <section>
                      <div className="Heading22M color-neutral100 mt_16">
                        General Information
                      </div>
                      {/* <div className="Body14R color-neutral60 mt_4">
                                    General Info Subheading goes here!
                                </div> */}
                      <div className="">
                        {/* <UploadImage 
                                        title       = "Brand Logo"
                                        // source      = {typeof formik.values.brandLogoFile === 'object' ? URL.createObjectURL(formik.values.brandLogoFile) : formik.values.logo}
                                        source     = {formik.values.brandLogoFile ? URL.createObjectURL(formik.values.brandLogoFile) : formik.values.logo}
                                        reference  = {logoRef}
                                        imageKey   = "brandLogoFile"
                                        imageClass = "innerBoxBorder"
                                        // handleImage = {handleImage}
                                        handleImage = {(e)=> {setImageModal({...imageModal, logo: {...imageModal.logo, open: true, imageEvent: e}}) }}
                                    /> */}
                        {console.log(formik.errors)}
                        <LogoUploader
                          src={
                            formik.values.brandLogoFile
                              ? URL.createObjectURL(formik.values.brandLogoFile)
                              : formik.values.logo
                          }
                          label="Brand Logo"
                          className="mt_24 pl_0 col-md-12 mb_24 z-index-100"
                          onChange={(e) => {
                            setImageModal({
                              ...imageModal,
                              logo: {
                                ...imageModal.logo,
                                open: true,
                                imageEvent: e,
                              },
                            });
                          }}
                          error={
                            formik.values.brandLogoFile &&
                            formik.errors.brandLogoFile
                          }
                          btntext="Upload Logo"
                        />
                        <div className="error">
                          {formik.errors.brandLogoFile}
                        </div>

                        <LogoUploader
                          src={
                            formik.values.brandCoverFile
                              ? URL.createObjectURL(
                                  formik.values.brandCoverFile
                                )
                              : formik.values.brandCover
                          }
                          label="Brand Cover"
                          className="pl_0 col-md-12 mb_24 z-index-100"
                          error={
                            formik.values.brandCoverFile &&
                            formik.errors.brandCoverFile
                          }
                          btntext="Upload Cover"
                          hint={
                            "Upload a  205 X 78  pixel PNG, or JPG to display in browser tabs."
                          }
                          htmlFor={"coverUpload"}
                          onChange={(e) => {
                            setImageModal({
                              ...imageModal,
                              cover: {
                                ...imageModal.cover,
                                open: true,
                                imageEvent: e,
                              },
                            });
                          }}
                        />
                        <div className="error">
                          {formik.errors.brandCoverFile}
                        </div>
                      </div>

                      {/* <CustomsModal
                                    minWidth  = "100%"
                                    open      = { imageModal.logo.open }
                                    onClose   = { ()=> setImageModal({...imageModal, logo: {...imageModal.logo, open: false}}) }
                                    component = { 
                                        // <ImageModal 
                                        //     imageModal    = { imageModal }
                                        //     setImageModal = { setImageModal }
                                        //     image         = { 'logo' }
                                        //     formik        = { formik }
                                        // />
                                }
                                /> */}
                      {/* <TextField /> */}
                      <div className="position-relative mt_16">
                        <CustomTextField
                          label="Brand Name"
                          className="w-100 mb_24"
                          name="name"
                          inputProps={{
                            tabIndex: "1",
                            onFocus: formik.handleBlur,
                          }}
                          defaultValue={formik.values.name}
                          onBlur={(e) => {
                            handleChange(e, "name");
                          }}
                          // inputProps   = {{ onFocus: formik.handleBlur }}
                          error={formik.touched.name && formik.errors.name}
                          helperText={
                            formik.touched.name && formik.errors.name
                              ? formik.errors.name
                              : ""
                          }
                          // inputProps  = {{ onFocus: formik.handleBlur }}
                          // error       = {typeof formik.values.name === 'string' && formik.errors.name && formik.errors.name}
                          // helperText  = {typeof formik.values.name === 'string' && formik.touched.name && formik.errors.name ? formik.errors.name : ''}
                        />

                        <CustomTextField
                          label="Email Address"
                          className="w-100 mb_24"
                          autoComplete={"off"}
                          name="email"
                          inputProps={{
                            tabIndex: "2",
                            onFocus: formik.handleBlur,
                          }}
                          defaultValue={formik.values.email}
                          onBlur={(e) => {
                            handleChange(e, "email");
                          }}
                          // inputProps   = {{ onFocus: formik.handleBlur }}
                          error={formik.touched.email && formik.errors.email}
                          helperText={
                            formik.touched.email && formik.errors.email
                              ? formik.errors.email
                              : ""
                          }
                        />
                      </div>

                      <div className="mb_24">
                        <PhoneInput
                            inputClass = {'w-100 Body14R phoneInputField'}
                            inputStyle = {{border: '1px solid '+ColorSchemeCode.OtherBorder, height: '42px'}}
                            value      = {formik.values.fullPhone || ''}
                            onChange   = {phone => formik.setValues({...formik.values, fullPhone: phone })}
                        />
                      </div>
                      <CustomSelect
                        label="Location Type"
                        className="w-100"
                        options={Options.getVenueType()}
                        name="type"
                        inputProps={{ tabIndex: "5" }}
                        value={formik.values.type}
                        onChange={(e) => {
                          handleChange(e, "type");
                        }}
                        error={formik.touched.type && formik.errors.type}
                        helperText={formik.errors.type && formik.errors.type}
                      />
                      {formik.values.type === "other" && (
                        <div className="mt_24">
                          <CustomTextField
                            label="Other"
                            name="other"
                            className="w-100"
                            defaultValue={formik.values.other}
                            onBlur={(e) => {
                              handleChange(e, "other");
                            }}
                            inputProps={{
                              tabIndex: "6",
                              onFocus: formik.handleBlur,
                            }}
                            error={formik.touched.phone && formik.errors.phone}
                            helperText={
                              formik.touched.phone && formik.errors.phone
                                ? formik.errors.phone
                                : ""
                            }
                          />
                        </div>
                      )}

                      <div className="customLabel mt_24"></div>

                      <CustomTextArea
                        label={"Description"}
                        minHeight="150px"
                        maxHeight="150px"
                        color={ColorSchemeCode.textFieldActiveColor}
                        name="description"
                        onBlur={(e) => {
                          handleChange(e, "description");
                        }}
                        defaultValue={formik.values.description}
                        classes="w-100"
                        rows={3}
                        error={
                          formik.touched.description &&
                          formik.errors.description
                        }
                        helperText={
                          formik.errors.description && formik.errors.description
                        }
                      />
                      <div className="fs-12 ml_8 text-align-right">
                        Minimum 25 characters
                      </div>
                    </section>
                    <div style={{ height: "60px" }} ref={section2Ref}></div>
                    <div className="mb_56">
                      <SvgIcons.SubBrandPageSeperator />
                    </div>
                    <section>
                      <div className="Heading22M color-neutral100">Location</div>
                      <div className="Body14R color-neutral60 mt_4 mb_24">
                        Location Subheading goes here!
                      </div>

                      <Autocomplete
                        className="w-100  mb_24"
                        variant="standard"
                        fontSize="14px"
                        label="Venue Address"
                        inputProps={{ tabIndex: "8" }}
                        name="address"
                        value={
                          formik.values.location
                            ? formik.values.location.address
                            : ""
                        }
                        onChange={onAddressChange}
                        locationSummary={locationSummary}
                        InputProps={{
                          disableUnderline: false,
                          style: { fontSize: 16, color: "color(neutral80)" },
                        }}
                        error={
                          formik.errors.location && !formik.values.location
                            ? formik.errors.location.address
                            : false
                        }
                        helperText={
                          formik.errors.location && !formik.values.location
                            ? formik.errors.location.address
                            : ""
                        }
                      />
                      <div tabIndex="-1">
                        <GoogleMap
                          width={"100%"}
                          height={"200px"}
                          zoom={
                            formik.values.location && formik.values.location.lat
                              ? 15
                              : 4
                          }
                          draggable={true}
                          position={
                            formik.values.location
                              ? {
                                  lat: formik.values.location.lat,
                                  lng: formik.values.location.lng,
                                }
                              : ""
                          }
                          dragEnd={getPlace}
                          className={"borderRadius-4"}
                        />
                      </div>
                    </section>
                    <div style={{ height: "60px" }} ref={section3Ref}></div>
                    <div className="mb_56">
                      <SvgIcons.SubBrandPageSeperator />
                    </div>
                    <section>
                      <div className="Heading22M color-neutral100">
                        Redemption Type
                      </div>
                      <div className="Body14R color-neutral60">
                        Redemption Subheading goes here!
                      </div>
                      <div className="mt_24">
                        <CustomSelect
                          label        = "Redemption Type"
                          className    = "w-100"
                          options      = {Options.getRedemptionType()}
                          name         = "redemptionType"
                          inputProps   = {{ tabIndex: "9" }}
                          defaultValue = {formik.values.redemptionType}
                          onBlur       = {(e) => {
                            handleChange(e, "redemptionType");
                          }}
                          error={
                            formik.touched.redemptionType &&
                            formik.errors.redemptionType
                          }
                          helperText={
                            formik.errors.redemptionType &&
                            formik.errors.redemptionType
                          }
                        />
                      </div>
                      <div className="mt_24">
                        {
                          <CustomTextField
                            label="Average Spending"
                            name="avgSpendingValue"
                            type="number"
                            className="w-100"
                            disabled={
                              formik.values.redemptionType ===
                              RedemptionType.SELF
                                ? false
                                : true
                            }
                            defaultValue={formik.values.avgSpendingValue}
                            onChange={(e) => {
                              handleChange(e, "avgSpendingValue");
                            }}
                            inputProps={{
                              tabIndex: "10",
                              onFocus: formik.handleBlur,
                            }}
                            error={
                              formik.touched.avgSpendingValue &&
                              formik.errors.avgSpendingValue
                            }
                            helperText={
                              formik.touched.avgSpendingValue &&
                              formik.errors.avgSpendingValue
                                ? formik.errors.avgSpendingValue
                                : ""
                            }
                          />
                        }
                      </div>
                      {formik.values.redemptionType === RedemptionType.SELF &&
                        venueData &&
                        venueData.edit && (
                          <div className="d-flex">
                            <CustomButton
                              className="mt_16"
                              btntext={"Email QR Code"}
                              varient="secondary"
                              onClick={SendQrCode}
                              icon={
                                qrLoader ? (
                                  <CircularProgress
                                    size={20}
                                    color={"inherit"}
                                  />
                                ) : (
                                  <SvgIcons.EmailIcon
                                    color={utils.invertColor(
                                      agency.agencyColor,
                                      { black: "black", white: "white" }
                                    )}
                                  />
                                )
                              }
                            />
                            <div className="ml_8">
                              <CustomButton
                                className="mt_16 display-none"
                                varient="secondary"
                                // btntext   = {"Download"}
                                btntext=""
                                onClick={downloadQr}
                                icon={
                                  qrLoader ? (
                                    <CircularProgress
                                      size={16}
                                      color={"inherit"}
                                    />
                                  ) : (
                                    <SvgIcons.DownloadIcon
                                      color={utils.invertColor(
                                        agency.agencyColor,
                                        { black: "black", white: "white" }
                                      )}
                                    />
                                  )
                                }
                              />
                              <div className="downloadLink mt_16 middle">
                                <a
                                  onClick={downloadQr}
                                  className="text-center cp"
                                  download="Qrcode.png"
                                >
                                  {downloadQrLoader ? (
                                    <CircularProgress
                                      size={20}
                                      color={"inherit"}
                                    />
                                  ) : (
                                    <SvgIcons.DownloadIcon
                                      color={utils.invertColor(
                                        agency.agencyColor,
                                        { black: "black", white: "white" }
                                      )}
                                    />
                                  )}
                                </a>
                              </div>
                            </div>
                          </div>
                        )}
                    </section>
                    <div style={{ height: "60px" }} ref={section4Ref}></div>
                    <div className="mb_56">
                      <SvgIcons.SubBrandPageSeperator />
                    </div>
                    <section>
                      <div className="Heading22M color-neutral100">
                        Loyalty & Benefits
                      </div>
                      <div className="Body14R color-neutral60 mt_4">
                        Check loyalty & benefits you want to include in your
                        sub-brand.
                      </div>

                      <div className="bar mt_24"></div>

                      <div className="TierSystem">
                        <div
                          className="row w-100 m_0 TierSystemBox pt_16 pb_16"
                          onClick={() =>
                            formik.setValues({
                              ...formik.values,
                              checkTierSystem: !formik.values.checkTierSystem,
                            })
                          }
                        >
                          <div className="col-10 cp">
                            <div className="Heading16M color-neutral100">
                              Tier System
                            </div>
                            <div className="Body14R mt_8 color-neutral60">
                              All these tiers will be applied to your sub-brand.
                            </div>
                          </div>
                          <div className="col-2 text-right display pt_10 cp">
                            <img
                              className={` rotate cp  ${
                                formik.values.checkTierSystem &&
                                "icon animateIcon"
                              }`}
                              src={PngIcons.arrowIconLoyalty}
                              alt=""
                            />
                          </div>
                        </div>
                        {/* <CustomCheckBox value={formik.values.checkTierSystem} onChange={()=>formik.setValues({...formik.values, checkTierSystem: !formik.values.checkTierSystem})} className="fs-14 color-neutral100" label={"Tier System"} /> */}
                        {/* {
                                        formik.values.checkTierSystem &&
                                        <CustomButton 
                                            hover           = {ColorSchemeCode.white}
                                            backgroundColor = {ColorSchemeCode.white}
                                            color           = {ColorSchemeCode.c6B778C}
                                            focus           = {ColorSchemeCode.white}
                                            btntext         = {_customTier ? 'Save Changes' : 'Customize Tier'}
                                            onClick         = {_customTier ? handleSave : ()=>_setCustomTier(!_customTier)
                                            }
                                            className={"btnBorder"}
                                        />
                                    }  */}
                        {console.log("formik.values ", formik.values)}

                        <div
                          className={`expandable ${
                            formik.values.checkTierSystem && "expand"
                          }`}
                        >
                          {formik.values.checkTierSystem && (
                            <div>
                              <div>
                                {venueData &&
                                  venueData.tiers.length > 0 &&
                                  venueData.tiers.map((value, index) => (
                                    <div>
                                      {/* <div className={formik.values.tierSystemName === value.name ? "pl_16 pr_16 pt_12 pb_12 mr_8 color-c5F9EF1 bg-color-cF6F9FE cp selectedBorder" : "cp pl_16 pr_16 pt_12 pb_12 mr_8 color-c5F9EF1 bg-color-cF6F9FE"}  */}
                                      <div
                                        className={`singleTier cp ${
                                          formik.values.tierSystemName ===
                                            value.name &&
                                          showTierModal &&
                                          "selectedTier"
                                        }`}
                                        onClick={() => {
                                          formik.setValues({
                                            ...formik.values,
                                            tierSystemName: value.name,
                                            tierIndex: index,
                                            //  minPoints: value.pointRange.minPoints, maxPoints: value.pointRange.maxPoints,
                                            // extraPoints: value.benefits.points.extraPoints , discountValue: value.benefits.discount.discountValue
                                          });
                                          setShowTierModal(true);
                                        }}
                                      >
                                        <div className="d-flex space-between cp">
                                          <div className="tierDetails cp d-flex">
                                            {tierData?.map((element, idx) =>
                                              element._id === value.id ? (
                                                <div
                                                  class="position-relative circle mr_8"
                                                  style={{
                                                    background:
                                                      element.backgroundColor,
                                                  }}
                                                >
                                                  <div
                                                    class="borderRadius-50 innerCircle absoluteMiddle"
                                                    style={{
                                                      background:
                                                        element.backgroundColor,
                                                    }}
                                                  ></div>
                                                </div>
                                              ) : (
                                                ""
                                              )
                                            )}

                                            {!tierData ? (
                                              <div
                                                class="position-relative circle mr_8"
                                                style={{
                                                  background:
                                                    value.backgroundColor,
                                                }}
                                              >
                                                <div
                                                  class="borderRadius-50 innerCircle absoluteMiddle"
                                                  style={{
                                                    background:
                                                      value.backgroundColor,
                                                  }}
                                                ></div>
                                              </div>
                                            ) : (
                                              ""
                                            )}

                                            <div className="tierName Body14R color-neutral100 middle">
                                              {value.name}
                                            </div>
                                          </div>
                                          <div className="cp">
                                            <img
                                              src={PngIcons.arrowIconLoyalty}
                                              alt=""
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>

                              {showTierModal && (
                                <CustomsModal
                                  open={showTierModal}
                                  minWidth={"600px"}
                                  component={
                                    <ModalComponent
                                      handleChange={handleChange}
                                      onSave={
                                        _customTier
                                          ? handleSave
                                          : () => _setCustomTier(!_customTier)
                                      }
                                      days={days}
                                      handleText={handleText}
                                      formik={formik}
                                      onClose={() => setShowTierModal(false)}
                                    />
                                  }
                                  onClose={() => setShowTierModal(false)}
                                />
                              )}

                              {_customTier && (
                                <>
                                  <div className="fw-6 fs-18 color-neutral100 mb_8">
                                    Benefits
                                  </div>
                                  <div className="fs-14 color-propertySectionTextColor mb_24">
                                    Check benefits you want to add to your tier.
                                  </div>
                                  <div>
                                    <CustomCheckBox
                                      value={
                                        formik.values.checkDiscount[0][
                                          formik.values.tierIndex
                                        ]
                                      }
                                      onChange={() =>
                                        (formik.values.checkDiscount[0][
                                          formik.values.tierIndex
                                        ] =
                                          !formik.values.checkDiscount[0][
                                            formik.values.tierIndex
                                          ])
                                      }
                                      className="fs-14 color-neutral100"
                                      label={"Dicounts"}
                                    />
                                  </div>
                                  {/* <div><CustomCheckBox value={formik.values.checkDiscount} onChange={()=>formik.setValues({...formik.values, checkDiscount: !formik.values.checkDiscount})} className="fs-14 color-neutral100" label={"Dicounts"} /></div> */}

                                  {formik.values.checkDiscount[0][
                                    formik.values.tierIndex
                                  ] && (
                                    <div>
                                      <div>
                                        <CustomTextField
                                          // placeholder  = "Discount Value"
                                          icon={<div>%</div>}
                                          type="number"
                                          position="end"
                                          className={
                                            formik.values.checkCustom[0][
                                              formik.values.tierIndex
                                            ]
                                              ? "w-40 mb_16 disabled"
                                              : "w-40 mb_16"
                                          }
                                          name={
                                            "discountValue" +
                                            [0][formik.values.tierIndex]
                                          }
                                          defaultValue={
                                            formik.values.discountValue[0][
                                              formik.values.tierIndex
                                            ]
                                              ? formik.values.discountValue[0][
                                                  formik.values.tierIndex
                                                ]
                                              : ""
                                          }
                                          onBlur={(e) => {
                                            if (
                                              e.target.value <= 100 &&
                                              e.target.value >= 0
                                            ) {
                                              formik.values.discountValue[0][
                                                formik.values.tierIndex
                                              ] = parseInt(e.target.value);
                                              formik.setValues({
                                                ...formik.values,
                                              });
                                            }
                                          }}
                                          // error       = {formik.errors.discountValue  ? formik.errors.discountValue : false}
                                          // helperText  = {formik.errors.discountValue ? formik.errors.discountValue : ''}
                                        />
                                      </div>
                                      <div>
                                        <CustomCheckBox
                                          value={
                                            formik.values.checkCustom[0][
                                              formik.values.tierIndex
                                            ]
                                          }
                                          onChange={() => {
                                            formik.values.checkCustom[0][
                                              formik.values.tierIndex
                                            ] =
                                              !formik.values.checkCustom[0][
                                                formik.values.tierIndex
                                              ];
                                            formik.setValues({
                                              ...formik.values,
                                            });
                                          }}
                                          className="fs-14 color-neutral100"
                                          label={"Custom Discounts"}
                                        />
                                      </div>
                                      {formik.values.checkCustom[0][
                                        formik.values.tierIndex
                                      ] && (
                                        <div>
                                          <div className="fw-6 fs-14 color-neutral100 mt_24 mb_16">
                                            Days
                                          </div>
                                          {days.map((value, index) => (
                                            <div
                                              className={
                                                index % 2 === 0
                                                  ? "row ml_0 w-80 align-items-center bg-color-cF6F9FE"
                                                  : "row ml_0 w-80 align-items-center"
                                              }
                                            >
                                              <div className="col-5 pb_10">
                                                <CustomTextField
                                                  defaultValue={
                                                    formik.values
                                                      .customDiscounts[0][
                                                      formik.values.tierIndex
                                                    ][index]
                                                  }
                                                  // onChange = {(e)=>handleText(e,index)}
                                                  type="number"
                                                  label={value}
                                                  onBlur={(e) => {
                                                    handleChange(e, index);
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                  {/* <div className="bar w-40 mt_24 mb_24"></div> */}
                                  <div>
                                    <CustomCheckBox
                                      value={
                                        formik.values.checkExtra[0][
                                          formik.values.tierIndex
                                        ]
                                      }
                                      onChange={() =>
                                        (formik.values.checkExtra[0][
                                          formik.values.tierIndex
                                        ] =
                                          !formik.values.checkExtra[0][
                                            formik.values.tierIndex
                                          ])
                                      }
                                      className="fs-14 color-neutral100 "
                                      label={"Extra Points"}
                                    />
                                  </div>
                                  {formik.values.checkExtra[0][
                                    formik.values.tierIndex
                                  ] && (
                                    <div>
                                      <div>
                                        <CustomSelect
                                          label     = "Select event"
                                          className = "w-40 mb_16"
                                          name      = "extraPoints"
                                          type      = "number"
                                          options   = {Options.getTierExtraPoint()}
                                          onChange  = {(e) => {
                                            formik.values.extraPoints[0][
                                              formik.values.tierIndex
                                            ] = e.target.value;
                                            formik.setValues({
                                              ...formik.values,
                                            });
                                          }}
                                          value={
                                            formik.values.extraPoints[0][
                                              formik.values.tierIndex
                                            ]
                                          }
                                          error={
                                            formik.errors.extraPoints
                                              ? formik.errors.extraPoints
                                              : false
                                          }
                                          helperText={
                                            formik.errors.extraPoints
                                              ? formik.errors.extraPoints
                                              : ""
                                          }
                                        />
                                      </div>
                                    </div>
                                  )}
                                  <div className="bar w-40 mt_24 mb_24"></div>
                                  {/* <div className="disabled"><CustomCheckBox value={formik.values.checkBirthday} onChange={()=>formik.setValues({...formik.values, checkBirthday: !formik.values.checkBirthday})} className="fs-14 color-neutral100" label={"Birthday Coupon"} /></div>
                                                {
                                                    formik.values.checkBirthday &&
                                                    <div>
                                                        <div>
                                                            <CustomTextField
                                                                // placeholder = "Voucher Value"
                                                                icon        = {<div>$</div>}
                                                                position    = "start"
                                                                className   = "w-40"
                                                            />
                                                        </div>
                                                    </div>
                                                } */}
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      {/* <div><CustomCheckBox value={formik.values.checkDealOffer} onChange={()=>formik.setValues({...formik.values, checkDealOffer: !formik.values.checkDealOffer})} className="fs-14 color-neutral100 mt_24" label={"Deal Offers"} /></div> */}

                      <div className="bar w-100"></div>

                      <div className="DealSystem">
                        <div className="row m_0 w-100 DealSystemBox pt_16 pb_16">
                          <div
                            className="col-10 cp"
                            onClick={() =>
                              formik.setValues({
                                ...formik.values,
                                checkDealOffer: !formik.values.checkDealOffer,
                              })
                            }
                          >
                            <div className="Heading16M color-neutral100 mt_8">
                              Deal Offers
                            </div>
                            <div className="Body14R color-neutral60 mt_8">
                              All these Deals will be applied to your sub-brand.
                            </div>
                          </div>
                          <div className="col-2 text-right pr_0">
                            <CustomSwitch
                              onChange={() =>
                                formik.setValues({
                                  ...formik.values,
                                  checkDealOffer: !formik.values.checkDealOffer,
                                })
                              }
                              checked={formik.values.checkDealOffer}
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        className={`expandable ${
                          formik.values.checkDealOffer && "expandDeal"
                        }`}
                      >
                        {formik.values.checkDealOffer && (
                          <div className="mb_40 pl_24">
                            <CustomTextField
                              label="Deal Title"
                              className="w-100 mt_8 mb_24"
                              name="dealOfferName"
                              defaultValue={formik.values.dealOfferName}
                              onBlur={(e) => {
                                handleChange(e, "dealOfferName");
                              }}
                              inputProps={{ onFocus: formik.handleBlur }}
                              error={
                                formik.touched.dealOfferName &&
                                formik.errors.dealOfferName
                              }
                              // helperText = {formik.touched.sharedCampaignHolder && formik.touched.sharedCampaignHolder.discountOffer && formik.errors.sharedCampaignHolder.discountOffer ? formik.errors.sharedCampaignHolder.discountOffer : ''}
                            />

                            {formik.values.checkDealOffer && (
                              <CustomTextArea
                                label="Deal Description"
                                className="w-100 mb_24"
                                name="dealDescription"
                                defaultValue={formik.values.dealDescription}
                                onBlur={(e) => {
                                  handleChange(e, "dealDescription");
                                }}
                                inputProps={{ onFocus: formik.handleBlur }}
                                rows={3}
                                error={
                                  formik.touched.dealDescription &&
                                  formik.errors.dealDescription
                                }
                                // helperText = {formik.touched.sharedCampaignHolder && formik.touched.sharedCampaignHolder.discountOffer && formik.errors.sharedCampaignHolder.discountOffer ? formik.errors.sharedCampaignHolder.discountOffer : ''}
                              />
                            )}

                            {/* <CustomImageDrop
                                            onChange = {(e)=> {setImageModal({...imageModal, dealOfferImageFile: {...imageModal.dealOfferImageFile, open: true, imageEvent: e}}) }}
                                            isImage  = {(formik.values.dealOfferImageFile || formik.values.dealOfferImage)}
                                            imageUrl = {typeof formik.values.dealOfferImageFile === 'object' ? URL.createObjectURL(formik.values.dealOfferImageFile) : formik.values.dealOfferImage}
                                            text     = {
                                                        <div className="">
                                                        <div className="Body14R title"> Drop or click to upload image</div>
                                                        <div className="mb_0">
                                                            <span className="Link13M text-center subHeading">Recommended image size: 750x288 </span>
                                                        </div>
                                                        </div>
                                                    }
                                            formik   = {formik}
                                        /> */}
                            <div id="dealCoverUploader">
                              <LogoUploader
                                src={
                                  formik.values.dealOfferImageFile
                                    ? URL.createObjectURL(
                                        formik.values.dealOfferImageFile
                                      )
                                    : formik.values.dealOfferImage
                                }
                                label="Deal Cover"
                                className="mb_50 pl_0"
                                error={
                                  formik.values.dealOfferImageFile &&
                                  formik.errors.dealOfferImageFile
                                }
                                btntext="Upload Cover"
                                htmlFor={"dealCoverUpload"}
                                onChange={(e) => {
                                  setImageModal({
                                    ...imageModal,
                                    dealOfferImageFile: {
                                      ...imageModal.dealOfferImageFile,
                                      open: true,
                                      imageEvent: e,
                                    },
                                  });
                                }}
                              />
                            </div>
                          </div>
                        )}
                        <FormHelperText className="fs-10 ml_40 error">
                          {!formik.values.dealOfferImage
                            ? formik.errors.dealOfferImageFile
                            : ""}
                        </FormHelperText>
                      </div>
                    </section>
                    <div className="mt_56">
                      <SvgIcons.SubBrandPageSeperator />
                    </div>
                    <section ref={section5Ref}>
                      <div className="row w-100 m_0 pt_16 pb_16">
                        <div
                          className="col-10 cp pl_0 pr_0"
                          onClick={() =>
                            formik.setValues({
                              ...formik.values,
                              checkSocialLinks: !formik.values.checkSocialLinks,
                            })
                          }
                        >
                          <div className="Heading22M mt_56 color-neutral100 mb_4">
                            Social Links
                          </div>
                          <div className="Body14R color-neutral60 mb_60">
                            All these tiers will be applied to your sub-brand.
                          </div>
                        </div>
                        <div className="col-2 mt_56 text-right pr_0">
                          <CustomSwitch
                            onChange={() =>
                              formik.setValues({
                                ...formik.values,
                                checkSocialLinks:
                                  !formik.values.checkSocialLinks,
                              })
                            }
                            value={formik.values.checkSocialLinks}
                            checked={formik.values.checkSocialLinks}
                          />
                        </div>
                      </div>
                      <div
                        className={`expandable ${
                          formik.values.checkSocialLinks && "expandSocial"
                        }`}
                      >
                        {formik.values.checkSocialLinks && (
                          <>
                            <div className="mt_4 w-100 d-flex">
                              <CustomCheckBox
                                value={formik.values.checkFacebook}
                                onChange={() =>
                                  formik.setValues({
                                    ...formik.values,
                                    checkFacebook: !formik.values.checkFacebook,
                                  })
                                }
                                className="fs-14 pt_2 color-neutral100"
                                label={""}
                              />
                              <div className="pt_6 mr_8">
                                <SvgIcons.Facebook />
                              </div>
                              <div
                                className="w-100
"
                              >
                                 <CustomTextField
                                  label="Facebook"
                                  className={`w-100 ${
                                    !formik.values.checkFacebook && "disabled"
                                  }`}
                                  name="facebook"
                                  // paddingLeft="64px"
                                  defaultValue={formik.values.facebook}
                                  onChange={(e) => {
                                    handleChange(e, "facebook");

                                  }
                                }
                                  inputProps={{ onFocus: formik.handleBlur }}
                                  error={
                                    formik.touched.facebook &&
                                    formik.errors.facebook
                                  }
                                  // onFocus={icon="e"}
                                  // icon={
                                  //   <div className="Body14R">https://</div>
                                  // }
                                  position="start"
                                  helperText={
                                    formik.errors.facebook &&
                                    formik.errors.facebook
                                  }
                                /> 
                              

                              </div>
                            </div>
                            <div className="mt_24 w-100 d-flex">
                              <CustomCheckBox
                                value={formik.values.checkInstagram}
                                onChange={() =>
                                  formik.setValues({
                                    ...formik.values,
                                    checkInstagram:
                                      !formik.values.checkInstagram,
                                  })
                                }
                                className="fs-14 color-neutral100"
                              />
                              <div className="pt_6 mr_8">
                                <SvgIcons.Instagram />
                              </div>
                              <div className="w-100">
                                <CustomTextField
                                  label="Instagram"
                                  link
                                  className={`w-100 ${
                                    !formik.values.checkInstagram && "disabled"
                                  }`}
                                  // paddingLeft="64px"
                                  name="instagram"
                                  defaultValue={formik.values.instagram}
                                  onChange={(e) => {
                                    handleChange(e, "instagram");
                                  }}
                                  // icon={
                                  //   <div className="Body14R">https://</div>
                                  // }
                                  position="start"
                                  inputProps={{ onFocus: formik.handleBlur }}
                                  error={
                                    formik.errors.instagram &&
                                    formik.errors.instagram
                                  }
                                  helperText={
                                    formik.touched.instagram &&
                                    formik.errors.instagram
                                  }
                                />
                                {/* <div className="error">{formik.errors.instagram}</div> */}
                              </div>
                            </div>
                            <div className="mt_24 w-100 d-flex">
                              <CustomCheckBox
                                value={formik.values.checkWebsite}
                                onChange={() =>
                                  formik.setValues({
                                    ...formik.values,
                                    checkWebsite: !formik.values.checkWebsite,
                                  })
                                }
                                className="fs-14 color-neutral100"
                              />
                              <div className="pt_6 mr_8">
                                <SvgIcons.Website />
                              </div>
                              <div className="w-100">
                                <CustomTextField
                                  label="Website"
                                  link
                                  className={`w-100 ${
                                    !formik.values.checkWebsite && "disabled"
                                  }`}
                                  paddingLeft="0"
                                  name="website"
                                  defaultValue={formik.values.website}
                                  onChange={(e) => {
                                    handleChange(e, "website");
                                  }}
                                  // icon         = { <div className="ml_4">https://</div>}
                                  // position     = "start"
                                  inputProps={{ onFocus: formik.handleBlur }}
                                  error={
                                    formik.touched.website &&
                                    formik.errors.website
                                  }
                                  helperText={
                                    formik.touched.website &&
                                    formik.errors.website
                                  }
                                />
                                {/* <div className="error">{formik.errors.website}</div> */}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </section>
                    <div className="mb_56">
                      <SvgIcons.SubBrandPageSeperator />
                    </div>
                    {/* {brand.loyaltyConnectionBrand && <> */}
                    <section>
                      <div className="d-flex space-between">
                        <div className="">
                          <div className="Heading22M color-neutral100 mt_16">
                            Connect to solo brand
                          </div>
                          <div className="Body14R color-neutral60 mt_4">
                            You can connect your sub brand with your solo brand
                            combining their reward program.
                          </div>
                        </div>
                        {/* <div className="col-2 text-right pr_0 mt_16">
                                                <CustomSwitch 
                                                    onChange = {()=>setShowSoloBrand(!showSoloBrand)}
                                                    value    = {showSoloBrand}
                                                    checked  = {showSoloBrand}
                                                />
                                            </div> */}
                      </div>
                      <div className={`expandable`}>
                        <div className="soloBrandBox mt_24">
                          <CustomSelect
                            className="w-100"
                            label={'Solo Brands'}
                            value={formik.values.connectedBrand}
                            onChange={(e) =>
                              formik.setValues({
                                ...formik.values,
                                connectedBrand: e.target.value,
                              })
                            }
                            options={createBrandOptions()}
                          />
                        </div>
                      </div>
                    </section>
                    <div style={{ height: "60px" }} ref={section6Ref}></div>
                    {/* </>} */}
                  </div>
                </div>
              </div>

              <CustomsModal
                open={imageModal.dealOfferImageFile.open}
                onClose={() =>
                  setImageModal({
                    ...imageModal,
                    dealOfferImageFile: {
                      ...imageModal.dealOfferImageFile,
                      open: false,
                    },
                  })
                }
                minWidth="470px"
                minHeight="470px"
                padding="0px"
                component={
                  <ImageModalV2
                    imageModal={imageModal}
                    setImageModal={setImageModal}
                    image={"dealOfferImageFile"}
                    type="cover"
                    formik={formik}
                  />
                }
              />

              <CustomsModal
                open={imageModal.logo.open}
                onClose={() =>
                  setImageModal({
                    ...imageModal,
                    logo: { ...imageModal.logo, open: false },
                  })
                }
                minWidth="470px"
                minHeight="470px"
                padding="0px"
                component={
                  <ImageModalV2
                    imageModal={imageModal}
                    setImageModal={setImageModal}
                    image={"logo"}
                    formik={formik}
                  />
                }
              />
            </form>
            <div className="display-none">
              {/* <QRkjua 
                            qrcode={qrcode}
                        /> */}
              <QRcode qrcode={qrcode} />
            </div>
          </div>
        ) : (
          <Loader height={40} width={40} />
        )}
      </div>

      <CustomsModal
        open={deleteModal}
        minWidth={"600px"}
        component={
          <DeleteModal
            icon={state.deleteLoader}
            title="sub brand"
            onCancel={() => setDeleteModal(false)}
            onDelete={handleDelete}
          />
        }
        onClose={() => setDeleteModal(false)}
      />
    </div>
  );
}

const ModalComponent = ({
  formik,
  days,
  handleText,
  onClose,
  handleChange,
}) => {
  const handleResetFunc = async () => {
    let currentTier = formik.values.tier[formik.values.tierIndex];
    let tiers = await localForage.getItem("tiers");
    for (let tier of tiers) {
      if (currentTier.id == tier._id) {
        formik.values.tier[formik.values.tierIndex].benefits = tier.benefits;
        formik.values.discountValue[0][formik.values.tierIndex] =
          tier.benefits.discount.discountValue;
        formik.setValues({ ...formik.values });
      }
    }
  };

  return (
    <div id="subBrandModal" className="modalComponent">
      <>
        <div className="Heading22M color-neutral100">
          {formik.values.tierSystemName}
        </div>
        <div className="Body14R mb_24 mt_4">
          Following Benifits will be applied on this tier.
        </div>
        <div className="cp d-flex space-between discountBox">
          <div className="col-10 pl_0 pr_0">
            <div className="Heading16M color-neutral100">Discount</div>
            <div className="Body14R mt_4">
              Set discount value for the this tier.
            </div>
          </div>
          <div className="col-2 middle">
            <CustomButton
              btntext={"Reset"}
              onClick={handleResetFunc}
              varient="secondary"
            />
          </div>
        </div>
        <div className={`'expandDiscount'}`}>
          {/* <div><CustomCheckBox value={formik.values.checkDiscount} onChange={()=>formik.setValues({...formik.values, checkDiscount: !formik.values.checkDiscount})} className="fs-14 color-neutral100" label={"Dicounts"} /></div> */}
          {
            <FormControl className="w-100 pl_40 pb_16 pt_8">
              {/* //error = {formik.touched.usedFor && formik.errors.usedFor}> */}
              <RadioGroup
                name="checkCustom"
                value={
                  formik.values.checkCustom[0][formik.values.tierIndex]
                    ? false
                    : true
                }
                onChange={(e) => {
                  formik.values.checkCustom[0][formik.values.tierIndex] =
                    e.target.value;
                  formik.setValues({ ...formik.values });
                }}
                onFocus={formik.handleBlur}
              >
                <div className="d-flex">
                  <CustomRadio
                    value={"DiscountValue"}
                    label=""
                    checked={
                      formik.values.checkCustom[0][formik.values.tierIndex] ===
                        "DiscountValue" ||
                      formik.values.checkCustom[0][formik.values.tierIndex] ===
                        false
                        ? true
                        : false
                    }
                  />
                  {
                    <CustomTextField
                      // placeholder  = "Discount Value"
                      icon={<div>%</div>}
                      type="number"
                      position="end"
                      label="Discount Value"
                      className={
                        formik.values.checkCustom[0][
                          formik.values.tierIndex
                        ] !== "DiscountValue"
                          ? "w-70 mb_16 disabled"
                          : "w-70 mb_16"
                      }
                      name={"discountValue" + [0][formik.values.tierIndex]}
                      value={
                        formik.values.discountValue[0][formik.values.tierIndex]
                          ? formik.values.discountValue[0][
                              formik.values.tierIndex
                            ]
                          : ""
                      }
                      onChange={(e) => {
                        if (e.target.value <= 100 && e.target.value >= 0) {
                          formik.values.discountValue[0][
                            formik.values.tierIndex
                          ] = parseInt(e.target.value);
                          formik.setValues({ ...formik.values });
                        }
                      }}
                      // error       = {formik.errors.discountValue  ? formik.errors.discountValue : false}
                      // helperText  = {formik.errors.discountValue ? formik.errors.discountValue : ''}
                    />
                  }
                </div>

                <div>
                  <CustomRadio
                    value="Custom Discounts"
                    label="Custom Discounts"
                    checked={
                      formik.values.checkCustom[0][formik.values.tierIndex] ===
                        "Custom Discounts" ||
                      formik.values.checkCustom[0][formik.values.tierIndex] ===
                        true
                        ? true
                        : false
                    }
                  />
                  {(formik.values.checkCustom[0][formik.values.tierIndex] ===
                    "Custom Discounts" ||
                    formik.values.checkCustom[0][formik.values.tierIndex] ==
                      true) && (
                    <div>
                      {days.map((value, index) => (
                        <div className={"mt_8 pl_40 w-100 align-items-center"}>
                          <div className="pb_14">
                            <CustomTextField
                              value={
                                formik.values.customDiscounts[0][
                                  formik.values.tierIndex
                                ][index]
                              }
                              onChange={(e) => handleText(e, index)}
                              icon={<div>%</div>}
                              type="number"
                              position="end"
                              label={value}
                              className={"w-80"}
                              inputProps={{ onFocus: formik.handleBlur }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </RadioGroup>
              {/* <div className="error">{formik.errors.}</div> */}
            </FormControl>
          }
        </div>
        <div className="bar w-100"></div>
        <div>
          {/* <CustomCheckBox value={formik.values.checkExtra[0][formik.values.tierIndex]} onChange={()=>{formik.values.checkExtra[0][formik.values.tierIndex]=!formik.values.checkExtra[0][formik.values.tierIndex]; formik.setValues({...formik.values})}} className="fs-14 color-neutral100 " label={"Extra Points"} /> */}
          <div
            className="d-flex cp space-between discountBox"
            onClick={() => {
              formik.values.checkExtra[0][formik.values.tierIndex] =
                !formik.values.checkExtra[0][formik.values.tierIndex];
              formik.setValues({ ...formik.values });
            }}
          >
            {/* <CustomCheckBox value={formik.values.checkDiscount[0][formik.values.tierIndex]} onChange={()=>formik.values.checkDiscount[0][formik.values.tierIndex]= !formik.values.checkDiscount[0][formik.values.tierIndex] } className="fs-14 color-neutral100" label={"Dicounts"} /> */}
            <div className="">
              <div className="Heading16M color-neutral100 ">Extra Points</div>
              <div className="Body14R mt_4">
                Select how much extra points you want to give this tier.
              </div>
            </div>
            <div className="col-2 middle"></div>
          </div>
        </div>
        {
          <div>
            <div className="discountComponents pl_40">
              <CustomSelect
                label="Select event"
                className="w-70 mb_16"
                name="extraPoints"
                type="number"
                options={Options.getTierExtraPoint()}
                onChange={(e) => {
                  formik.values.extraPoints[0][formik.values.tierIndex] =
                    e.target.value;
                  formik.setValues({ ...formik.values });
                }}
                value={formik.values.extraPoints[0][formik.values.tierIndex]}
                error={
                  formik.errors.extraPoints ? formik.errors.extraPoints : false
                }
                helperText={
                  formik.errors.extraPoints ? formik.errors.extraPoints : ""
                }
              />
            </div>
          </div>
        }
        <div className="modalButtons m_0 row mt_24 justify-flex-end">
          <div>
            <CustomButton
              btntext="Cancel"
              varient="secondary"
              onClick={onClose}
            />
          </div>
          <div className="ml_8">
            <CustomButton
              varient={"primary"}
              btntext="Save Changes"
              onClick={onClose}
            />
          </div>
        </div>
        {/* <div className="bar w-40 mt_24 mb_24"></div> */}
        {/* <div className="disabled"><CustomCheckBox value={formik.values.checkBirthday} onChange={()=>formik.setValues({...formik.values, checkBirthday: !formik.values.checkBirthday})} className="fs-14 color-neutral100" label={"Birthday Coupon"} /></div> */}
        {/* {
                    formik.values.checkBirthday &&
                    <div>
                        <div>
                            <CustomTextField
                                // placeholder = "Voucher Value"
                                icon        = {<div>$</div>}
                                position    = "start"
                                className   = "w-40"
                            />
                        </div>
                    </div>
                } */}
      </>
    </div>
  );
};

const UploadImage = ({
  title,
  reference,
  source,
  imageKey,
  handleImage,
  imageClass,
}) => {
  const initColor = "#C4C4C4";
  return (
    <>
      <div className=" mb_8 mt_24 Link14M color-neutral100">{title}</div>
      <div className="d-flex">
        <div>
          <div
            onClick={() => {
              reference.current.click();
            }}
            className="boxBorder mr_35 cp"
          >
            {typeof source === "object" ? (
              source !== "" ? (
                <img
                  className={imageClass + " image"}
                  src={URL.createObjectURL(source)}
                  alt="img"
                />
              ) : (
                <div
                  className={imageClass + " image"}
                  style={{ backgroundColor: initColor }}
                ></div>
              )
            ) : source !== "" ? (
              <img className={imageClass + " image"} src={source} alt="img" />
            ) : (
              <div
                className={imageClass + " image"}
                style={{ backgroundColor: initColor }}
              ></div>
            )}
            <div class="middleText">
              <div>
                <SvgIcons.UploadIcon />
              </div>
            </div>
          </div>
          <input
            ref={reference}
            onChange={(event) => handleImage(event, imageKey)}
            type="file"
            style={{ display: "none" }}
            multiple={false}
          />
        </div>
        <div>
          <CustomButton
            onClick={() => {
              reference.current.click();
            }}
            height="36px"
            btntext="Upload Logo"
            icon={<SvgIcons.UploadIcon />}
            varient={"secondary"}
          />
          <div className="mt_8 color-color-neutral60 fw-4 fs-12">
            Upload a 32 x 32 pixel ICO, PNG, GIF, or JPG to display in browser
            tabs.
          </div>
        </div>
      </div>
    </>
  );
};

export default AddVenue;
